import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import user from './user';
import users from './users';
import contracts from './contracts';
import places from './places';
import snacks from './snacks';
import reports from './reports';
import ui from './ui';
import conversations from './conversations';
import agencies from './agencies';
import accountPlaces from './accountPlaces';
import forum from './forum';
import accounts from './accounts';
import alerts from './alerts';
import { connectRouter } from 'connected-react-router';
import expenses from './expenses';

export default history =>
  combineReducers({
    router: connectRouter(history),
    routing,

    accounts,
    accountPlaces,
    alerts,
    agencies,
    contracts,
    conversations,
    expenses,
    form,
    forum,
    places,
    reports,
    snacks,
    ui,
    user,
    users,
  });
