import { createReducer } from 'redux-starter-kit';
import { get, setWith } from 'lodash';
import {
  mergeGlobalStateAndActionPayload,
  addLocalPaginationToGlobalState,
} from 'utils/reducers';

const initialState = {
  conversationById: {},
  conversationsIdsByPlaceId: {},
  conversationsIdsByAgencyId: {},
  conversationsIdsByContactId: {},
  agenciesConversationsPagination: {},
  messageById: {},
  messagesIdsByConversationId: {},
  conversationsPagination: {},
};

const places = createReducer(initialState, {
  CONVERSATION_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_CONVERSATION_SUCCESS: mergeGlobalStateAndActionPayload,
  RESOLVE_CONVERSATION_SUCCESS: mergeGlobalStateAndActionPayload,
  UNRESOLVE_CONVERSATION_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_CONVERSATION_LAST_READ_SUCCESS: mergeGlobalStateAndActionPayload,
  ACKNOWLEDGE_CONVERSATION_SUCCESS: mergeGlobalStateAndActionPayload,

  CONVERSATIONS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.conversationsPagination,
      payload,
      status: 'PENDING',
    }),

  CONVERSATIONS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.conversationsPagination,
      payload,
      status: 'ERROR',
    }),

  CONVERSATIONS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.conversationsPagination,
      ...action,
      status: 'DONE',
    });
  },

  CONVERSATION_MESSAGES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });

    state.messagesIdsByConversationId[payload.conversationId] =
      payload.data.result;
  },

  CONTACT_CONVERSATIONS_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.conversationsIdsByContactId[payload.contactId] = get(
      payload,
      'data.result',
    );
  },

  POST_CONVERSATION_MESSAGE_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });

    if (Boolean(state.messagesIdsByConversationId[payload.conversationId])) {
      state.messagesIdsByConversationId[payload.conversationId].push(
        payload.data.result,
      );
    }
  },

  ARCHIVE_CONVERSATION_SUCCESS: (state, { payload }) => {
    setWith(state, ['conversationById', payload, 'archive'], true, Object);

    // state.messagesIdsByConversationId[payload.conversationId] = [
    //   get(payload, 'data.result'),
    // ].concat(state.messagesIdsByConversationId[payload.conversationId] || []);

    // setWith(state, ['conversationById', payload.conversationId], {
    //   ...get(state, ['conversationById', payload.conversationId], {}),
    //   preview: get(
    //     payload,
    //     `data.entities.messages.${payload.data.result}.body`,
    //   ),
    //   lastReadMessageId: get(
    //     payload,
    //     `data.entities.messages.${payload.data.result}.id`,
    //   ),
    //   archive: false,
    //   updatedAt: Date.now(),
    // });
  },
});

// case 'AGENCY_CONVERSATIONS_SUCCESS':
//   return {
//     ...state,
//     conversationById: merge(
//       state.conversationById,
//       get(payload, 'data.entities.conversations'),
//     ),
//     conversationsIdsByAgencyId: {
//       ...state.conversationsIdsByAgencyId,
//       [payload.agencyId]: (
//         state.conversationsIdsByAgencyId[payload.agencyId] || []
//       ).concat(payload.data.result),
//     },
//     agenciesConversationsPagination: {
//       ...state.agenciesConversationsPagination,
//       [payload.agencyId]: merge(
//         get(state, `agenciesConversationsPagination.${payload.agencyId}`),
//         generatePagination(
//           state.agenciesConversationsPagination,
//           payload.agencyId,
//           action,
//         ),
//       ),
//     },
//   };
// case 'PORTFOLIO_CONVERSATIONS_SUCCESS':
//   return {
//     ...state,
//     conversationById: merge(
//       state.conversationById,
//       get(payload, 'entities.conversations'),
//     ),
//     agenciesConversationsPagination: {
//       ...state.agenciesConversationsPagination,
//       portfolio: merge(
//         get(state, `agenciesConversationsPagination.${payload.agencyId}`),
//         generatePagination(
//           state.agenciesConversationsPagination,
//           'portfolio',
//           action,
//         ),
//       ),
//     },
//   };
// case 'CONVERSATION_MESSAGES_SUCCESS':
//   return {
//     ...state,
//     messageById: merge(
//       state.messageById,
//       get(payload, 'data.entities.messages'),
//     ),
//     messagesIdsByConversationId: {
//       ...state.messagesIdsByConversationId,
//       [payload.conversationId]: payload.data.result,
//     },
//   };

// conversationById: {
//   ...state.conversationById,
//   [payload.conversationId]: {
//     ...state.conversationById[payload.conversationId],
//     preview: get(
//       payload,
//       `data.entities.messages.${payload.data.result}.body`,
//     ),
//     lastReadMessageId: get(
//       payload,
//       `data.entities.messages.${payload.data.result}.id`,
//     ),
//     archive: false,
//     updatedAt: Date.now(),
//   },

export default places;
