import Icon from 'react-icon-base';

const IcAdmin = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M24 6.5c0-.867-.718-1.571-1.6-1.571-.882 0-1.6.704-1.6 1.571 0 .29.086.56.227.793l-4.22 4.144a1.6 1.6 0 00-.807-.223c-.295 0-.568.084-.806.222l-1.82-1.788c.14-.233.226-.501.226-.79 0-.868-.718-1.572-1.6-1.572-.882 0-1.6.705-1.6 1.571 0 .186.039.36.1.526l-7.86 7.719a1.602 1.602 0 00-1.04-.388V2.964c0-.65.538-1.178 1.2-1.178h18.4c.662 0 1.2.528 1.2 1.178v.786c0 .217.18.393.4.393.22 0 .4-.176.4-.393v-.786c0-1.083-.897-1.964-2-1.964H2.8c-1.103 0-2 .881-2 1.964v13.97c-.476.272-.8.773-.8 1.352 0 .866.718 1.571 1.6 1.571.882 0 1.6-.705 1.6-1.571 0-.186-.039-.36-.1-.526l7.86-7.719c.28.238.641.388 1.04.388.297 0 .571-.085.81-.224l1.818 1.786a1.533 1.533 0 00-.228.795c0 .866.718 1.571 1.6 1.571.882 0 1.6-.705 1.6-1.571 0-.291-.086-.56-.227-.793l4.22-4.144a1.6 1.6 0 00.807.222v12.965a1.19 1.19 0 01-1.2 1.178H20V11.607a.397.397 0 00-.4-.393c-.22 0-.4.176-.4.393v10.607h-2.4v-6.678a.397.397 0 00-.4-.393c-.22 0-.4.176-.4.393v6.678h-2.4v-9.821a.397.397 0 00-.4-.393c-.22 0-.4.176-.4.393v9.821h-2.4v-9.821A.397.397 0 0010 12c-.22 0-.4.176-.4.393v9.821H7.2v-6.678a.397.397 0 00-.4-.393c-.22 0-.4.176-.4.393v6.678H4v-1.571a.397.397 0 00-.4-.393c-.22 0-.4.176-.4.393v1.571h-.4a1.19 1.19 0 01-1.2-1.178.397.397 0 00-.4-.393c-.22 0-.4.176-.4.393 0 1.083.897 1.964 2 1.964h18.4c1.103 0 2-.881 2-1.964V7.853c.476-.273.8-.774.8-1.353zM1.6 19.071a.794.794 0 01-.8-.785c0-.434.359-.786.8-.786.441 0 .8.352.8.786a.794.794 0 01-.8.785zm9.6-10.214c0-.433.359-.786.8-.786.441 0 .8.353.8.786a.794.794 0 01-.8.786.794.794 0 01-.8-.786zm4.8 4.714a.794.794 0 01-.8-.785c0-.434.359-.786.8-.786.441 0 .8.352.8.786a.794.794 0 01-.8.785zm6.4-6.285a.805.805 0 01-.56-.227l-.004-.005-.006-.004a.776.776 0 01-.23-.55c0-.433.359-.786.8-.786.441 0 .8.353.8.786a.794.794 0 01-.8.786z"
      fillRule="nonzero"
    />
  </Icon>
);

export default IcAdmin;
