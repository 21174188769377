import { round } from 'lodash';

export const base = 16;
export const spacing = (input = 1) => `${Number(input) * base}px`;
export const size = (input = 0) => `${round(Math.sqrt(Math.sqrt(2)) ** input, 3)}rem`;

const colors = {
   blue: {
      default: '#0080c8',
      medium: '#285fcc',
      dark: '#00364d',
      vibrant: '#009de0',
   },
   black: {
      default: '#212121',
      secondary: '#707070',
      tertiary: '#9e9e9e',
      quaternary: '#cccccc',
   },
   red: {
      default: '#e22a2a',
      medium: '#d7263d',
      dark: '#bc021a',
   },
   grey: {
      blueish: '#a8b1ba',
      lightest: '#f6f7f7',
      lighter: '#f3f4f8',
      lightish: '#f2f4f7',
      mediumLightish: '#e0e4e8',
      default: '#f6f7f7',
      medium: '#dadede',
      light: '#fafafa',
      dark: '#bbb',
   },
   green: {
      default: '#26bf8c',
   },
   yellow: {
      default: '#ffbf00',
   },
   orange: {
      default: '#ff9819',
      dark: '#e66322',
   },
};

const color = (name, shade = 'default') => {
   return colors[name] && colors[name][shade];
};

const colorsUi = {
   primary: color('blue'),
   success: color('green'),
   error: color('red', 'medium'),
   warning: color('orange', 'dark'),
   imageShadow: 'rgba(2, 24, 43, .25)',
   blueShadow: 'rgba(89, 149, 179, .15)',
   text: color('blue', 'dark'),
   separator: color('grey', 'mediumLightish'),
   separatorLight: 'rgba(0, 0, 0, 0.05)',
   textLight: 'rgba(0, 0, 0, 0.6)',
   textLightish: 'hsl(210, 15%, 65%)',
   textLighter: 'rgba(0, 0, 0, 0.38)',
   uiLight: 'hsl(210, 15%, 95%)',
   uiLighter: 'hsl(210, 15%, 97%)',
   disabled: color('grey', 'medium'),
   disabledBackground: color('grey', 'medium'),
};

export const theme = {
   primary: '#009de0',
   primarylight: 'hsl(198, 100%, 60%)',
   primaryDark: '#002b40',
   primaryBackground: '#e1f2fa',
   primaryBackgroundUi: 'rgba(0, 157, 224, 0.12)',
   primarySeparator: 'rgba(0, 157, 224, 0.12)',
   success: '#65d182',
   error: '#e22a2a',
   warning: color('orange', 'dark'),
   imageShadow: 'rgba(2, 24, 43, .25)',
   blueShadow: 'rgba(89, 149, 179, .15)',
   text: color('blue', 'dark'),
   separator: color('grey', 'mediumLightish'),
   separatorLight: 'rgba(0, 0, 0, 0.08)',
   separatorUi: 'rgba(0, 0, 0, 0.12)',
   textLight: 'hsl(210, 15%, 45%)',
   textLightish: 'hsl(210, 15%, 65%)',
   textLighter: 'rgba(25, 75, 105, 0.3)',
   background: 'hsl(210, 15%, 95%)',
   backgroundLight: 'hsl(210, 15%, 97%)',
   disabled: color('grey', 'medium'),
   sidebarMenuActiveLinkBackgroundColor: 'rgba(0, 0, 0, 0.08)',

   borderRadius: '6px',
   spacing,
   size,

   buttonsDefaultAlignX: 'center',
};

const ui = name => colorsUi[name];

export { color, ui };
