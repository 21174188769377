import styled from 'styled-components';
import { size, ui } from './';

export const RowLabel = styled.p`
  margin: 0;
  color: ${ui('textLight')};
  font-size: ${size(-1)};
`;

export const RowTitle = styled.p`
  font-weight: 500;
  margin: 0;

  + ${RowLabel} {
    margin-top: ${({ theme }) => theme.spacing(0.25)};
  }
`;
