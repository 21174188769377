import { CLIENT_ID, SCOPE } from '../constants';
import { get, post, put, rawPost } from './api';
import { identity, user } from './schema';

export const login = payload =>
   post(`/v1/oauth/token`, {
      ...payload,
      client_id: CLIENT_ID,
      grant_type: 'password',
      scope: SCOPE || 'eseis admin',
   });

export const refreshToken = token =>
   post(`/v1/oauth/token`, {
      refresh_token: token,
      client_id: CLIENT_ID,
      grant_type: 'refresh_token',
      scope: SCOPE || 'eseis admin',
   });

export const rawRefreshToken = token =>
   rawPost(`/v1/oauth/token`, {
      refresh_token: token,
      client_id: CLIENT_ID,
      grant_type: 'refresh_token',
      scope: SCOPE || 'eseis admin',
   });

export const signup = username =>
   post('/v1/users/sign_up', {
      user: { email: decodeURIComponent(username), sergic_offer: 'sergic' },
      client_id: CLIENT_ID,
   });

export const sendForgottenPasswordEmail = (username, recaptchaToken) =>
   post('/v1/users/password', {
      user: { email: decodeURIComponent(username), sergic_offer: 'sergic' },
      recaptcha_token: recaptchaToken,
      client_id: CLIENT_ID,
   });

export const getMe = () => get(`/v1/users/me`, user);
export const putMe = payload => put(`/v1/users/me`, payload, user);

export const resetPassword = ({ email, password, resetToken }) =>
   post(`/v1/users/reset`, {
      user: {
         email: decodeURIComponent(email),
         password,
         sergic_offer: 'sergic',
      },
      reset_password_token: resetToken,
      client_id: CLIENT_ID,
   });

export const acceptInvite = ({ email, password, invitationToken }) =>
   post(`/v1/users/accept`, {
      user: { email: decodeURIComponent(email), password },
      invitation_token: invitationToken,
      client_id: CLIENT_ID,
   });

export const getMePrivacySettings = () => get(`/v1/users/me/privacy_settings`);
export const putMePrivacySettings = payload => put(`/v1/users/me/privacy_settings`, payload);

export const updateUserPassword = payload => put(`/v1/users/me/passwords`, payload);

export const getUserIdentities = () => get(`/v1/users/me/identities`, [identity]);
