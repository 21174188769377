import styled, { css } from 'styled-components';
import { withRouter } from 'react-router';
import { Link, Route } from 'react-router-dom';
import { getCrumbName } from 'utils/routes';
import { color, media, QueryParamsAwareNavLink } from 'ui';

const Title = styled.h1`
   margin-top: 0;
   margin-bottom: 0;
   display: inline-block;
   font-size: inherit;
   font-weight: 600;
`;

const CrumbLink = styled(QueryParamsAwareNavLink)`
   font-weight: 600;
   color: currentColor;
   text-decoration: underline;
   display: none;

   ${props =>
      props.isLastCrumb &&
      css`
         display: inline-block;
      `};

   ${media.desktop`
    display: inline-block;
  `};
`;

const Wrapper = styled.div`
   align-items: center;
   display: flex;
   justify-content: center;
   background-color: ${({ theme }) => theme.primary};
   padding: ${({ theme }) => theme.spacing()};
   color: #fff;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 2000;
   min-height: 60px;
   font-size: 18px;

   ${media.desktop`
    min-height: 0;
    position: static;
    background-color: #fff;
    padding: 0;
    color: inherit;
    justify-content: flex-start;
  `};
`;

const CrumbWrapper = styled.span`
   display: none;
   align-items: baseline;

   ${props =>
      props.isLastCrumb &&
      css`
         display: inline-block;

         ${media.desktop`
        color: ${({ theme }) => theme.textLight}
      `};
      `};

   ${media.desktop`
    display: inline-block;
  `};
`;

const SeparatorWrapper = styled.span`
   display: none;
   margin-left: ${({ theme }) => theme.spacing(0.5)};
   margin-right: ${({ theme }) => theme.spacing(0.5)};
   color: ${color('grey', 'medium')};

   ${media.desktop`display: inline-block;`};
`;

const BreadcrumbAction = styled.span`
   display: inline-block;
   display: flex;
   align-items: center;
   padding-left: ${({ theme }) => theme.spacing()};
   font-size: ${({ theme }) => theme.size(0)};
`;

export const BreadcrumbButton = styled(Link)`
   text-decoration: none;
   display: inline-block;
   font-size: ${({ theme }) => theme.size(-1)};
   background-color: ${color('blue', 'dark')};
   padding: ${({ theme }) => theme.spacing(0.25)} ${({ theme }) => theme.spacing(0.5)};
   color: #fff;
   border-radius: 999px;
`;

const Separator = () => <SeparatorWrapper>›</SeparatorWrapper>;

const Crumb = props => {
   const { location, match, customTranslations, isRoot } = props;
   const { pathname } = location;
   const { url, params } = match;
   const { crumb } = params;
   const isLastCrumb = pathname === url;

   if (isLastCrumb) {
      return isRoot ? (
         <Title>{getCrumbName(crumb, url, customTranslations)}</Title>
      ) : (
         <CrumbWrapper isLastCrumb={isLastCrumb}>
            <Separator isLastCrumb={isLastCrumb} />
            <span>{getCrumbName(crumb, url, customTranslations)}</span>
         </CrumbWrapper>
      );
   }

   return (
      <>
         {!isRoot && <Separator />}

         <CrumbLink to={url} isLastCrumb={isLastCrumb}>
            {getCrumbName(crumb, url, customTranslations)}
         </CrumbLink>

         <Route path={`${url}/:crumb`} render={() => <ConnectedCrumb customTranslations={customTranslations} />} />
      </>
   );
};

const ConnectedCrumb = withRouter(Crumb);

const Breadcrumb = ({ customTranslations, children }) => (
   <Wrapper>
      <Route path='/:crumb' render={() => <ConnectedCrumb isRoot customTranslations={customTranslations} />} />
      {children && <BreadcrumbAction>{children}</BreadcrumbAction>}
   </Wrapper>
);

export default withRouter(Breadcrumb);
