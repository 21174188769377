import { merge } from './';
import { get, keys, endsWith, setWith } from 'lodash';
import defs from 'api/schemaDefs';
import { getPageAndRestFromQuery } from './pagination';

export const mergeGlobalStateAndActionPayload = (state, { payload }) => {
  const nested = keys(payload).indexOf('data') > -1;
  const dataStateKeys = keys(state).filter(key => endsWith(key, 'ById'));
  const entitiesPath = nested ? `data.entities` : `entities`;

  dataStateKeys.forEach(key => {
    state[key] = merge(
      state[key],
      get(payload, `${entitiesPath}.${defs[key.slice(0, -4)]}`),
    );
  });
};

export const addLocalPaginationToGlobalState = ({
  state,
  payload,
  meta,
  status,
}) => {
  const { sliceId, params } = payload;
  const { page, query } = getPageAndRestFromQuery(params);

  setWith(
    state,
    [sliceId, query, 'loadingState', page].filter(Boolean),
    status,
    Object,
  );

  if (status === 'DONE') {
    const pagination = {
      total: meta.total,
      perPage: meta.perPage,
      lastPage: Math.ceil(meta.total / meta.perPage),
    };

    setWith(
      state,
      [sliceId, query, 'idsByPage', meta.page || 1].filter(Boolean),
      payload.data.result,
      Object,
    );

    setWith(
      state,
      [sliceId, query, 'pagination'].filter(Boolean),
      pagination,
      Object,
    );
  }
};
