import { createReducer } from 'redux-starter-kit';
import { get } from 'lodash';
import {
  mergeGlobalStateAndActionPayload,
  addLocalPaginationToGlobalState,
} from 'utils/reducers';

const initialState = {
  topicById: {},
  topicsPagination: {},
  postById: {},
  postsPagination: {},
  addedPostsIdsByTopicId: {},
  topicCategoryById: {},
  topicsCategoriesIds: [],
};

const forum = createReducer(initialState, {
  TOPICS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.topicsPagination,
      payload,
      status: 'PENDING',
    }),

  TOPICS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.topicsPagination,
      payload,
      status: 'ERROR',
    }),

  TOPICS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.topicsPagination,
      ...action,
      status: 'DONE',
    });
  },

  TOPIC_POSTS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.postsPagination,
      payload,
      status: 'PENDING',
    }),

  TOPIC_POSTS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.postsPagination,
      payload,
      status: 'ERROR',
    }),

  TOPIC_POSTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.postsPagination,
      ...action,
      status: 'DONE',
    });
  },

  POST_TOPIC_POST_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.addedPostsIdsByTopicId[payload.id] = [
      payload.data.result,
      ...(state.addedPostsIdsByTopicId[payload.id] || []),
    ];
  },

  TOPIC_CATEGORIES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.topicsCategoriesIds = get(payload, 'result', []);
  },

  TOPIC_SUCCESS: mergeGlobalStateAndActionPayload,
  MARK_TOPIC_AS_READ_SUCCESS: mergeGlobalStateAndActionPayload,
  MARK_TOPIC_AS_UNREAD_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_POST_SUCCESS: mergeGlobalStateAndActionPayload,
  ARCHIVE_TOPIC_SUCCESS: mergeGlobalStateAndActionPayload,
  UNARCHIVE_TOPIC_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_TOPIC_SUCCESS: mergeGlobalStateAndActionPayload,

  LOGOUT: () => initialState,
});

export default forum;
