import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { MdHighlightOff, MdSearch } from 'react-icons/md';
import Animate from 'react-move/Animate';
import { ButtonReset, color } from 'ui';
import { InputSearch } from 'ui/forms';
import MDSpinner from 'react-md-spinner';

const CancelableInputContainer = styled.div`
   position: relative;
   display: inline-block;

   > ${ButtonReset} {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: ${({ theme }) => theme.spacing(0.5)};
      display: flex;
      align-items: center;

      &:hover {
         cursor: pointer;
      }
   }

   svg {
      fill: ${color('black', 'tertiary')};

      &:hover {
         fill: ${color('black', 'secondary')};
      }
   }

   ${props =>
      (props.full || props.size === 'full') &&
      css`
         width: 100%;
      `};
`;

const SearchIcon = styled.div`
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   left: ${({ theme }) => theme.spacing(0.25)};
   padding: ${({ theme }) => theme.spacing(0.25)};
   line-height: 0.8;
`;

const Search = ({ onChange, value, onClear, secondary, placeholder, full, size, isFetching, variant, focusOnLoad, hasError, ...props }) => {
   const searchRef = useRef();

   useEffect(() => {
      if (!focusOnLoad) {
         return;
      }

      searchRef.current.focus();
   }, [focusOnLoad]);

   return (
      <CancelableInputContainer full={full} size={size}>
         {variant !== 'none' && <SearchIcon>{isFetching ? <MDSpinner style={{ marginRight: 8 }} singleColor='currentColor' size={16} /> : <MdSearch size='20' />}</SearchIcon>}

         <InputSearch
            type='text'
            onChange={onChange}
            value={value}
            secondary={secondary}
            placeholder={placeholder}
            variant={variant}
            style={full || size === 'full' ? { width: '100%' } : size === 'large' ? { width: '25em' } : {}}
            size={size}
            ref={searchRef}
            hasError={hasError}
            {...props}
         />

         <Animate
            show={Boolean(value)}
            start={{ opacity: 0 }}
            enter={[
               {
                  opacity: [1],
                  timing: { duration: 100 },
               },
            ]}
            leave={{
               opacity: [0],
               timing: { duration: 100 },
            }}
         >
            {({ opacity }) => (
               <ButtonReset phantom style={{ opacity }} onClick={onClear} type='button'>
                  <MdHighlightOff size='20' />
               </ButtonReset>
            )}
         </Animate>
      </CancelableInputContainer>
   );
};

Search.defaultProps = {
   value: '',
   placeholder: 'Rechercher',
};

export default Search;
