import styled, { css } from 'styled-components';
import { size } from 'ui';
import { GoAlert } from 'react-icons/go';
import { MdChatBubble, MdFolder } from 'react-icons/md';
import { FaFileInvoice } from 'react-icons/fa';
import { MdBrokenImage } from 'react-icons/md';

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(4)};
  text-align: center;
  color: ${({ theme }) => theme.textLight};
  font-size: ${size(1)};

  ${({ variant }) =>
    variant === 'bare' &&
    css`
      padding-top: 0;
    `};
`;

const IconWrapper = styled.p`
  font-size: ${size(6)};
  margin-bottom: ${({ theme }) => theme.spacing()};
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getIcon = icon => {
  switch (icon) {
    case 'messages':
      return <MdChatBubble />;
    case 'reports':
      return <GoAlert />;
    case 'folders':
      return <MdFolder />;
    case 'invoices':
      return <FaFileInvoice />;
    case 'brokenDocument':
      return <MdBrokenImage />;
    default:
      return null;
  }
};

const EmptyState = ({ children, icon, ...props }) => (
  <Wrapper {...props}>
    <IconWrapper>{getIcon(icon)}</IconWrapper>
    {children}
  </Wrapper>
);

export default EmptyState;
