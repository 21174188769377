import { createReducer } from 'redux-starter-kit';
import {
  mergeGlobalStateAndActionPayload,
  addLocalPaginationToGlobalState,
} from 'utils/reducers';

const initialState = {
  alertById: {},
  alertsIds: [],
  alertsPagination: {},
};

const alerts = createReducer(initialState, {
  ALERTS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.alertsPagination,
      payload,
      status: 'PENDING',
    }),

  ALERTS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.alertsPagination,
      payload,
      status: 'ERROR',
    }),

  ALERTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.alertsPagination,
      ...action,
      status: 'DONE',
    });
  },

  UPDATE_ALERT_SUCCESS: (state, action) =>
    mergeGlobalStateAndActionPayload(state, action),

  LOGOUT: () => initialState,
});

export default alerts;
