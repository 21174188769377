import { Button, Stack } from '@tymate/sergic';
import { TextField } from '@tymate/sergic/components/Formik';
import { login } from 'api/user';
import AuthLayout from 'components/AuthLayout';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { Box } from 'ui';
import { BoxTitle } from 'ui/auth';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { receiveLogin } from 'actions/user';
import { Redirect } from 'react-router-dom';

const formatWarning = ({ explanation } = {}) => {
   switch (explanation) {
      case 'account_locked':
         return 'Votre compte a été bloqué après cinq tentatives de connexion échouées. Il sera débloqué dans 24h.';
      case 'last_attempt_before_lock':
         return 'Attention, ceci est votre dernier essai avant que votre compte ne soit bloqué.';
      default:
         return '';
   }
};

const Warning = styled.div`
   color: ${({ theme }) => theme.error};
`;

const Login = () => {
   const dispatch = useDispatch();
   const isAuthenticated = useSelector(state => state?.user?.isAuthenticated);

   const { error, mutateAsync } = useMutation(login, {
      onError: () => {
         toast.error('Mauvais identifiants', { duration: 5000 });
      },
      onSuccess: payload => {
         dispatch(receiveLogin(payload));
      },
   });

   const handleSubmit = async values => {
      await mutateAsync(values);
   };

   const shouldShowWarning = Boolean(error?.explanation);

   if (isAuthenticated) {
      return <Redirect to='/' />;
   }

   return (
      <AuthLayout>
         <Box padded>
            <Formik
               onSubmit={handleSubmit}
               initialValues={{
                  username: '',
                  password: '',
               }}
            >
               {({ isSubmitting }) => (
                  <Form autoComplete='off'>
                     <BoxTitle>Connexion par email</BoxTitle>

                     <Stack direction='column' gutterSize={1}>
                        <TextField id='login-username' label='Email' name='username' autoComplete='off' />

                        <TextField
                           type='password'
                           label='Mot de passe'
                           id='login-password'
                           name='password'
                           autoComplete='off'
                        />

                        {shouldShowWarning && <Warning>{formatWarning(error)}</Warning>}

                        <Stack>
                           <Button variant='text' to='/mot-de-passe-oublie'>
                              Mot de passe oublié ?
                           </Button>
                        </Stack>

                        <Button variant='primary' isLoading={isSubmitting} size='full'>
                           Se connecter
                        </Button>
                     </Stack>
                  </Form>
               )}
            </Formik>
         </Box>

         <Stack direction='column' alignX='center' style={{ color: '#ffffff' }} padding={2} gutterSize={0.5}>
            <div>Je n’ai pas encore de compte…</div>
            <Button variant='lightText' to='/inscription'>
               Activer mon compte
            </Button>
         </Stack>
      </AuthLayout>
   );
};

export default Login;
