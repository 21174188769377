import { createReducer } from 'redux-starter-kit';
import { find, get, setWith } from 'lodash';

export const initialState = {
   selectedAgencyId: null,
   selectedAgencyUuid: null,
   selectedPlaceId: null,
   selectedPlaceUuid: null,
   reportsFilters: {},
   conversationsFilters: {},
   topicsFilters: {},
   placesFilters: {},
   alertsFilters: {},
   contactsSearch: '',
   placesSearch: '',
   selectedFiscalYears: {},
};

const ui = createReducer(initialState, {
   SELECT_AGENCY: (state, { payload }) => {
      state.selectedAgencyId = payload.id;
      state.selectedAgencyUuid = payload.uuid;
   },

   SELECT_PLACE: (state, { payload }) => {
      state.selectedPlaceId = payload.id;
      state.selectedPlaceUuid = payload.uuid;
   },

   SELECT_REPORTS_FILTERS: (state, { payload }) => {
      state.reportsFilters = { ...state.reportsFilters, ...payload };
   },

   SELECT_CONVERSATIONS_FILTERS: (state, { payload }) => {
      state.conversationsFilters = { ...state.conversationsFilters, ...payload };
   },

   SELECT_TOPICS_FILTERS: (state, { payload }) => {
      state.topicsFilters = { ...state.topicsFilters, ...payload };
   },

   SELECT_PLACES_FILTERS: (state, { payload }) => {
      state.placesFilters = { ...state.placesFilters, ...payload };
   },

   SELECT_CONTACTS_FILTERS: (state, { payload }) => {
      state.contactsFilters = { ...state.contactsFilters, ...payload };
   },

   SELECT_PARTNERS_FILTERS: (state, { payload }) => {
      state.partnersFilters = { ...state.partnersFilters, ...payload };
   },

   SELECT_ALERTS_FILTERS: (state, { payload }) => {
      state.alertsFilters = { ...state.alertsFilters, ...payload };
   },

   SELECT_PLACE_FISCAL_YEAR: (state, { payload }) => {
      setWith(state, ['selectedFiscalYears', payload.placeId], payload.fiscalYearId);
   },

   PLACE_FISCAL_YEARS_SUCCESS: (state, { payload }) => {
      if (!get(state, ['selectedFiscalYears', payload.placeId])) {
         setWith(
            state,
            ['selectedFiscalYears', payload.placeId],
            get(
               find(
                  payload.data.result.map(id => payload.data.entities.fiscalYears[id]),
                  ({ current }) => current
               ),
               'id'
            ),
            Object
         );
      }
   },

   LOGOUT: () => initialState,
});

export default ui;
