import {Field} from 'formik';
import styled from 'styled-components';
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md';
import {Stack} from '@tymate/margaret';

const RadioWrapper = styled(Stack)`
  position: relative;
  cursor: pointer;

  + *:before {
    position: absolute;
    content: '';
    top: 0;
    left: calc(${({ theme }) => theme.spacing(2)} + 19px);
    right: 0;
    border-top: 1px solid ${({ theme }) => theme.separator};
  }

  input:not(:checked) svg {
    color: ${({ theme }) => theme.textLight};
  }
`;

const RadioLabel = styled.div`
  line-height: 1.2;
`;

const RadioIcon = styled(Stack)`
  ${({ isChecked, theme }) =>
    isChecked &&
    `
      color: ${theme.primary};
    `}
`;

export const RadioFormik = ({
  name,
  label,
  value,
  disabled,
  columns,
  iconChecked,
  iconUnchecked }) => (
  <Field name={name}>
    {({ field, form: { setFieldValue } }) => {
      const checked =
        field.value === value || Number(field.value) === Number(value);

      return (
        <RadioWrapper
          checked={checked}
          disabled={disabled}
          columns={columns}
          as="label"
          padding={1}
        >
          <input
            type="radio"
            name={name}
            value={value}
            disabled={disabled}
            onChange={e => setFieldValue(name, e.target.value)}
            style={{ display: 'none' }}
            checked={checked}
          />

          <RadioIcon alignY="center" marginRight={1} isChecked={checked}>
            {checked ? (
              iconChecked || <MdRadioButtonChecked size={19} />
            ) : (
              iconUnchecked || <MdRadioButtonUnchecked size={19} />
            )}
          </RadioIcon>

          <RadioLabel>{label}</RadioLabel>
        </RadioWrapper>
      );
    }}
  </Field>
);

export default RadioFormik;