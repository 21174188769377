import { createSelector } from 'reselect';
import { get, camelCase, orderBy } from 'lodash';
import queryString from 'query-string';

export const getParams = (state, { match }) => match.params;
export const getQuery = (state, { location: { search } }) => search;
export const getProps = (state, props) => props;

/**
 * Select top-level entities
 */
export const getPlaces = state => state.places;
export const getUi = state => state.ui;
export const getUser = state => state.user;
export const getUsers = state => state.users;
export const getReports = state => state.reports;
export const getConversations = state => state.conversations;
export const getAgencies = state => state.agencies;
export const getContracts = state => state.contracts;
export const getPlace = (state, { match }) =>
  get(state, `places.placeById.${get(match, 'params.id')}`);
export const getAccounts = state => state.accounts;
export const getForum = state => state.forum;
export const getAlerts = state => state.alerts;

export const selectAppState = state => {
  const { selectedAgencyId, selectedPlaceId } = state.ui;

  return {
    selectedPlaceId,
    selectedAgencyId: selectedAgencyId || 'portfolio',
  };
};

export const getCurrentUser = createSelector(
  [getUser, getUsers],
  ({ userId }, { userById }) => userById[userId] || {},
);

export const selectReport = createSelector(
  [getParams, getReports, getUsers, getPlaces, getContracts, getUi],
  (
    { reportId },
    { reportById, categoryById, reportEventById, reportResolutionById },
    { userById },
    { equipmentById, placeById },
    {
      contractById,
      contractsIdsByContactId,
      contractsIdsByAgencyIdAndByUserId,
    },
    { selectedAgencyId },
  ) => {
    let report = reportById[reportId];

    if (!report) {
      return {
        witnesses: [],
        reportEvents: [],
      };
    }

    const contractsByUserId = Boolean(selectedAgencyId)
      ? contractsIdsByAgencyIdAndByUserId[selectedAgencyId] || {}
      : contractsIdsByContactId || {};

    const witnesses = (report.witnesses || []).map(witnessId => ({
      ...userById[witnessId],
      contracts: (contractsByUserId[witnessId] || [])
        .map(contractId => contractById[contractId])
        .filter(contract => contract.placeId === report.placeId),
    }));
    const category = categoryById[report.category];
    const author = {
      ...userById[report.author],
      contracts: (contractsByUserId[report.author] || []).map(
        contractId => contractById[contractId],
      ),
    };
    const reportEvents = (report.reportEvents || []).map(reportEventId => {
      const reportEvent = reportEventById[reportEventId] || {};
      return {
        ...reportEvent,
        author: userById[reportEvent.author],
      };
    });
    const rawReportResolution = reportResolutionById[report.reportResolution];
    const reportResolution = {
      ...rawReportResolution,
      author: userById[get(rawReportResolution, 'userId')] || {},
    };
    const equipment = equipmentById[report.equipmentId];
    const place = Boolean(equipment) ? get(placeById, equipment.placeId) : {};

    return {
      ...report,
      author,
      category,
      witnesses,
      reportEvents,
      reportResolution,
      equipment,
      place,
    };
  },
);

export const getFilteredReportCategories = createSelector(
  [getReports, getProps],
  ({ categoriesIds, categoryById }, props) => {
    if (!props.type) {
      return categoriesIds.map(id => categoryById[id]);
    }

    const translations = {
      urgence: 'emergency',
      dysfonctionnement: 'disfunction',
    };

    const type = translations[props.type];
    return categoriesIds
      .map(id => categoryById[id])
      .filter(({ kind }) => kind === type);
  },
);

export const selectContact = createSelector(
  [getUsers, getParams, getReports, getPlaces, getConversations, getContracts],
  (
    { userById, identityById },
    { id },
    { reportById, categoryById, reportsIdsByContactId },
    { placeById },
    { conversationById, conversationsIdsByContactId },
    { contractById },
  ) => {
    const user = get(identityById, [id], {});

    return {
      ...user,
      contracts: get(user, 'contractIds', [])
        .map(contractId => {
          const contract = contractById[contractId];
          return {
            ...contract,
            place: placeById?.[contract?.placeId],
          };
        })
        .filter(Boolean),
      places: (user.placeIds || [])
        .map(placeId => placeById[placeId])
        .filter(Boolean),
      conversations: (conversationsIdsByContactId[user.userId] || []).map(
        conversationId => {
          const conversation = conversationById[conversationId] || {};
          return {
            ...conversation,
            members: (conversation.members || []).map(
              memberId => userById[memberId],
            ),
          };
        },
      ),
      reports: (reportsIdsByContactId[user.userId] || [])
        .map(reportId => {
          const report = reportById[reportId];

          return {
            ...report,
            category: categoryById[report.category],
          };
        })
        .filter(Boolean),
    };
  },
);

export const selectPlaceNotifications = createSelector(
  [getPlaces, getProps, getQuery],
  ({ notificationById, notificationsPagination }, { placeId }, search) => {
    if (search === '') {
      search = '?';
    }

    search = queryString.stringify({
      sort: '-updatedAt',
      ...queryString.parse(search),
    });

    const { page = 1, ...parsedQuery } = queryString.parse(search);
    const query = `?${queryString.stringify(parsedQuery)}`;

    const notificationsPageIds = get(
      notificationsPagination,
      `${placeId}['${query}'].idsByPage.${page}`,
    );

    if (!notificationsPageIds) {
      return [];
    }

    return notificationsPageIds.map(nid => notificationById[nid]);
  },
);

export const selectNotification = createSelector(
  [getPlaces, getParams],
  ({ notificationById }, { notificationId }) =>
    notificationById[notificationId],
);

export const selectPlaceTreasuries = createSelector(
  [getPlaces, getProps],
  ({ treasuryById, treasuriesIdsByPlaceId }, { placeId }) => {
    const treasuries = (treasuriesIdsByPlaceId[placeId] || []).map(
      tid => treasuryById[tid],
    );

    return treasuries.reduce(
      (acc, curr) => ({ ...acc, [camelCase(curr.kind)]: curr }),
      {},
    );
  },
);

export const selectPlaceBudgets = createSelector(
  [getPlaces, getUi, getProps],
  (
    { budgetById, budgetsIdsByPlaceIdAndByFiscalYearId },
    { selectedFiscalYears = {} },
    { placeId },
  ) => {
    const selectedFiscalYearId = selectedFiscalYears[placeId];

    const budgetsIds =
      get(
        budgetsIdsByPlaceIdAndByFiscalYearId,
        `[${placeId}][${selectedFiscalYearId}]`,
      ) || [];

    const budgets = budgetsIds.map(id => budgetById[id]);

    const totalBudget = {
      allocated: budgets.reduce((acc, curr) => acc + curr.allocatedAmount, 0),
      spent: budgets.reduce((acc, curr) => acc + curr.spentAmount, 0),
    };

    return { budgets, totalBudget };
  },
);

export const selectPlaceFiscalYears = createSelector(
  [getPlaces, getProps],
  ({ fiscalYearsIdsByPlaceId, fiscalYearById }, { placeId }) =>
    orderBy(
      (fiscalYearsIdsByPlaceId[placeId] || []).map(id => fiscalYearById[id]),
      'startDate',
      'desc',
    ),
);

export const selectTreasuryInvoices = createSelector(
  [getPlaces, getQuery, getProps, selectPlaceTreasuries],
  (
    { invoiceGroupById, invoiceById, invoicesPagination },
    search,
    { target },
    treasuries,
  ) => {
    const treasuryId = get(treasuries, `[${target}].id`);

    if (!treasuryId) {
      return [];
    }

    if (search === '') {
      search = '?';
    }

    const { page, ...parsedQuery } = queryString.parse(search);
    const query = `?${queryString.stringify(parsedQuery)}`;

    const pagination =
      get(invoicesPagination, `${treasuryId}['${query}'].idsByPage`) || {};

    const invoicesIds = pagination[page || 1] || [];

    return invoicesIds.map(id => invoiceGroupById[id]);
  },
);

export const selectTreasuryInvoicesPagination = createSelector(
  [getProps, getPlaces, getQuery, selectPlaceTreasuries],
  ({ target }, { invoicesPagination }, search, treasuries) => {
    const treasuryId = get(treasuries, `[${target}].id`);

    if (!treasuryId) {
      return {};
    }

    if (!search) {
      search = '?';
    }

    const { page, ...parsedQuery } = queryString.parse(search);
    const query = `?${queryString.stringify(parsedQuery || {})}`;

    return get(invoicesPagination, `${treasuryId}['${query}']`) || {};
  },
);

export const selectTreasuryCoownerAmounts = createSelector(
  [getPlaces, getQuery, getProps, selectPlaceTreasuries],
  (
    { coownerAmountById, coownerAmountsPagination },
    search,
    { target },
    treasuries,
  ) => {
    const treasuryId = get(treasuries, `[${target}].id`);

    if (!treasuryId) {
      return [];
    }

    if (search === '') {
      search = '?';
    }

    const { page, ...parsedQuery } = queryString.parse(search);
    const query = `?${queryString.stringify(parsedQuery)}`;

    const pagination =
      get(coownerAmountsPagination, `${treasuryId}['${query}'].idsByPage`) ||
      {};

    const coownerAmountsIds = pagination[page || 1] || [];
    return coownerAmountsIds.map(id => coownerAmountById[id]);
  },
);

export const selectTreasuryCoownerAmountsPagination = createSelector(
  [getProps, getPlaces, getQuery, selectPlaceTreasuries],
  ({ target }, { coownerAmountsPagination }, search, treasuries) => {
    const treasuryId = get(treasuries, `[${target}].id`);

    if (!treasuryId) {
      return {};
    }

    if (!search) {
      search = '?';
    }

    const { page, ...parsedQuery } = queryString.parse(search);
    const query = `?${queryString.stringify(parsedQuery || {})}`;

    return get(coownerAmountsPagination, `${treasuryId}['${query}']`) || {};
  },
);

export const selectTreasuryAccountPlaceEntries = createSelector(
  [getPlaces, getQuery, getProps, selectPlaceTreasuries],
  (
    { accountPlaceEntryById, accountPlaceEntriesPagination },
    search,
    { target },
    treasuries,
  ) => {
    const treasuryId = get(treasuries, `[${target}].id`);

    if (!treasuryId) {
      return [];
    }

    if (search === '') {
      search = '?';
    }

    const { page, ...parsedQuery } = {
      ...queryString.parse(search),
      sort: '-operationDate',
    };

    const query = `?${queryString.stringify(parsedQuery)}`;

    const pagination =
      get(
        accountPlaceEntriesPagination,
        `${treasuryId}['${query}'].idsByPage`,
      ) || {};

    const accountPlaceEntriesIds = pagination[page || 1] || [];

    return accountPlaceEntriesIds.map(id => accountPlaceEntryById[id]);
  },
);

export const selectTreasuryAccountPlaceEntriesPagination = createSelector(
  [getProps, getPlaces, getQuery, selectPlaceTreasuries],
  ({ target }, { accountPlaceEntriesPagination }, search, treasuries) => {
    const treasuryId = get(treasuries, `[${target}].id`);

    if (!treasuryId) {
      return {};
    }

    if (!search) {
      search = '?';
    }

    const { page, ...parsedQuery } = {
      ...queryString.parse(search),
      sort: '-operationDate',
    };

    const query = `?${queryString.stringify(parsedQuery || {})}`;

    return (
      get(accountPlaceEntriesPagination, `${treasuryId}['${query}']`) || {}
    );
  },
);

export const selectPlaceMaintenanceContractsCategories = createSelector(
  [getPlaces, getParams, getUi],
  (
    {
      maintenanceContractsCategoriesIdsByPlaceId,
      maintenanceContractsCategoryById,
    },
    { id },
    { selectedPlaceId },
  ) => {
    const maintenanceContractsIds =
      maintenanceContractsCategoriesIdsByPlaceId[selectedPlaceId || id] || [];

    return maintenanceContractsIds.map(
      mcid => maintenanceContractsCategoryById[mcid],
    );
  },
);

export const selectPlaceMaintenanceContract = createSelector(
  [getPlaces, getParams],
  (
    {
      maintenanceContractDocumentById,
      maintenanceContractById,
      maintenanceContractsIdByMaintenanceContractsCategoryId,
    },
    { contractId },
  ) => {
    const maintenanceContractId =
      maintenanceContractsIdByMaintenanceContractsCategoryId[contractId];

    if (!maintenanceContractId) {
      return {};
    }

    const maintenanceContract =
      maintenanceContractById[maintenanceContractId] || {};

    return {
      ...maintenanceContract,
      maintenanceContractDocuments: (
        maintenanceContract.maintenanceContractDocuments || []
      ).map(mcdid => maintenanceContractDocumentById[mcdid]),
    };
  },
);

export const selectPlaceMaintenanceContractCategory = createSelector(
  [getPlaces, getParams],
  ({ maintenanceContractsCategoryById }, { contractId }) =>
    maintenanceContractsCategoryById[contractId] || {},
);

export const selectPublicWorks = createSelector(
  [getPlaces, getProps, getQuery],
  ({ publicWorkById, publicWorksPagination }, { placeId }, search) => {
    if (search === '') {
      search = '?';
    }

    const { page, ...parsedQuery } = {
      ...queryString.parse(search),
      state: 'inProgress',
    };
    const query = `?${queryString.stringify(parsedQuery)}`;

    const pagination =
      get(publicWorksPagination, `${placeId}['${query}'].idsByPage`) || {};

    const publicWorksIds = pagination[page || 1] || [];
    return publicWorksIds.map(pwid => publicWorkById[pwid]);
  },
);

export const selectPublicWorksPagination = createSelector(
  [getPlaces, getQuery, getProps],
  ({ publicWorksPagination }, search, { placeId }) => {
    const pagination = publicWorksPagination[placeId];

    if (!search) {
      search = '?';
    }

    const { page, ...parsedQuery } = {
      ...queryString.parse(search),
      state: 'inProgress',
    };
    const query = `?${queryString.stringify(parsedQuery || {})}`;

    return get(pagination, `['${query}']`) || {};
  },
);

export const selectPublicWork = createSelector(
  [getPlaces, getParams],
  ({ publicWorkById }, { id }) => publicWorkById[id],
);

export const selectPlaceBudgetAccountEntries = createSelector(
  [getAccounts, getParams],
  ({ accountPlaceEntriesIdsByBudgetId, accountPlaceEntryById }, { id }) => {
    const accountPlaceEntriesIds = accountPlaceEntriesIdsByBudgetId[id] || [];
    return accountPlaceEntriesIds.map(apeid => accountPlaceEntryById[apeid]);
  },
);

export const selectPlaceBudgetProviderAccountEntries = createSelector(
  [getAccounts, getParams],
  (
    {
      providerAccountPlaceEntriesIdsByBudgetId,
      providerAccountPlaceEntryById,
      accountPlaceEntryById,
    },
    { id },
  ) => {
    const providerAccountPlaceEntriesIds =
      providerAccountPlaceEntriesIdsByBudgetId[id] || [];
    return providerAccountPlaceEntriesIds.map(papeid => {
      const providerAccountPlaceEntry = providerAccountPlaceEntryById[papeid];
      return {
        ...providerAccountPlaceEntry,
        accountPlaceEntries: providerAccountPlaceEntry.accountPlaceEntries.map(
          apeid => accountPlaceEntryById[apeid],
        ),
      };
    });
  },
);

export const selectPlaceBudgetAccountCodeAccountEntries = createSelector(
  [getAccounts, getParams],
  (
    {
      accountCodeAccountPlaceEntriesIdsByBudgetId,
      accountCodeAccountPlaceEntryById,
      accountPlaceEntryById,
    },
    { id },
  ) => {
    const accountCodeAccountPlaceEntriesIds =
      accountCodeAccountPlaceEntriesIdsByBudgetId[id] || [];

    return accountCodeAccountPlaceEntriesIds.map(acapeid => {
      const accountCodeAccountPlaceEntry =
        accountCodeAccountPlaceEntryById[acapeid];

      return {
        ...accountCodeAccountPlaceEntry,
        accountPlaceEntries: accountCodeAccountPlaceEntry.accountPlaceEntries.map(
          apeid => accountPlaceEntryById[apeid],
        ),
      };
    });
  },
);

export const selectBudget = createSelector(
  [getPlaces, getParams],
  ({ budgetById }, { id }) => budgetById[id],
);
