import Icon from 'react-icon-base';

const IcReport = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M12 18.712a.405.405 0 0 0 .404-.407v-2.44a.405.405 0 1 0-.808-.001v2.441c0 .225.18.407.404.407zm2.423-8.084a1.628 1.628 0 0 0-1.211 1.575v1.628c0 .756.515 1.394 1.211 1.575v-4.778zm.404-.865c.223 0 .404.182.404.406v5.695c0 .225-.18.407-.404.407a2.434 2.434 0 0 1-2.423-2.44v-1.628a2.434 2.434 0 0 1 2.423-2.44zm-4.039 2.44c0-.756-.515-1.394-1.211-1.575v4.778a1.628 1.628 0 0 0 1.211-1.575v-1.628zm.808 1.628a2.434 2.434 0 0 1-2.423 2.44.405.405 0 0 1-.404-.407V10.17c0-.224.18-.406.404-.406a2.434 2.434 0 0 1 2.423 2.44v1.628-.001zm-7.842-8.8l1.4.813a.403.403 0 0 0 .552-.149.409.409 0 0 0-.149-.556l-1.399-.813a.402.402 0 0 0-.552.149.408.408 0 0 0 .148.555v.001zm4.06-2.165a.403.403 0 0 0 .551.15.408.408 0 0 0 .148-.557L7.705 1.05a.402.402 0 0 0-.551-.149.408.408 0 0 0-.148.556l.808 1.409zm10.832 3.44c.07 0 .14-.02.201-.055l1.399-.814a.408.408 0 0 0-.097-.745.402.402 0 0 0-.307.04l-1.399.814a.409.409 0 0 0 .203.76zm-3.011-2.884a.403.403 0 0 0 .552-.149l.807-1.41a.408.408 0 0 0-.148-.555.402.402 0 0 0-.552.15l-.807 1.408a.408.408 0 0 0 .148.556zM12 2.44a.405.405 0 0 0 .404-.407V.407a.405.405 0 1 0-.808 0v1.627c0 .224.18.407.404.407V2.44zm3.563 6.489a.408.408 0 0 0 .247-.519 4.088 4.088 0 0 0-2.473-2.488.403.403 0 0 0-.515.25.408.408 0 0 0 .247.518 3.27 3.27 0 0 1 1.98 1.99c.073.21.303.322.514.249zM22.5 21.153v2.44c0 .225-.18.407-.404.407H1.904a.405.405 0 0 1-.404-.407v-2.44c0-1.046.981-2.034 2.02-2.034h2.018V9.356c0-3.589 2.9-6.509 6.462-6.509 3.563 0 6.462 2.92 6.462 6.509v8.542a.405.405 0 1 1-.808 0V9.356c0-3.14-2.536-5.695-5.654-5.695S6.346 6.216 6.346 9.356v10.17c0 .224-.18.406-.404.406H3.52c-.589 0-1.211.627-1.211 1.22v2.034h19.384v-2.033c0-.594-.622-1.22-1.211-1.22H7.583a.405.405 0 0 1-.404-.408c0-.224.18-.406.404-.406H20.48c1.038 0 2.019.988 2.019 2.034h.001z" />
    </g>
  </Icon>
);

export default IcReport;
