import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { FiCheckCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import {
   hasOneDigit,
   hasOneLowerLetter,
   hasOneSpecialCharacter,
   hasOneUpperLetter,
   isLongerThanTwelve,
} from '../utils/validation';

const Conditions = styled(Stack).attrs({ gutterSize: 0.5, marginTop: 1 })``;

const ConditionWrapper = styled(Stack)`
   ${({ isChecked, theme }) =>
      isChecked &&
      `
      color: ${theme.success}
    `}

   ${({ isChecked, isDirty, theme }) =>
      !isChecked &&
      isDirty &&
      `
      color: ${theme.error}
    `}
`;

const Condition = ({ label, isChecked, isDirty, ...props }) => (
   <ConditionWrapper isChecked={isChecked} isDirty={isDirty} alignY='center' gutterSize={0.25} {...props}>
      <Stack>
         <FiCheckCircle />
      </Stack>
      <div>{label}</div>
   </ConditionWrapper>
);

const PasswordConditions = ({ password, isDirty }) => {
   const { t } = useTranslation('authForm');

   return (
      <Conditions direction='column' alignY='center'>
         <Condition label='Majuscule' isChecked={hasOneUpperLetter(password)} isDirty={isDirty} />
         <Condition label='Minuscule' isChecked={hasOneLowerLetter(password)} isDirty={isDirty} />
         <Condition label='Chiffre' isChecked={hasOneDigit(password)} isDirty={isDirty} />
         <Condition label='Caractère spécial' isChecked={hasOneSpecialCharacter(password)} isDirty={isDirty} />
         <Condition label='Au moins 12 caractères' isChecked={isLongerThanTwelve(password)} isDirty={isDirty} />
      </Conditions>
   );
};
export default PasswordConditions;
