import { createAction } from 'redux-actions';
import * as Api from 'api/agencies';
import { createActionWithPagination } from 'utils/pagination';

const requestAgencies = createAction('AGENCIES_REQUEST');
const receiveAgencies = createActionWithPagination('AGENCIES_SUCCESS');
const failAgencies = createAction('AGENCIES_ERROR');

export const getAgencies = () => dispatch => {
  dispatch(requestAgencies());

  return Api.getAgencies().then(
    response => dispatch(receiveAgencies(response)),
    error => dispatch(failAgencies(error)),
  );
};
