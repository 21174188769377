import { createReducer } from 'redux-starter-kit';
import { get } from 'lodash';
import { merge } from 'utils';
import { generatePagination } from 'utils/pagination';
import {
  addLocalPaginationToGlobalState,
  mergeGlobalStateAndActionPayload,
} from 'utils/reducers';

const initialState = {
  userById: {},
  portfolioContactsPagination: {},
  portfolioPartnersPagination: {},
  partnerById: {},
  identityById: {},
  contactsPagination: {},
};

const users = createReducer(initialState, {
  UPDATE_ME_SUCCESS: mergeGlobalStateAndActionPayload,
  ME_SUCCESS: mergeGlobalStateAndActionPayload,
  REPORT_SUCCESS: mergeGlobalStateAndActionPayload,
  CONTACT_SUCCESS: mergeGlobalStateAndActionPayload,
  CONVERSATION_SUCCESS: mergeGlobalStateAndActionPayload,
  CONVERSATIONS_SUCCESS: mergeGlobalStateAndActionPayload,
  PORTFOLIO_CONVERSATIONS_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_CONVERSATION_SUCCESS: mergeGlobalStateAndActionPayload,
  PORTFOLIO_REPORTS_SUCCESS: mergeGlobalStateAndActionPayload,
  TOPIC_SUCCESS: mergeGlobalStateAndActionPayload,
  PORTFOLIO_TOPICS_SUCCESS: mergeGlobalStateAndActionPayload,
  PLACE_EQUIPMENTS_SUCCESS: mergeGlobalStateAndActionPayload,
  PLACE_CONVERSATIONS_SUCCESS: mergeGlobalStateAndActionPayload,
  AGENCY_CONVERSATIONS_SUCCESS: mergeGlobalStateAndActionPayload,
  CONVERSATION_MESSAGES_SUCCESS: mergeGlobalStateAndActionPayload,
  AGENCY_REPORTS_SUCCESS: mergeGlobalStateAndActionPayload,
  CONTACT_CONVERSATIONS_SUCCESS: mergeGlobalStateAndActionPayload,
  AGENCY_TOPICS_SUCCESS: mergeGlobalStateAndActionPayload,
  PARTNER_SUCCESS: mergeGlobalStateAndActionPayload,

  CONTACTS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.contactsPagination,
      payload,
      status: 'PENDING',
    }),

  CONTACTS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.contactsPagination,
      payload,
      status: 'ERROR',
    }),

  CONTACTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.contactsPagination,
      ...action,
      status: 'DONE',
    });
  },

  PORTFOLIO_CONTACTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.portfolioContactsPagination.all = merge(
      get(state, `portfolioContactsPagination.all`),
      generatePagination(state.portfolioContactsPagination, 'all', action),
    );
  },

  PORTFOLIO_PARTNERS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.portfolioPartnersPagination.all = merge(
      get(state, `portfolioPartnersPagination.all`),
      generatePagination(state.portfolioPartnersPagination, 'all', action),
    );
  },

  LOGOUT: () => initialState,
});

export default users;
