import { useSelector } from 'react-redux';
import { get, upperFirst } from 'lodash';
import * as is from '../utils/roles';
import { selectCurrentRoles, selectCurrentTopRole } from '../selectors/agencies';

const useRoles = () => {
   const roles = useSelector(selectCurrentRoles);
   const topRole = useSelector(selectCurrentTopRole);

   const isAllowedTo = rawPrivilege => {
      const privilege = rawPrivilege.replace('allowedTo', '');
      const formattedPrivilege = `allowedTo` + upperFirst(privilege);
      const allowedRoles = get(is, formattedPrivilege, []);
      return allowedRoles?.some(r => roles?.includes(r));
   };

   return {
      topRole,
      roles,
      isAllowedTo,
   };
};

export default useRoles;
