import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ButtonReset } from 'ui';
import {
  SidebarWrapper,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuLinkWrapper,
  SidebarAgencySwitcherWrapper,
  SidebarInner,
  SidebarMenuSubnav,
  SidebarMenuSubnavItem,
  SidebarMenuSubnavLink,
} from 'ui/sidebar';
import IcAdmin from 'components/icons/Admin';
import IcBriefcase from 'components/icons/Briefcase';
import IcChat from 'components/icons/Chat';
import IcForum from 'components/icons/Forum';
import IcReport from 'components/icons/Report';
import IcSupport from 'components/icons/Support';
import IcPeople from 'components/icons/People';
import IcInvoice from 'components/icons/Invoice';
import IcBuilding from 'components/icons/Building';
import AgencySwitcher from 'components/AgencySwitcher';
import { useAppState } from 'hooks';
import { motion } from 'framer-motion';
import UserPopover from 'components/UserPopover';
import { FF_ENABLE_BILLER, FF_ENABLE_BAP } from '../constants';
import { Route, Link } from 'react-router-dom';
import {
  selectHasAccessToAdminTab,
  selectIsAnyAgencyDirector,
} from 'selectors/agencies';
import { MdDomain, MdForum } from 'react-icons/md';
import { Scrollbars } from 'react-custom-scrollbars';

const Backdrop = styled(ButtonReset)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: none;
`;

const Shortcut = styled(ButtonReset)`
  background-color: ${({ theme }) => theme.separatorUi};
  height: ${({ theme }) => theme.spacing(2)};
  width: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 999em;
  text-decoration: none;

  + * {
    margin-left: ${({ theme }) => theme.spacing()};
  }
`;

const sidebarVariants = {
  open: {
    transform: 'translateX(280px)',
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
  closed: {
    transform: 'translateX(0px)',
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
};

const backdropVariants = {
  open: {
    display: 'block',
    opacity: 1,
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
  closed: {
    display: 'none',
    opacity: 0,
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
};

const MainSidebar = () => {
  const { mobileNavIsShown, isMobile, closeMobileNav } = useAppState();
  const selectedPlaceId = useSelector(state => state.ui.selectedPlaceId);
  const hasAccessToAdminTab = useSelector(selectHasAccessToAdminTab);
  const isAnyAgencyDirector = useSelector(selectIsAnyAgencyDirector);

  return (
    <>
      <Backdrop
        as={isMobile ? motion.button : null}
        animate={mobileNavIsShown ? 'open' : 'closed'}
        variants={backdropVariants}
        onClick={closeMobileNav}
      />

      <SidebarWrapper
        as={isMobile ? motion.aside : null}
        animate={mobileNavIsShown ? 'open' : 'closed'}
        variants={sidebarVariants}
      >
        <SidebarAgencySwitcherWrapper>
          <UserPopover />
        </SidebarAgencySwitcherWrapper>

        {isAnyAgencyDirector && (
          <SidebarInner>
            <Shortcut as={Link} to="/batch/notifications">
              <MdDomain />
            </Shortcut>
            <Shortcut as={Link} to="/batch/conversations">
              <MdForum />
            </Shortcut>
          </SidebarInner>
        )}

        {!isMobile && (
          <SidebarInner>
            <AgencySwitcher />
          </SidebarInner>
        )}

        <Scrollbars autoHeight autoHeightMax="calc(100vh - 145px)">
          <SidebarMenu>
            {hasAccessToAdminTab && (
              <>
                <SidebarMenuItem>
                  <SidebarMenuLinkWrapper to="/administration">
                    <IcAdmin />
                    Administration
                  </SidebarMenuLinkWrapper>
                </SidebarMenuItem>

                <Route
                  path="/administration"
                  render={() => (
                    <SidebarMenuSubnav>
                      <SidebarMenuSubnavItem>
                        <SidebarMenuSubnavLink to="/administration/signalements">
                          Signalements
                        </SidebarMenuSubnavLink>
                      </SidebarMenuSubnavItem>

                      <SidebarMenuSubnavItem>
                        <SidebarMenuSubnavLink to="/administration/messages">
                          Messages
                        </SidebarMenuSubnavLink>
                      </SidebarMenuSubnavItem>
                    </SidebarMenuSubnav>
                  )}
                />
              </>
            )}

            {Boolean(selectedPlaceId) && (
              <SidebarMenuItem>
                <SidebarMenuLinkWrapper to="/tableau-de-bord">
                  <IcBriefcase />
                  Tableau de bord
                </SidebarMenuLinkWrapper>
              </SidebarMenuItem>
            )}

            <SidebarMenuItem>
              <SidebarMenuLinkWrapper to="/signalements">
                <IcReport />
                Signalements
              </SidebarMenuLinkWrapper>
            </SidebarMenuItem>

            <SidebarMenuItem>
              <SidebarMenuLinkWrapper to="/messages">
                <IcChat />
                Messages
              </SidebarMenuLinkWrapper>
            </SidebarMenuItem>

            <SidebarMenuItem>
              <SidebarMenuLinkWrapper to="/forum">
                <IcForum />
                Forum du CS
              </SidebarMenuLinkWrapper>
            </SidebarMenuItem>

            <SidebarMenuItem>
              <SidebarMenuLinkWrapper to="/alertes">
                <IcSupport />
                Alertes
              </SidebarMenuLinkWrapper>
            </SidebarMenuItem>

            <SidebarMenuItem>
              <SidebarMenuLinkWrapper to="/contacts">
                <IcPeople />
                Contacts
              </SidebarMenuLinkWrapper>
            </SidebarMenuItem>

            {!Boolean(selectedPlaceId) && (
              <SidebarMenuItem>
                <SidebarMenuLinkWrapper to="/lieux">
                  <IcBuilding />
                  Lieux
                </SidebarMenuLinkWrapper>
              </SidebarMenuItem>
            )}

            {FF_ENABLE_BILLER && (
              <SidebarMenuItem>
                <SidebarMenuLinkWrapper to="/facturier">
                  <IcInvoice />
                  Facturier
                </SidebarMenuLinkWrapper>
              </SidebarMenuItem>
            )}

            {FF_ENABLE_BAP && (
              <SidebarMenuItem>
                <SidebarMenuLinkWrapper to="/factures">
                  <IcInvoice />
                  BAP
                </SidebarMenuLinkWrapper>
              </SidebarMenuItem>
            )}

            <SidebarMenuItem>
              <SidebarMenuLinkWrapper to="/actualites">
                <IcPeople />
                Actualités en avant
              </SidebarMenuLinkWrapper>
            </SidebarMenuItem>
          </SidebarMenu>
        </Scrollbars>
      </SidebarWrapper>
    </>
  );
};

export default MainSidebar;
