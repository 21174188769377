import { createReducer } from 'redux-starter-kit';
import { get, merge, uniq } from 'lodash';
import {
  mergeGlobalStateAndActionPayload,
  addLocalPaginationToGlobalState,
} from 'utils/reducers';

const initialState = {
  contractById: {},
  contractsIdsByAgencyIdAndByUserId: {},
  contractsIdsByUserId: {},
  contractsIdsByContactId: {},
  contractDocumentsPagination: {},
  contractFoldersPagination: {},
  contractDocumentById: {},
  contractFolderById: {},
  contractAccountEntriesPagination: {},
};

const extractContracts = (state, { payload }) => {
  const contracts = get(payload, 'data.result', [])
    .map(id => get(payload, `data.entities.contracts[${id}]`))
    .filter(Boolean);

  state.contractById = merge(
    state.contractById,
    get(payload, `data.entities.contracts`),
  );

  contracts.forEach(({ id, userIds }) => {
    userIds.forEach(userId => {
      if (!state.contractsIdsByAgencyIdAndByUserId[payload.agencyId]) {
        state.contractsIdsByAgencyIdAndByUserId[payload.agencyId] = {};
      }

      state.contractsIdsByAgencyIdAndByUserId[payload.agencyId][userId] = uniq(
        (
          state.contractsIdsByAgencyIdAndByUserId[payload.agencyId][userId] ||
          []
        ).concat(id),
      );
    });
  });
};

const contracts = createReducer(initialState, {
  REPORT_CONTRACTS_SUCCESS: extractContracts,
  CONVERSATION_CONTRACTS_SUCCESS: extractContracts,

  UPDATE_CONTRACT_FOLDER_REQUEST: (state, { payload }) => {
    state.contractFolderById[payload.contractFolderId].displayName =
      payload.payload.contractFolder.displayName;
  },

  CONTRACT_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_CONTRACT_FOLDER_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_CONTRACT_DOCUMENT_SUCCESS: mergeGlobalStateAndActionPayload,

  CONTRACT_FOLDERS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.contractFoldersPagination,
      payload,
      status: 'PENDING',
    }),

  CONTRACT_FOLDERS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.contractFoldersPagination,
      payload,
      status: 'ERROR',
    }),

  CONTRACT_FOLDERS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.contractFoldersPagination,
      ...action,
      status: 'DONE',
    });
  },

  CONTRACT_FOLDER_DOCUMENTS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.contractDocumentsPagination,
      payload,
      status: 'PENDING',
    }),

  CONTRACT_FOLDER_DOCUMENTS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.contractDocumentsPagination,
      payload,
      status: 'ERROR',
    }),

  CONTRACT_FOLDER_DOCUMENTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.contractDocumentsPagination,
      ...action,
      status: 'DONE',
    });
  },

  CONTACT_CONTRACTS_SUCCESS: (state, { payload }) => {
    state.contractById = merge(
      state.contractById,
      get(payload, 'data.entities.contracts'),
    );
    state.contractsIdsByContactId[payload.contactId] = get(
      payload,
      'data.result',
    );
  },

  UPDATE_CONTRACT_PREFERENCES_SUCCESS: (state, { payload }) => {
    state.contractById = merge(
      state.contractById,
      get(payload, 'data.entities.contracts'),
    );

    state.parcelById = merge(
      state.parcelById,
      get(payload, 'data.entities.parcels'),
    );
  },

  CONTRACT_ACCOUNT_ENTRIES_SUCCESS: (state, action) => {
    addLocalPaginationToGlobalState({
      state: state.contractAccountEntriesPagination,
      ...action,
      status: 'DONE',
    });

    state.accountEntryById = merge(
        state.accountEntryById,
        get(action.payload, 'data.entities.accountEntries'),
    );
  },

  LOGOUT: () => initialState,
});

export default contracts;
