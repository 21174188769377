import { getMe, getMePrivacySettings, getUserIdentities } from './user';
import { createAction } from 'redux-actions';
import { getReportsCategories } from './reports';
import { getAgencies } from './agencies';

const requestInitialData = createAction('INITIAL_DATA_REQUEST');
const receiveInitialData = createAction('INITIAL_DATA_SUCCESS');
const failInitialData = createAction('INITIAL_DATA_ERROR');

export const fetchInitialData = () => dispatch => {
  dispatch(requestInitialData());

  return Promise.all([
    dispatch(getMe()),
    dispatch(getAgencies()),
    dispatch(getUserIdentities()),
    dispatch(getMePrivacySettings()),
    dispatch(getReportsCategories()),
  ]).then(
    () => dispatch(receiveInitialData()),
    error => dispatch(failInitialData(error)),
  );
};
