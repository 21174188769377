export const isEmail = value => value && !isValidEmail(value) && 'L’adresse mail n’est pas valide';

export const isValidEmail = value => value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const validatePassword = password => {
   if (!password) return 'Ce champ est requis';
   if (!isLongerThanTwelve(password)) return 'Doit contenir au moins 12 caractères';
   if (!hasOneUpperLetter(password)) return 'Doit contenir au moins 1 majuscule';
   if (!hasOneLowerLetter(password)) return 'Doit contenir au moins 1 minuscule';
   if (!hasOneDigit(password)) return 'Doit contenir au moins 1 chiffre';
   if (!hasOneSpecialCharacter(password)) return 'Doit contenir au moins 1 caractère spécial';
   return null;
};

export const isRequired = value => !Boolean(value) && 'Ce champ est requis';
export const isLongerThanTwelve = value => value?.length >= 12;
export const hasOneDigit = input => input?.match(/\d+/);
export const hasOneUpperLetter = input => input?.match(/[A-Z]+/);
export const hasOneLowerLetter = input => input?.match(/[a-z]+/);
export const hasOneSpecialCharacter = input =>
   input?.match(/(?=[^!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]*[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~])/);
