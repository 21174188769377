import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ui } from './';
import tinycolor from 'tinycolor2';

export const Table = styled.div`
  width: 100%;
  display: table;
  font-size: ${({ theme }) => theme.size(-1)};
  border-radius: 4px;

  ${props =>
    props.variant === 'bordered' &&
    css`
      background-color: #fff;
      border: 1px solid ${({ theme }) => theme.separator};
    `};

  ${props =>
    props.hasFooterActions &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-width: 0;
    `};
`;

export const Cell = styled.div`
  display: table-cell;
  white-space: nowrap;

  ${props => props.full && `width: 100%;`};
`;

export const Thead = styled.div`
  display: table-header-group;
`;

export const Tbody = styled.div`
  display: table-row-group;
`;

export const Th = styled.div`
  display: table-cell;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  text-align: left;
  font-size: ${({ theme }) => theme.size(-1)};
  vertical-align: middle;

  button {
    font-weight: inherit;
  }

  span {
    display: inline-block;
  }

  + div {
    padding-left: 0;
  }

  ${props =>
    props.hasNoHorizontalPadding &&
    css`
      padding-left: 0;
    `};
`;

const trStyles = `
  display: table-row;
  position: relative;

  svg {
    font-size: ${({ theme }) => theme.size(2)};
  }
`;

const LinkTr = styled(Link)`
  ${trStyles};
  text-decoration: none;
  color: inherit;

  &.bold {
    font-weight: bold;
  }

  &:hover {
    background-color: ${({ theme }) =>
      tinycolor(theme.primary)
        .setAlpha(0.08)
        .toString()};
  }

  ${({ isTransparent, theme }) =>
    Boolean(isTransparent) &&
    `
    background-color: ${theme.backgroundLight};
  `}
`;

const DefaultTr = styled.div`
  ${trStyles};

  &.active:hover {
    background-color: ${({ theme }) =>
      tinycolor(theme.primary)
        .setAlpha(0.08)
        .toString()};
    cursor: pointer;
  }

  a.bold {
    font-weight: bold;
  }
`;

export const Tr = props =>
  Boolean(props.to || props.href) ? (
    <LinkTr {...props} />
  ) : (
    <DefaultTr {...props} />
  );

export const Td = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  border-top: 1px solid ${ui('separator')};

  + div {
    padding-left: 0;
  }

  ${props =>
    props.hasNoHorizontalPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};

  ${props =>
    props.hasNoLeftPadding &&
    css`
      padding-left: 0;
    `};
`;

const IconCellWrapper = styled.span`
  display: flex;
  align-items: center;

  ${props =>
    props.isDisabled &&
    css`
      color: ${({ theme }) => theme.textLighter};
    `};
`;

const IconCellContent = styled.span`
  flex: 1;
`;

const IconCellLabel = styled.span`
  display: block;
  font-weight: 500;
  font-size: ${({ theme }) => theme.size(0)};
`;

const IconCellSubLabel = styled.span`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  color: ${({ theme }) => theme.textLight};
  font-size: ${({ theme }) => theme.size(-1)};
`;

const IconCellIcon = styled.span`
  color: ${({ theme }) => theme.textLighter};
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  font-size: ${({ theme }) => theme.size(1)};
  display: flex;
`;

const IconLinkWrapper = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.text};
`;

export const IconCell = ({
  label,
  icon,
  subLabel,
  isDisabled,
  canRedirect,
}) => (
  <IconCellWrapper
    as={Boolean(canRedirect) ? IconLinkWrapper : null}
    to={canRedirect}
    isDisabled={isDisabled}
  >
    {Boolean(icon) && <IconCellIcon>{icon}</IconCellIcon>}
    <IconCellContent>
      <IconCellLabel>{label}</IconCellLabel>
      {Boolean(subLabel) && <IconCellSubLabel>{subLabel}</IconCellSubLabel>}
    </IconCellContent>
  </IconCellWrapper>
);
