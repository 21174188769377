import styled, { css } from 'styled-components';
import { size } from 'ui';

const Tip = styled.span`
  opacity: 0;
  display: none;
  position: absolute;
  top: 100%;
  top: calc(100% + ${({ theme }) => theme.spacing(0.5)});
  left: 50%;
  transform: translate3d(-50%, -4px, 0);
  background-color: #111;
  color: #fff;
  font-size: ${size(-1)};
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: 4px;
  transition: transform 75ms ease;
  white-space: nowrap;
  z-index: 1;
  line-height: 1.5;

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border: 4px solid transparent;
    z-index: 22;
    border-bottom-color: #111;
  }

  :disabled & {
    display: none;
  }

  ${props =>
    props.left &&
    css`
      right: -4px;
      left: auto;
      transform: none;

      &:before {
        left: auto;
        right: 4px;
        transform: none;
      }
    `};

  ${props =>
    props.right &&
    css`
      left: -4px;
      right: auto;
      transform: none;

      &:before {
        right: auto;
        left: 4px;
        transform: none;
      }
    `};
`;

const Wrapper = styled.span`
  position: relative;

  &:hover ${Tip} {
    opacity: 1;
    display: block;
  }
`;

const Content = styled.span``;

const Tooltip = ({ children, tip, name, left, right, wrapperStyle, style }) => (
  <Wrapper style={wrapperStyle}>
    <Content aria-labelledby={name}>{children}</Content>
    <Tip id={name} left={left} right={right} style={style}>
      {tip}
    </Tip>
  </Wrapper>
);

export default Tooltip;
