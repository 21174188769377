import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import { Box, Buttons, Button as RawButton, color, size } from 'ui';
import { BoxDescription } from 'ui/auth';
import { get } from 'lodash';

const Button = styled(RawButton)`
  border-radius: 4px;
  border: 0;
  color: ${color('blue', 'vibrant')};
  background-color: ${color('grey', 'lightish')};
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 150ms ease, opacity 150ms ease, background-color 150ms ease;
  text-decoration: none;
  display: block;
  font-size: ${size(1)};
  padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing(2)};
  text-align: center;
  background-color: ${color('blue', 'vibrant')};
  color: #fff;

  &:hover {
    background-color: #0086bf;
  }
`.withComponent(Link);

const CheckYourEmails = ({ location }) => {
  const email = get(location, 'state.email');
  const isReferred = Boolean(email);

  if (!isReferred) {
    return <Redirect to="/" />;
  }

  return (
    <AuthLayout>
      <Box padded>
        <BoxDescription>
          Un email a été envoyé à l’adresse <strong>{email}</strong>.
        </BoxDescription>

        <BoxDescription>
          Merci de suivre les instructions qui y figurent.
        </BoxDescription>

        <Buttons>
          <Button className="primary cta" to="/connexion">
            Retour à l’écran de connexion
          </Button>
        </Buttons>
      </Box>
    </AuthLayout>
  );
};

export default CheckYourEmails;
