import { get } from 'lodash';

const initialState = {
   summary: {
      allocatedAmount: 0,
      error: '',
      isLoading: true,
      progress: 0,
      spentAmount: 0,
      unreadApprovedEntriesCount: 0,
      unreadEntriesCount: 0,
      unreadRefusedEntriesCount: 0,
      unreadWaitingEntriesCount: 0,
      expensesManager: '',
   },
   canManageExpenses: false,
};

const expenses = (state = initialState, action) => {
   const { payload, type } = action;
   switch (type) {
      case 'EXPENSES_SUMMARY_REQUEST':
         return {
            ...state,
            summary: {
               ...state.summary,
               isLoading: !payload.keepPreviousData,
            },
         };
      case 'EXPENSES_SUMMARY_SUCCESS':
         const { data } = payload;
         return {
            ...state,
            summary: {
               allocatedAmount: get(data, 'allocatedAmount'),
               error: '',
               isLoading: false,
               progress: get(data, 'progress'),
               spentAmount: get(data, 'spentAmount'),
               unreadApprovedEntriesCount: get(data, 'unreadApprovedEntriesCount'),
               unreadEntriesCount: get(data, 'unreadEntriesCount'),
               unreadRefusedEntriesCount: get(data, 'unreadRefusedEntriesCount'),
               unreadWaitingEntriesCount: get(data, 'unreadWaitingEntriesCount'),
               expensesManager: get(data, 'expensesManager'),
            },
            canManageExpenses: get(data, 'canManageExpenses'),
         };
      case 'EXPENSES_SUMMARY_ERROR':
         return {
            ...state,
            summary: {
               ...state.summary,
               error: payload?.data?.error?.message,
               isLoading: false,
            },
         };
      default:
         return state;
   }
};

export default expenses;
