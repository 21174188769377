import { useContext } from 'react';
import { AppContext } from 'containers/AppProvider';

export const useAppState = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error(`useAppState must be used within an AppProvider`);
  }

  return context;
};

export { default as usePagination } from './pagination';
