import { history } from 'store';

/**
 * Upon switching agency, if user is not on a main route (reports or messages),
 * he is brought to reports main view.
 */
export const agencySwitchMiddleware = store => next => action => {
  next(action);

  if (action.type === 'SELECT_AGENCY') {
    const { pathname } = history.location;

    if (
      pathname !== '/messages' &&
      pathname !== '/signalements' &&
      pathname !== '/contacts'
    ) {
      history.push(`/${history.location.pathname.split('/')[1]}`);
    }
  }
};
