import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { camelizeKeys } from 'humps';
import withSnacks from 'components/withSnacks';
import { history } from 'store';
import { getParams } from 'utils';
import { Box } from 'ui';
import { AcceptInvitationForm, ResetPasswordForm } from 'components/AuthForms';
import AuthLayout from 'components/AuthLayout';
import { selectAgency, selectPlace } from 'actions/ui';
import { acceptInvite, resetPassword } from '../api/user';

class Callback extends Component {
   componentDidMount() {
      const { location, match } = this.props;
      const params = camelizeKeys(getParams(location) || {});

      const { path } = match;

      this.handleCallback(path, params);
   }

   handleCallback = (path, params) => {
      const { type } = this.props.match.params;

      switch (type) {
         case 'accept_invitation':
            this.handleInvitationCallback(params);
            return;
         case 'reset_password':
            this.handleResetPasswordCallback(params);
            return;
         case 'connection':
            this.handleConnectionCallback(params);
            return;
         case 'report':
            this.handleClearSelectedEntities();
            history.replace(`/signalements/${params.reportId}`);
            return;
         case 'reports':
            this.handleClearSelectedEntities();
            history.replace(`/signalements/${params.id}`);
            return;
         case 'conversations':
            this.handleClearSelectedEntities();
            history.replace(`/messages/${params.id}`);
            return;
         case 'topics':
            this.handleClearSelectedEntities();
            history.replace(`/forum/${params.id}`);
            return;
         case 'notifications':
            this.handleClearSelectedEntities();
            history.replace(`/lieux/${params.placeId}/notifications`);
            return;
         case 'biller':
            history.replace(`/facturier/${params.billerId}/${params.billerUuid}`);
            return;
         default:
            return;
      }
   };

   handleInvitationCallback = ({ email, invitationToken }) => {
      this.props.initialize({ email, invitationToken });
   };

   handleResetPasswordCallback = ({ email, resetToken }) => {
      this.props.initialize({ email, resetToken });
   };

   handleConnectionCallback = ({ email }) => {
      this.props.sendSnack({
         type: 'success',
         duration: 3000,
         message: 'Vous pouvez vous connecter avec l’adresse enregistrée',
         action: 'OK',
      });

      history.replace(`/connexion?email=${email}`);
   };

   handleClearSelectedEntities = () => {
      const { setSelectedAgency, setSelectedPlace } = this.props;
      setSelectedPlace();
      setSelectedAgency();
   };

   handleSubmitResetPasswordForm = async e => {
      e.preventDefault();
      await this.handleSubmit(resetPassword);
   };

   handleSubmitAcceptInvitationForm = async e => {
      e.preventDefault();
      await this.handleSubmit(acceptInvite);
   };

   handleSubmit = async method => {
      const { values } = this.props.form;
      try {
         await method(values);
         this.handleNewPasswordSuccess();
      } catch (e) {
         this.props.sendSnack({
            type: 'error',
            duration: 3000,
            message: 'Une erreur est survenue. Merci de réessayer ultérieurement.',
         });
      }
   };

   handleNewPasswordSuccess = () => {
      this.props.sendSnack({
         type: 'success',
         duration: 3000,
         message: 'Votre mot de passe a été modifié avec succès. Vous pouvez vous connecter avec celui-ci.',
         action: 'OK',
      });

      history.replace({
         pathname: '/',
         state: { newPassword: true },
      });
   };

   render() {
      const {
         params: { type },
      } = this.props.match;

      return (
         <AuthLayout>
            <Box padded>
               {type === 'reset_password' && (
                  <ResetPasswordForm disabled={false} onSubmit={this.handleSubmitResetPasswordForm} />
               )}
               {type === 'accept_invitation' && (
                  <AcceptInvitationForm disabled={false} onSubmit={this.handleSubmitAcceptInvitationForm} />
               )}
            </Box>
         </AuthLayout>
      );
   }
}

const mapStateToProps = state => ({
   form: state.form.auth,
});

const mapDispatchToProps = dispatch => ({
   setSelectedPlace: payload => dispatch(selectPlace(payload)),
   setSelectedAgency: payload => dispatch(selectAgency(payload)),
});

export default reduxForm({
   form: 'auth',
})(connect(mapStateToProps, mapDispatchToProps)(withSnacks(Callback)));
