import { useState, useCallback } from 'react';
import { pickBy, keys, includes, isEqual, omit } from 'lodash';
import queryString from 'query-string';
import { useLocation, useDeepCompareEffect } from 'react-use';
import { getApiQueryFromPaginationQuery } from 'utils/routes';
import { useSelector, useDispatch } from 'react-redux';
import { selectAppState } from 'selectors';

const usePagination = ({
  actionFn,
  selectorFn,
  defaultParams,
  extraArguments,
  defaultSort,
  ...options
}) => {
  const [sliceId, setSliceId] = useState(options?.sliceId);
  const dispatch = useDispatch();
  const location = useLocation();
  const [params, setParams] = useState(defaultParams);
  const { selectedPlaceId, selectedAgencyId } = useSelector(selectAppState);
  const query = queryString.parse(location.search);
  const hasSelectedPortfolio = selectedAgencyId === 'portfolio';

  const shouldDismissDefaultParams =
    options.shouldDismissDefaultParamsIfAnyParamIsSet &&
    keys(query).filter(
      key => !includes(['page', 'sort', 'order', 'search'], key),
    ).length > 0;

  const aggregateQuery = getApiQueryFromPaginationQuery(
    queryString.stringify(
      pickBy(
        omit(
          {
            page: 1,
            sort: defaultSort,
            ...options.filter,
            ...(!isEqual(params, defaultParams) || !shouldDismissDefaultParams
              ? params
              : {}),
            ...query,
            place: options.shouldDisregardSelectedPlace
              ? null
              : selectedPlaceId,
            agency:
              !hasSelectedPortfolio &&
              !options.shouldDisregardAgencyAndPortfolio
                ? selectedAgencyId
                : null,
            inPortfolio:
              hasSelectedPortfolio &&
              !options.shouldDisregardAgencyAndPortfolio,
          },
          options.omittedSearchParams || [],
        ),
      ),
    ),
  );

  const { data, loadingState, pagination } = useSelector(state =>
    selectorFn(state, { selectedAgencyId, aggregateQuery, sliceId }),
  );

  const request = useCallback(
    () => {
      if (!actionFn) {
        return;
      }
      dispatch(
        actionFn({ params: aggregateQuery, sliceId, ...extraArguments }),
      );
    },
    // eslint-disable-next-line
    [dispatch, selectedAgencyId, aggregateQuery, sliceId, extraArguments],
  );

  useDeepCompareEffect(() => {
    request();
  }, [{ selectedAgencyId, aggregateQuery, sliceId, extraArguments }]);

  const setParam = param => setParams({ ...params, ...param });

  const skeletonLength = pagination
    ? pagination.page * pagination.perPage > pagination.total
      ? pagination.page * pagination.perPage - pagination.total
      : 5
    : 5;

  return {
    data,
    loadingState,
    pagination,
    setParam,
    setParams,
    setSliceId,
    skeletonLength,
    request,
  };
};

export default usePagination;
