import { createSelector } from 'reselect';
import { getAgencies, getContracts, getConversations, getCurrentUser, getPlaces, getQuery, getReports, getUi, getUsers } from './';
import queryString from 'query-string';
import { find, flatten, get, identity, orderBy, pickBy, values } from 'lodash';
import { getQueryWithoutPage } from 'utils/routes';

export const getAllAgencies = createSelector([getAgencies], ({ agenciesIds, agencyById }) => {
   return (agenciesIds || []).map(id => agencyById[id]);
});

export const getSelectedAgency = createSelector([getAgencies, getUi], ({ agencyById }, { selectedAgencyId }) => agencyById[selectedAgencyId]);

export const getSelectedPlace = createSelector([getPlaces, getUi], ({ placeById }, { selectedPlaceId }) => placeById[selectedPlaceId]);

export const selectAgencyPlaces = createSelector([getSelectedAgency, getUi, getPlaces], (agency, { selectedAgencyId }, { placeById, portfolioPlacesIds }) => {
   if (!selectedAgencyId) {
      return portfolioPlacesIds.map(id => placeById[id]).filter(Boolean);
   }

   return (agency.places || []).map(id => placeById[id]).filter(Boolean);
});

export const selectAgencyFiltersCounts = createSelector([getAgencies, getUi], ({ agencyById }, { selectedAgencyId }) => {
   const agency = agencyById[selectedAgencyId] || {};
   const unread = agency.conversationsUnreadCount;
   const total = agency.conversationsCount;

   return {
      unread,
      total,
   };
});

export const selectAgencyContacts = createSelector([getUi, getUsers, getQuery], ({ contactsSearch = '' }, { userById, portfolioContactsPagination }, search) => {
   if (search === '') {
      search = '?';
   }

   const parsedQuery = queryString.parse(search);

   const query = `?${queryString.stringify(
      pickBy(
         {
            ...(parsedQuery || {}),
            search: contactsSearch.length > 2 ? encodeURI(contactsSearch.toLowerCase()) : null,
            sort: 'lastName,firstName',
         },
         identity
      )
   )}`;

   const pagination = get(portfolioContactsPagination, `all['${query}'].idsByPage`) || {};

   const portfolioContactsIds = flatten(values(pagination));

   if (!portfolioContactsIds) {
      return [];
   }

   return portfolioContactsIds.map(id => userById[id]);
});

export const selectAgencyContactsPagination = createSelector([getUsers, getQuery, getUi], ({ portfolioContactsPagination }, search, { contactsSearch = '' }) => {
   const parsedQuery = queryString.parse(search);

   const query = `?${queryString.stringify(
      pickBy(
         {
            ...(parsedQuery || {}),
            search: contactsSearch.length > 2 ? encodeURI(contactsSearch.toLowerCase()) : null,
            sort: 'lastName,firstName',
         },
         identity
      )
   )}`;

   return get(portfolioContactsPagination, `all['${query}']`) || {};
});

export const selectAgencyPartners = createSelector([getUi, getUsers, getQuery], ({ partnersFilters = {} }, { userById, portfolioPartnersPagination }, search) => {
   if (search === '') {
      search = '?';
   }

   const parsedQuery = queryString.parse(search);

   const query = `?${queryString.stringify(
      pickBy({
         ...(parsedQuery || {}),
         search: Boolean(partnersFilters.search) ? encodeURI(partnersFilters.search.toLowerCase()) : null,
         // sort: 'lastName,firstName',
      })
   )}`;

   const pagination = get(portfolioPartnersPagination, `all['${query}'].idsByPage`) || {};

   const portfolioPartnersIds = flatten(values(pagination));

   if (!portfolioPartnersIds) {
      return [];
   }

   return portfolioPartnersIds.map(id => userById[id]);
});

export const selectAgencyPartnersPagination = createSelector([getUsers, getQuery, getUi], ({ portfolioPartnersPagination }, search, { partnersSearch = '' }) => {
   const parsedQuery = queryString.parse(search);

   const query = `?${queryString.stringify(
      pickBy(
         {
            ...(parsedQuery || {}),
            search: partnersSearch.length > 2 ? encodeURI(partnersSearch.toLowerCase()) : null,
            sort: 'lastName,firstName',
         },
         identity
      )
   )}`;

   return get(portfolioPartnersPagination, `all['${query}']`) || {};
});

export const selectAgencyConversationPagination = createSelector([getConversations, getUi, getQuery], ({ agenciesConversationsPagination }, { selectedAgencyId }, search) => {
   const parsedQuery = queryString.parse(search);

   const query = `?${queryString.stringify(
      pickBy(
         {
            sort: '-updatedAt',
            ...parsedQuery,
         },
         identity
      )
   )}`;

   return get(agenciesConversationsPagination, `${selectedAgencyId || 'portfolio'}[${getQueryWithoutPage(query)}]`);
});

export const selectAgencyReportPagination = createSelector([getReports, getUi, getQuery], ({ agenciesReportsPagination }, { selectedAgencyId }, query) => {
   query = `?${queryString.stringify({
      sort: '-updatedAt',
      ...queryString.parse(query),
   })}`;

   return get(agenciesReportsPagination, `${selectedAgencyId || 'portfolio'}[${getQueryWithoutPage(query)}]`);
});

export const selectAgencyConversations = createSelector(
   [getUi, getConversations, getUsers, getPlaces, getQuery],
   ({ selectedAgencyId }, { conversationById, conversationsIdsByAgencyId, agenciesConversationsPagination }, { userById }, { placeById }, search) => {
      if (search === '') {
         search = '?';
      }

      search = queryString.stringify({
         sort: '-updatedAt',
         ...queryString.parse(search),
      });

      if (!Boolean(selectedAgencyId)) {
         selectedAgencyId = 'portfolio';
      }

      const { page = 1, ...parsedQuery } = queryString.parse(search);
      const query = `?${queryString.stringify(parsedQuery)}`;

      const agencyConversationsPageIds = get(agenciesConversationsPagination, `${selectedAgencyId}['${query}'].idsByPage.${page}`);

      if (!selectedAgencyId || !agencyConversationsPageIds) {
         return [];
      }

      const conversations = agencyConversationsPageIds
         .filter(id => Boolean(conversationById[id]))
         .map(id => {
            const conversation = conversationById[id];

            return {
               ...conversation,
               members: conversation.members.map(userId => userById[userId] || {}),
               place: placeById[conversation.placeId],
            };
         });

      return conversations;
   }
);

export const selectAgencyReports = createSelector(
   [getUi, getReports, getUsers, getPlaces, getQuery, getContracts],
   ({ selectedAgencyId }, { reportById, categoryById, agenciesReportsPagination }, { userById }, { placeById }, search, { contractById, contractsIdsByAgencyIdAndByUserId }) => {
      if (search === '') {
         search = '?';
      }

      if (!selectedAgencyId) {
         selectedAgencyId = 'portfolio';
      }

      const { page = 1, ...parsedQuery } = queryString.parse(search);
      const query = `?${queryString.stringify({
         sort: '-updatedAt',
         ...parsedQuery,
      })}`;

      const agencyReportsPageIds = get(agenciesReportsPagination, `${selectedAgencyId}['${query}'].idsByPage.${page}`);

      if (!selectedAgencyId || !agencyReportsPageIds) {
         return [];
      }

      const contractsByUserId = contractsIdsByAgencyIdAndByUserId[selectedAgencyId] || {};

      return agencyReportsPageIds.map(id => {
         const report = reportById[id];

         if (!report) return {};

         return {
            ...report,
            author: {
               ...userById[report.author],
               contracts: (contractsByUserId[report.author] || []).map(contractId => contractById[contractId]),
            },
            category: categoryById[report.category],
            place: placeById[report.placeId],
         };
      });
   }
);

export const selectAllPortfolioPlaces = createSelector([getUi, getPlaces], ({ selectedAgencyId }, { allPlacesIdsByAgencyId, placeById }) =>
   orderBy(
      (Boolean(selectedAgencyId) ? get(allPlacesIdsByAgencyId, `['${selectedAgencyId}']`, []) : get(allPlacesIdsByAgencyId, `portfolio`, [])).map(id => placeById[id]),
      ({ displayName }) => (displayName || '').toLowerCase()
   )
);

export const selectAvailablePlaces = createSelector([getUi, selectAgencyPlaces, selectAllPortfolioPlaces], ({ selectedAgencyId }, agencyPlaces, portfolioPlaces) =>
   selectedAgencyId ? agencyPlaces : portfolioPlaces
);

export const selectHasAccessToAdminTab = createSelector(
   [getCurrentUser, getSelectedAgency, getUi],
   (currentUser, selectedAgency, { selectedAgencyId }) =>
      Boolean(selectedAgencyId) && (Boolean(get(currentUser, 'sergicPartnerAdmin')) || Boolean(get(selectedAgency, 'amIDirector')))
);

export const selectIsLoadingAgency = createSelector(
   [getUi, getSelectedAgency],
   ({ selectedAgencyId }, selectedAgency) => Boolean(selectedAgencyId) && !Boolean(get(selectedAgency, 'displayName'))
);

export const selectIsAnyAgencyDirector = createSelector([getAllAgencies], agencies => Boolean(find(agencies, ({ amIDirector }) => amIDirector)));

export const selectCurrentTopRole = createSelector([getSelectedPlace], place => get(place, 'topPlaceRole.kind'));
export const selectCurrentRoles = createSelector([getSelectedPlace], place => get(place, 'placeRoles')?.map(r => r.kind));
