import styled, { css } from 'styled-components';
import { ui, size, media } from 'ui';
import { getAttachmentUrl } from 'utils';
import { pickBy } from 'lodash';
import defaultPlace from 'images/default-place.png';

const AvatarWrapper = styled.span`
  display: inline-block;
  width: ${({ theme }) => theme.spacing(4)};
  height: ${({ theme }) => theme.spacing(4)};
  line-height: ${({ theme }) => theme.spacing(4)};
  text-align: center;
  border-radius: 100%;
  background-color: ${props => (props.isImage ? 'transparent' : ui('primary'))};
  color: #fff;
  font-size: ${size(2)};
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  ${props =>
    props.variant === 'sidebar' &&
    css`
      background-color: ${({ theme }) => theme.primary};
      color: #fff;

      ${media.desktop`
        background-color: #fff;
        color: ${({ theme }) => theme.primary};
      `};
    `};

  ${props =>
    props.block &&
    css`
      display: block;
    `};

  ${props =>
    props.size === 'small' &&
    css`
      height: ${({ theme }) => theme.spacing(2)};
      width: ${({ theme }) => theme.spacing(2)};
      line-height: ${({ theme }) => theme.spacing(2)};
      font-size: ${size(-1)};
    `};

  ${props =>
    props.size === 'smallish' &&
    css`
      height: ${({ theme }) => theme.spacing(2.625)};
      width: ${({ theme }) => theme.spacing(2.625)};
      line-height: ${({ theme }) => theme.spacing(2.625)};
      font-size: ${size()};
    `};

  ${props =>
    props.size === 'medium' &&
    css`
      height: ${({ theme }) => theme.spacing(3)};
      width: ${({ theme }) => theme.spacing(3)};
      line-height: ${({ theme }) => theme.spacing(3)};
      font-size: ${size()};
    `};

  ${props =>
    props.size === 'mediumLarge' &&
    css`
      height: ${({ theme }) => theme.spacing(4)};
      width: ${({ theme }) => theme.spacing(4)};
      line-height: ${({ theme }) => theme.spacing(4)};
      font-size: ${({ theme }) => theme.spacing(2)};
    `};

  ${props =>
    props.size === 'big' &&
    css`
      height: ${({ theme }) => theme.spacing(6)};
      width: ${({ theme }) => theme.spacing(6)};
      line-height: ${({ theme }) => theme.spacing(6)};
      font-size: ${size(5)};
    `};

  ${props =>
    props.size === 'huge' &&
    css`
      height: ${({ theme }) => theme.spacing(10)};
      width: ${({ theme }) => theme.spacing(10)};
      line-height: ${({ theme }) => theme.spacing(10)};
      font-size: ${size(8)};
    `};

  ${props =>
    props.white &&
    css`
      background-color: #fff;
      color: #0080c8;
    `};

  ${props =>
    props.hasShaddow &&
    css`
      box-shadow: 0 8px 24px rgba(89, 149, 179, 0.15);
    `};

  ${props =>
    props.empty &&
    css`
      background-color: #ecf0f4;
    `};

  ${props =>
    props.variant === 'place' &&
    css`
      border-radius: 4px;
    `};
`;

const Avatar = ({
  firstName = '',
  lastName = '',
  displayName,
  style,
  avatarUrl,
  hasShaddow,
  empty,
  block,
  size,
  variant,
}) => (
  <AvatarWrapper
    style={pickBy({
      ...style,
      backgroundImage: Boolean(avatarUrl)
        ? `url(${getAttachmentUrl(avatarUrl)})`
        : variant === 'place'
        ? `url(${defaultPlace})`
        : null,
    })}
    size={size}
    hasShaddow={hasShaddow}
    empty={empty}
    block={block}
    variant={variant}
    isImage={Boolean(avatarUrl)}
  >
    {!Boolean(avatarUrl) &&
      (displayName
        ? `${displayName
            .split(' ')
            .map(w => w.charAt(0))
            .slice(0, 2)
            .join('') || ''}`
        : `${(firstName || '').charAt(0)}${(lastName || '').charAt(0)}`)}
  </AvatarWrapper>
);

Avatar.defaultProps = {
  displayName: '',
  firstName: '',
  lastName: '',
};

export default Avatar;
