import { createReducer } from 'redux-starter-kit';
import { mergeGlobalStateAndActionPayload } from 'utils/reducers';

const initialState = {
  agencyById: {},
  agenciesIds: [],
};

const agencies = createReducer(initialState, {
  AGENCIES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.agenciesIds = payload.result;
  },

  AGENCY_SUCCESS: mergeGlobalStateAndActionPayload,

  LOGOUT: () => initialState,
});

export default agencies;
