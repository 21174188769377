import { createContext, useState, useEffect } from 'react';
import { viewportSizes } from 'ui';
import { useWindowSize } from 'react-use';
import { withRouter } from 'react-router';

export const AppContext = createContext();

const AppProvider = ({ children, location }) => {
  const [mobileNavIsShown, setMobileNavIsShown] = useState(false);
  const [mobilePlaceIsShown, setMobilePlaceIsShown] = useState(false);
  const { width } = useWindowSize();

  const isMobile = width < viewportSizes.desktop;
  const isMobileStrict = width < viewportSizes.tabletMini;

  const toggleNavIsShown = () => {
    closeMobilePlace();
    setMobileNavIsShown(!mobileNavIsShown);
  };

  const togglePlaceIsShown = () => {
    closeMobileNav();
    setMobilePlaceIsShown(!mobilePlaceIsShown);
  };

  const closeMobileNav = () => setMobileNavIsShown(false);

  const closeMobilePlace = () => setMobilePlaceIsShown(false);

  useEffect(() => {
    closeMobileNav();
  }, [location.key]);

  useEffect(() => {
    closeMobileNav();
  }, [mobilePlaceIsShown]);

  return (
    <AppContext.Provider
      value={{
        mobileNavIsShown,
        toggleNavIsShown,
        isMobile,
        isMobileStrict,
        closeMobileNav,
        togglePlaceIsShown,
        closeMobilePlace,
        mobilePlaceIsShown,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default withRouter(AppProvider);
