import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { API_BASE, MOCK_API_BASE } from '../constants';

const client = new ApolloClient({
  uri: operation => operation.variables.mock ? `${MOCK_API_BASE}/graphql/${operation.operationName}` : `${API_BASE}/graphql`,
  request: operation => {
    const state = JSON.parse(localStorage.getItem('state'));

    if (Boolean(state?.user?.accessToken)) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${state?.user?.accessToken}`,
        },
      });
    }
  },
});

const GraphQLProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default GraphQLProvider;
