import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { color, ui } from './';

export const Input = styled.input`
  height: 40px;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${ui('separator')};
  flex: 1;

  &:focus,
  &:active {
    outline: none;
    border-color: ${color('grey', 'dark')};
  }
`;

export const InputSearch = styled(Input)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)};
  border-radius: 4px;

  ${({ variant }) =>
    variant === 'withSuffix' &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}

  ${({ variant }) =>
    variant === 'none' &&
    css`
      padding-left: ${({ theme }) => theme.spacing()};
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${({ theme }) => theme.error};
    `}
`;

export const ErrorMessage = styled.span`
  display: block;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  margin: 0;
  color: ${({ theme }) => theme.error};
`;

export const FormErrorMessage = ({ name }) => {
  const { errors, submitCount } = useFormikContext();
  const error = get(errors, name);

  if (!error || submitCount < 1) {
    return null;
  }

  return <ErrorMessage>{error}</ErrorMessage>;
};
