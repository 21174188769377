import { createReducer } from 'redux-starter-kit';

const initialState = {
  accountPlaceById: {},
  accountPlacesIds: [],
  codeIdsByAccountPlaceId: {},
};

const accountPlaces = createReducer(initialState, {
  ACCOUNT_PLACES_SUCCESS: (state, { payload }) => {
    state.accountPlaceById = payload.entities.accountPlaces;
    state.accountPlacesIds = payload.result;
  },

  ACCOUNT_PLACE_CODES_SUCCESS: (state, { payload }) => {
    state.codeIdsByAccountPlaceId[payload.accountPlaceId] = payload.data.result;
  },

  UPDATE_ACCOUNT_PLACE_SUCCESS: (state, { payload }) => {
    state.accountPlaceById[payload.id] = payload;
    state.codeIdsByAccountPlaceId[payload.id] = payload.accountCodes.map(
      code => code.id,
    );
  },

  ALL_CODES_SUCCESS: (state, { payload }) => {
    state.allCodes = payload.entities.accountCodes;
  },

  LOGOUT: () => initialState,
  NOT_AUTHORIZED: () => initialState,
});

export default accountPlaces;
