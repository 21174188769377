import Icon from 'react-icon-base';

const IcInvoice = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M3.2 6.5a.367.367 0 00-.733 0v8.067c0 .606.493 1.1 1.1 1.1H7.6V17.5c0 .606.494 1.1 1.1 1.1h6.6c.596 0 1.1-.504 1.1-1.1v-1.833h4.033c.607 0 1.1-.494 1.1-1.1V6.5a.367.367 0 10-.733 0v8.067a.367.367 0 01-.367.366h-4.4a.367.367 0 00-.366.367v2.2a.376.376 0 01-.367.367H8.7a.367.367 0 01-.367-.367v-2.2a.367.367 0 00-.366-.367h-4.4a.367.367 0 01-.367-.366V6.5zm1.1 7.7a.367.367 0 00.367-.367V4.667h13.566a.367.367 0 100-.734H4.667V3.2h13.566a.367.367 0 100-.733H4.667V2.1c0-.188.178-.367.366-.367h13.934c.195 0 .366.172.366.367v11.733a.367.367 0 10.734 0V2.1c0-.596-.504-1.1-1.1-1.1H5.033c-.596 0-1.1.504-1.1 1.1v11.733c0 .203.165.367.367.367zM21.167 3.933a.367.367 0 100 .734c.606 0 1.1.493 1.1 1.1v15.4c0 .606-.494 1.1-1.1 1.1H2.833c-.606 0-1.1-.494-1.1-1.1v-15.4c0-.607.494-1.1 1.1-1.1a.367.367 0 000-.734C1.823 3.933 1 4.756 1 5.767v15.4C1 22.177 1.822 23 2.833 23h18.334c1.01 0 1.833-.822 1.833-1.833v-15.4c0-1.011-.822-1.834-1.833-1.834zM15.3 14.2h1.467a.367.367 0 100-.733H15.3a.367.367 0 100 .733zm1.1-2.567a.367.367 0 00-.367-.366H13.1a.367.367 0 100 .733h2.933a.367.367 0 00.367-.367zM7.233 9.067h4.4a.367.367 0 000-.734h-4.4a.367.367 0 000 .734zM12 11.633a.367.367 0 00-.367-.366h-4.4a.367.367 0 000 .733h4.4a.367.367 0 00.367-.367zm.367 4.767h2.2a.367.367 0 100-.733h-2.2a.367.367 0 000 .733zm-2.934 0H10.9a.367.367 0 000-.733H9.433a.367.367 0 000 .733zm2.2-2.2h2.2a.367.367 0 100-.733h-2.2a.367.367 0 000 .733zm-1.466 0H7.233a.367.367 0 010-.733h2.934a.367.367 0 010 .733z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Icon>
);

export default IcInvoice;
