import { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Redirect, Route } from 'react-router-dom';
import { Box } from 'ui';
import { BoxSuffix, LinkButton } from 'ui/auth';
import { refreshToken, signup } from 'actions/user';
import { history } from 'store';
import { getParams } from 'utils';
import { LostPasswordForm, SignupForm } from 'components/AuthForms';
import AuthLayout from 'components/AuthLayout';
import { sendForgottenPasswordEmail } from '../api/user';
import withSnacks from '../components/withSnacks';

class Auth extends Component {
   componentDidMount() {
      this.initialize();
   }

   initialize = () => {
      const { location, initialize } = this.props;
      const params = getParams(location);

      if (Boolean(params.email)) {
         initialize({ username: params.email, email: params.email });
      } else {
         setTimeout(() => {
            this.props.reset();
         }, 0);
      }
   };

   handleSubmitSignupForm = e => {
      e.preventDefault();

      const { username } = this.props.form.values;
      this.props.signup(username).then(
         () =>
            history.push({
               pathname: `/email-envoye`,
               state: { email: username },
            }),
         err => console.error(err)
      );
   };

   handleSubmitResetForm = async (e, recaptchaToken) => {
      e.preventDefault();
      try {
         const { username } = this.props.form.values;
         await sendForgottenPasswordEmail(username, recaptchaToken);
         history.push({
            pathname: `/email-envoye`,
            state: { email: username },
         });
      } catch (e) {
         this.props.sendSnack({
            type: 'error',
            duration: 3000,
            message: 'Une erreur est survenue. Merci de réessayer ultérieurement.',
         });
      }
   };

   render() {
      if (this.props?.user?.isAuthenticated) {
         return <Redirect to='/' />;
      }

      return (
         <AuthLayout>
            <Box padded>
               <Route path='/inscription' render={() => <SignupForm onSubmit={this.handleSubmitSignupForm} />} />

               <Route
                  path='/mot-de-passe-oublie'
                  render={() => <LostPasswordForm onSubmit={this.handleSubmitResetForm} />}
               />
            </Box>

            <Route
               path='/inscription'
               render={() => (
                  <BoxSuffix>
                     <p>Mon compte est déjà activé</p>
                     <LinkButton to='/connexion'>Connexion</LinkButton>
                  </BoxSuffix>
               )}
            />

            <Route
               path='/mot-de-passe-oublie'
               render={() => (
                  <BoxSuffix>
                     <LinkButton to='/connexion'>Retour</LinkButton>
                  </BoxSuffix>
               )}
            />
         </AuthLayout>
      );
   }
}

const mapStateToProps = state => ({
   form: state.form.auth,
   user: state.user,
});

const mapDispatchToProps = dispatch => ({
   refreshToken: payload => dispatch(refreshToken(payload)),
   signup: payload => dispatch(signup(payload)),
});

export default reduxForm({
   form: 'auth',
})(connect(mapStateToProps, mapDispatchToProps)(withSnacks(Auth)));
