import { createBrowserHistory } from 'history';
import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import { loadState } from './api/localStorage';
import { agencySwitchMiddleware } from './middlewares';
import createRootReducer from './reducers';

export const history = createBrowserHistory();
const persistedState = loadState();

const store = configureStore({
  reducer: createRootReducer(history),
  preloadedState: persistedState,
  middleware: [...getDefaultMiddleware(), agencySwitchMiddleware],
});

export default store;
