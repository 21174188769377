import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { List, ButtonReset, ui } from './';
import tinycolor from 'tinycolor2';

export const PopoverContainer = styled.div`
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  left: 0;
  top: 100%;
  top: calc(100% + ${({ theme }) => theme.spacing(0.25)});
  border-radius: 6px;
  background-color: #fff;
  width: 20em;
  max-width: 100vw;
  font-size: ${({ theme }) => theme.size(0)};

  ${props =>
    props.size === 'small' &&
    css`
      width: 10em;
    `};

  ${props =>
    props.size === 'medium' &&
    css`
      width: 15em;
    `};

  ${props =>
    props.size === 'large' &&
    css`
      width: 25em;
    `};

  ${props =>
    props.right &&
    `
      left: auto;
      right: 0;
    `};

  ${props =>
    props.top &&
    css`
      top: auto;
      bottom: calc(100% + ${({ theme }) => theme.spacing(0.25)});
    `};

  ${props =>
    props.center &&
    css`
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    `};

  ${props =>
    props.isFull &&
    css`
      width: 100%;
    `};

  ${props =>
    props.above &&
    css`
      top: 0;
    `};
`;

export const PopoverMenu = styled(List)`
  + p {
    border-top: 2px solid ${ui('separator')};
  }
`;

export const PopoverItem = styled.li`
  position: relative;

  + li:before {
    position: absolute;
    top: 0;
    left: ${({ theme }) => theme.spacing()};
    right: 0;
    content: '';
    display: block;
    border-top: 1px solid #f2f4f7;
  }

  ${props =>
    props.isActive &&
    css`
      &:before,
      & + li:before {
        display: none !important;
      }
    `};
`;

export const PopoverItemButton = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()};
  cursor: pointer;
  display: block;
  width: 100%;
  color: inherit;
  text-decoration: none;
  border-radius: 0;
  text-align: inherit;

  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}

  &:hover {
    background-color: ${({ theme }) =>
      tinycolor(theme.primary)
        .setAlpha(0.06)
        .toString()};
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${({ theme }) =>
        tinycolor(theme.primary)
          .setAlpha(0.06)
          .toString()};
      }

      &:hover {
        background-color: ${({ theme }) =>
          tinycolor(theme.primary)
            .setAlpha(0.12)
            .toString()};
      }
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const PopoverItemLink = PopoverItemButton.withComponent(Link);

export const PopoverInner = styled.div`
  margin: ${({ theme }) => theme.spacing()};
`;
