import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
// import Spinner from 'react-spinner-material';
import { List, NavLink, ButtonReset, media } from './';
import background from 'images/icons/plus/menu.svg';
import contacts from 'images/icons/lineking/icUser.svg';
import documents from 'images/icons/lineking/briefcase.svg';
import messages from 'images/icons/lineking/chat-bubble.svg';
import doorOut from 'images/icons/lineking/door-out.svg';
import elevator from 'images/icons/lineking/elevator.svg';
import bin from 'images/icons/lineking/bin.svg';
import bicycle from 'images/icons/lineking/bicycle.svg';
import parking from 'images/icons/lineking/parking.svg';
import parkingIn from 'images/icons/lineking/parking-in.svg';
import bench from 'images/icons/lineking/bench.svg';
import lights from 'images/icons/lineking/lights.svg';
import amperMeter from 'images/icons/lineking/amper-meter.svg';
import { getAttachmentUrl } from 'utils';
import { MdInsertDriveFile } from 'react-icons/md';

export const Content = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.background};
  padding-top: 60px;

  ${media.desktop`
    padding-top: 76px;
  `};

  ${props =>
    props.nested &&
    css`
      margin-left: 0;
    `};

  ${media.desktop`
    margin-left: 280px;

    ${props =>
      props.nested &&
      css`
        margin-left: 0;
      `};
  `};
`;

export const SidebarWrapper = styled.aside`
  position: fixed;
  width: ${props => props.width || 280}px;
  top: 0;
  left: -280px;
  min-height: 100vh;
  background-color: #fff;
  z-index: 3;
  padding-top: 60px;

  ${props =>
    props.secondary &&
    css`
      right: 0;
    `};

  ${props =>
    props.large &&
    css`
      width: 320px;

      + ${Content} {
        margin-left: 320px;
      }
    `};

  ${({ side }) =>
    side === 'right' &&
    `
      left: auto;
      right: -280px;
      z-index: 5;
    `};

  ${media.desktop`
    top: 0;
    left: 0;
    right: auto;
    height: calc(100vh - 60px);
    padding-top: 0;
    background-color: ${({ theme }) => theme.primary};
  `};
`;

export const SidebarMenu = styled(List)`
  ${props =>
    props.hasPaddedChildren &&
    !props.isEmpty &&
    css`
      margin-top: ${({ theme }) => theme.spacing(-0.5)};
    `};
`;

export const SidebarMenuItem = styled.li`
  position: relative;
`;

export const SidebarMenuLinkWrapper = styled(NavLink)`
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing()};
  display: flex;
  color: ${({ theme }) => theme.primary};
  font-weight: 500;
  align-items: center;
  text-decoration: none;
  position: relative;
  z-index: 2;

  svg {
    margin-right: ${({ theme }) => theme.spacing()};
    font-size: 24px;
  }

  &:hover {
    z-index: 3;
    background-color: ${({ theme }) =>
      theme.sidebarMenuActiveLinkBackgroundColor};
  }

  &.active {
    background-color: ${({ theme }) =>
      theme.sidebarMenuActiveLinkBackgroundColor};
  }

  &.hasSuffix {
    padding-right: ${({ theme }) => theme.spacing(2.5)};
  }

  &.hasDoubleSuffix {
    padding-right: ${({ theme }) => theme.spacing(3.5)};
  }

  ${media.desktop`
    color: #fff;
  `};
`;

export const SidebarMenuButton = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  width: 100%;
  display: flex;
  color: ${({ theme }) => theme.text};
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.separator};

    &:before {
      position: absolute;
      bottom: -1px;
      content: '';
      display: block;
      border-top: 1px solid ${({ theme }) => theme.separator};
      left: 0;
      right: 0;
    }
  }
`;

export const SideBarIconWrapper = styled.span`
  position: relative;
  width: 36px;
  height: 36px;
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;

  ${props =>
    props.size === 'small' &&
    css`
      width: 24px;
      height: 24px;
    `};

  > img {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 6px;
    left: 6px;
  }
`;

// const SpinnerWrapper = styled.div`
//   position: absolute;
//   right: ${({ theme }) => theme.spacing(0.5)};
//   bottom: 72px;
//   bottom: ${props => props.footerHeight + 12}px;
//   z-index: 2;
// `;
//
const getSidebarIcon = icon => {
  switch (icon) {
    case 'documents':
      return documents;
    case 'messages':
      return messages;
    case 'contacts':
    case 'door-out':
      return doorOut;
    case 'elevator':
      return elevator;
    case 'bin':
      return bin;
    case 'bicycle':
      return bicycle;
    case 'parking':
      return parking;
    case 'parking-in':
      return parkingIn;
    case 'bench':
      return bench;
    case 'lights':
      return lights;
    case 'amper-meter':
      return amperMeter;
    default:
      return contacts;
  }
};

const SidebarIcon = ({ icon, iconURL, hasDisfunction, hasEmergency, size }) => (
  <SideBarIconWrapper size={size}>
    {hasDisfunction && <Badge />}
    {hasEmergency && <Badge error />}
    {iconURL && <img src={getAttachmentUrl(iconURL)} alt="" />}
    {!iconURL && <img src={getSidebarIcon(icon)} alt="" />}
  </SideBarIconWrapper>
);

const Badge = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  bottom: -4px;
  right: -4px;
  background-color: ${props =>
    props.error ? 'rgb(226,42,42)' : 'rgb(255,152,25)'};
`;

export const SidebarMenuLink = ({
  icon,
  iconURL,
  to,
  children,
  hasDisfunction,
  hasEmergency,
  hasSuffix,
  hasDoubleSuffix,
}) => (
  <SidebarMenuLinkWrapper
    to={to}
    className={
      hasDoubleSuffix ? 'hasDoubleSuffix' : hasSuffix ? 'hasSuffix' : ''
    }
  >
    {(Boolean(icon) ||
      Boolean(iconURL) ||
      Boolean(hasEmergency) ||
      Boolean(hasDisfunction)) && (
      <SidebarIcon
        icon={icon}
        iconURL={iconURL}
        hasEmergency={hasEmergency}
        hasDisfunction={hasDisfunction}
      />
    )}
    {children}
  </SidebarMenuLinkWrapper>
);

export const SidebarMedia = ({ iconURL, children, mediaSize }) => (
  <div>
    <SidebarIcon iconURL={iconURL} size={mediaSize} />
    {children}
  </div>
);

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const SidebarEntity = styled.div`
  min-height: 200px;
  padding: ${({ theme }) => theme.spacing()};
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.separator};

  > img {
    width: ${({ theme }) => theme.spacing(6)};
  }
`;

export const SidebarEntityTitle = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.size(2)};
  margin-bottom: 0;
  margin-top: ${({ theme }) => theme.spacing()};
`;

export const SidebarTitle = styled.h2`
  font-weight: 500;
  font-size: ${({ theme }) => theme.size(0)};
  margin-top: 0;
  margin-bottom: 0;
`;

export const SidebarMainTitle = styled.h2`
  padding: ${({ theme }) => theme.spacing()};
  font-size: ${({ theme }) => theme.size()};
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 2px 0 ${({ theme }) => theme.separator};
  height: 60px;
  line-height: 28px;
  color: ${({ theme }) => theme.textLightish};

  + div > div:nth-child(3) {
    z-index: 5;
  }
`;

export const SidebarEntityParagraph = styled.p`
  color: ${({ theme }) => theme.textLight};
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  margin-bottom: 0;
`;

export const SidebarEntityButtons = styled.div`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing(-0.5)};
`;

export const SidebarEntityButton = styled.button`
  display: inline-block;
  background: linear-gradient(to bottom, #fff, #f6f7f7);
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-size: 0.891rem;
  outline: none;
  color: inherit;
  text-decoration: none;

  &:active,
  &:focus {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  }
`;

export const SidebarEntityLinkButton = SidebarEntityButton.withComponent(Link);

export const SidebarSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const SidebarThumbnail = styled.a`
  display: table;
  width: 100%;
  color: inherit;
  text-decoration: none;
`;

const SidebarThumbnailImageWrapper = styled.span`
  display: table-cell;
  padding: ${({ theme }) => theme.spacing(0.5)};
  padding-left: ${({ theme }) => theme.spacing()};
  width: 0;
`;

const SidebarThumbnailImage = styled.span`
  display: block;
  width: ${({ theme }) => theme.spacing(3)};
  height: ${({ theme }) => theme.spacing(3)};
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  vertical-align: middle;
`;

const SidebarThumbnailBody = styled.span`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
`;

const SidebarThumbnailName = styled.span`
  font-weight: 600;
  font-size: ${({ theme }) => theme.size()};
  display: block;
  word-break: break-all;
`;

const SidebarThumbnailMeta = styled.span`
  font-weight: 400;
  font-size: ${({ theme }) => theme.size(-1)};
  color: ${({ theme }) => theme.textLight};
`;

const SidebarFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const SidebarAttachment = ({
  fileUrl,
  sourceUpdatedAt,
  sourceFileName,
  sourceContentType,
}) => (
  <SidebarThumbnail href={getAttachmentUrl(fileUrl)} download>
    <SidebarThumbnailImageWrapper>
      {sourceContentType.indexOf('pdf') > -1 ? (
        <MdInsertDriveFile size={48} />
      ) : (
        <SidebarThumbnailImage
          style={{ backgroundImage: `url(${getAttachmentUrl(fileUrl)})` }}
        />
      )}
    </SidebarThumbnailImageWrapper>
    <SidebarThumbnailBody>
      <SidebarThumbnailName>{sourceFileName}</SidebarThumbnailName>
      <SidebarThumbnailMeta>
        {moment(sourceUpdatedAt).calendar()}
      </SidebarThumbnailMeta>
    </SidebarThumbnailBody>
  </SidebarThumbnail>
);

export const Sidebar = ({
  title,
  children,
  paddingBottom,
  isOpen = true,
  header,
  footer,
  footerHeight = 0,
  isFetching = false,
  onUpdateScrollbar = () => null,
  ...props
}) => (
  <SidebarWrapper footerHeight={footerHeight} {...props}>
    {Boolean(title) && <SidebarMainTitle>{title}</SidebarMainTitle>}
    {Boolean(header) && <SidebarMainTitle>{header}</SidebarMainTitle>}
    <Scrollbars
      autoHeight
      autoHeightMin={`calc(100vh - ${
        Boolean(title) || Boolean(header)
          ? 120 + footerHeight
          : 60 + footerHeight
      }px)`}
      autoHeightMax={`calc(100vh - ${
        Boolean(title) || Boolean(header)
          ? 120 + footerHeight
          : 60 + footerHeight
      }px)`}
      style={{ width: props.large ? 320 : props.width || 280 }}
      onUpdate={onUpdateScrollbar}
    >
      {children}
    </Scrollbars>
    <SidebarFooter>{footer}</SidebarFooter>
  </SidebarWrapper>
);

export const SidebarMenuBadge = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  position: absolute;
  right: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.5)};
  top: 50%;
  transform: translateY(-50%);
  border-radius: 99px;
  font-size: ${({ theme }) => theme.size(-1)};
  min-width: 2em;
  text-align: center;
`;

export const SidebarMenuItemSuffix = styled.span`
  position: absolute;
  right: ${({ theme }) => theme.spacing()};
  top: 50%;
  transform: translateY(-50%);
`;

export const SidebarInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing()};
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const SidebarAgencySwitcherWrapper = styled(SidebarInner)`
  min-height: 76px;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
`;

export const SidebarMenuSubnav = styled(List)`
  background-color: ${({ theme }) =>
    theme.sidebarMenuActiveLinkBackgroundColor};
`;

export const SidebarMenuSubnavItem = styled.li``;

export const SidebarMenuSubnavLink = styled(NavLink)`
  color: #fff;
  display: block;
  padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(1 + 1 + 1.5)};
  color: ${({ theme }) => theme.primary};

  ${media.desktop`
    color: #fff;
  `};

  &.active {
    background-color: ${({ theme }) =>
      theme.sidebarMenuActiveLinkBackgroundColor};
  }
`;
