import { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { spacing } from 'ui';
import { dismissSnack } from 'actions/snacks';

const Wrapper = styled.dialog`
  position: fixed;
  bottom: 0;
  transform: ${props => (props.active ? 'translateY(0)' : 'translateY(300px)')};
  margin-left: auto;
  margin-right: auto;
  background-color: #323232;
  color: #eef6f6;
  transition: transform 200ms ease-in-out;
  z-index: 2147483648; /* Maximum possible */
  padding: ${spacing}px;
  border: 0;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-transform-style: preserve-3d;
  display: table;
  min-width: 20em;
  max-width: 100%;
  align-items: center;

  @media screen and (min-width: 900px) {
    max-width: 800px;
  }
`;

const Message = styled.p`
  margin: 0;
  display: table-cell;
  vertical-align: middle;
`;

// const ActionContainer = styled.div`
//   display: table-cell;
//   width: 0;
// `;

const Action = styled.button`
  border: 0;
  white-space: nowrap;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  padding-left: ${({ theme }) => theme.spacing(2)};
  font-weight: 600;
  outline: none;
  color: #fff;
  border: 0;
  background-color: transparent;
`;

class SnackBar extends Component {
  state = {
    activeSnack: {},
    snackbarIsActive: false,
  };

  UNSAFE_componentWillUpdate(nextProps) {
    const nextSnackId = nextProps.snacks.queue[0];
    const currentSnackId = this.props.snacks.queue[0];

    if (nextSnackId !== currentSnackId) {
      const nextSnack = nextProps.snacks.byId[nextSnackId];
      const switchActiveIn = currentSnackId ? 300 : 0;

      if (!currentSnackId) {
        this.setState({
          activeSnack: nextSnack,
          snackbarIsActive: true,
        });

        this.handleWillDismiss(nextSnack);
        return;
      }

      if (isEmpty(nextSnack)) {
        this.setState({ snackbarIsActive: false });
        setTimeout(
          () =>
            this.setState({
              activeSnack: {},
            }),
          switchActiveIn,
        );
      } else {
        this.setState({ snackbarIsActive: false });
        setTimeout(
          () =>
            this.setState({
              activeSnack: nextSnack,
              snackbarIsActive: true,
            }),
          switchActiveIn,
        );
        this.handleWillDismiss(nextSnack);
      }
    }
  }

  handleWillDismiss = snack => {
    if (snack.duration) {
      setTimeout(() => this.handleDismiss(snack.id), snack.duration);
    }
  };

  handleDismiss = snackId => {
    this.props.dispatchDismissSnack(snackId);
  };

  render() {
    const { activeSnack, snackbarIsActive } = this.state;

    return (
      <Wrapper
        open={snackbarIsActive}
        aria-hidden={!snackbarIsActive}
        active={snackbarIsActive}
      >
        <Message>{activeSnack.message}</Message>
        <Action
          disabled={!snackbarIsActive}
          onClick={() => this.handleDismiss(activeSnack.id)}
          type={activeSnack.type}
        >
          {activeSnack.action || 'ok'}
        </Action>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  snacks: state.snacks,
});

const mapDispatchToProps = dispatch => ({
  dispatchDismissSnack: id => dispatch(dismissSnack(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
