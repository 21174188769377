import { createAction } from 'redux-actions';
import { getPlaceEquipments } from './places';

export const setTitle = createAction('UPDATE_TITLE');

const syncSelectPlace = createAction('SELECT_PLACE');

export const selectPlace = (id, uuid) => dispatch => {
  dispatch(syncSelectPlace({ id, uuid }));
};

const syncSelectAgency = createAction('SELECT_AGENCY');

export const selectAgency = (id, uuid) => dispatch => {
  dispatch(syncSelectAgency({ id, uuid }));
};

export const selectReportsFilters = createAction('SELECT_REPORTS_FILTERS');
export const selectConversationsFilters = createAction(
  'SELECT_CONVERSATIONS_FILTERS',
);
export const selectTopicsFilters = createAction('SELECT_TOPICS_FILTERS');
export const selectContactsFilters = createAction('SELECT_CONTACTS_FILTERS');
export const selectPlacesFilters = createAction('SELECT_PLACES_FILTERS');
export const selectPartnersFilters = createAction('SELECT_PARTNERS_FILTERS');
export const selectAlertsFilters = createAction('SELECT_ALERTS_FILTERS');
export const updatePartnerSearch = createAction('UPDATE_PARTNERS_SEARCH');
export const updatePlaceSearch = createAction('UPDATE_PLACES_SEARCH');
export const selectPlaceFiscalYear = createAction('SELECT_PLACE_FISCAL_YEAR');
