import styled, { css } from 'styled-components';
import { spacing, size, Title, color } from './';

export const ModalContent = styled.section`
  color: ${color('blue', 'dark')};

  img {
    display: block;
    max-width: 100%;
  }

  strong {
    font-weight: 600;
  }
`;

export const ModalBody = styled.div`
  padding: ${props => (props.bare ? 0 : spacing())};
`;

export const ModalHeader = styled.header`
  text-align: center;

  ${({ padded, theme }) =>
    padded &&
    `
      padding: ${theme.spacing()};
    `};
`;

export const ModalTitle = styled.h1`
  font-size: ${size(3)};
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: 0;

  ${props =>
    props.small &&
    css`
      font-size: ${size(2)};
    `};
`;

export const ModalSubtitle = styled(Title)`
  margin-top: ${props => (props.marged ? spacing(2) : spacing())};
`;

export const ModalSection = styled.div`
  + div {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
