import { createReducer } from 'redux-starter-kit';
import { get } from 'lodash';
import { merge } from 'utils';
import { generatePagination } from 'utils/pagination';
import {
  mergeGlobalStateAndActionPayload,
  addLocalPaginationToGlobalState,
} from 'utils/reducers';

const initialState = {
  placeById: {},
  placesIds: [],
  portfolioPlacesIds: [],
  equipmentById: {},
  equipmentsIdsByPlaceId: [],
  budgetById: {},
  budgetsIdsByPlaceId: {},
  budgetsIdsByPlaceIdAndByFiscalYearId: {},
  treasuryById: {},
  treasuriesIdsByPlaceId: {},
  coownershipDocumentById: {},
  coownershipDocumentsPagination: {},
  coownershipFolderById: {},
  coownershipFoldersIdsByPlaceId: {},
  coownershipFoldersPagination: {},
  portfolioPlacesPagination: {},
  notificationById: {},
  notificationsPagination: {},
  fiscalYearsIdsByPlaceId: {},
  fiscalYearById: {},
  maintenanceContractsCategoryById: {},
  maintenanceContractsCategoriesIdsByPlaceId: {},
  maintenanceContractsIdByMaintenanceContractsCategoryId: {},
  accountPlaceEntryById: {},
  accountPlaceEntriesPagination: {},
  invoiceGroupById: {},
  invoicesPagination: {},
  coownerAmountsPagination: {},
  coownerAmountById: {},
  publicWorkById: {},
  publicWorksPagination: {},
  allPlacesIdsByAgencyId: {},
  placesPagination: {},
  maintenanceContractById: {},
  maintenanceContractDocumentById: {},
};

const places = createReducer(initialState, {
  PLACES_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.placesPagination,
      payload,
      status: 'PENDING',
    }),

  PLACES_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.placesPagination,
      payload,
      status: 'ERROR',
    }),

  PLACES_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.placesPagination,
      ...action,
      status: 'DONE',
    });
  },

  PLACE_COOWNERSHIP_FOLDERS_REQUEST: (state, action) => {
    addLocalPaginationToGlobalState({
      state: state.coownershipFoldersPagination,
      ...action,
      status: 'PENDING',
    });
  },

  PLACE_COOWNERSHIP_FOLDERS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.coownershipFoldersPagination,
      ...action,
      status: 'DONE',
    });
  },

  PLACE_COOWNERSHIP_FOLDERS_ERROR: (state, action) => {
    addLocalPaginationToGlobalState({
      state: state.coownershipFoldersPagination,
      ...action,
      status: 'STALLED',
    });
  },

  COOWNERSHIP_FOLDER_DOCUMENTS_REQUEST: (state, action) => {
    addLocalPaginationToGlobalState({
      state: state.coownershipDocumentsPagination,
      ...action,
      status: 'PENDING',
    });
  },

  COOWNERSHIP_FOLDER_DOCUMENTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.coownershipDocumentsPagination,
      ...action,
      status: 'DONE',
    });
  },

  COOWNERSHIP_FOLDER_DOCUMENTS_ERROR: (state, action) => {
    addLocalPaginationToGlobalState({
      state: state.coownershipDocumentsPagination,
      ...action,
      status: 'STALLED',
    });
  },

  PLACE_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_TMP_PLACE_SUCCESS: mergeGlobalStateAndActionPayload,
  CONVERSATIONS_SUCCESS: mergeGlobalStateAndActionPayload,
  AGENCY_SUCCESS: mergeGlobalStateAndActionPayload,
  EQUIPMENT_SUCCESS: mergeGlobalStateAndActionPayload,
  EDIT_NOTIFICATION_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_COOWNERSHIP_DOCUMENT_SUCCESS: mergeGlobalStateAndActionPayload,
  UPDATE_COOWNERSHIP_FOLDER_SUCCESS: mergeGlobalStateAndActionPayload,
  PLACE_ACTUALITY_SUCCESS: mergeGlobalStateAndActionPayload,

  UPDATE_COOWNERSHIP_FOLDER_REQUEST: (state, { payload }) => {
    state.coownershipFolderById[payload.coownershipFolder.id].displayName =
      payload.coownershipFolder.displayName;
  },

  GET_ALL_PORTFOLIO_PLACES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.allPlacesIdsByAgencyId[payload.agencyId || 'portfolio'] = get(
      payload,
      'data.result',
    );
  },

  PORTFOLIO_PLACES_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.portfolioPlacesIds = action.payload.result;
    state.portfolioPlacesPagination.all = merge(
      get(state, `portfolioPlacesPagination.all`),
      generatePagination(state.portfolioPlacesPagination, 'all', action),
    );
  },

  PLACE_BUDGETS_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.budgetsIdsByPlaceIdAndByFiscalYearId[payload.placeId] = {
      ...(state.budgetsIdsByPlaceIdAndByFiscalYearId[payload.placeId] || {}),
      [payload.fiscalYearId]: payload.data.result,
    };
  },

  PLACE_TREASURIES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.treasuriesIdsByPlaceId[payload.placeId] = payload.data.result;
  },

  // PLACE_COOWNERSHIP_FOLDERS_SUCCESS: (state, { payload }) => {
  //   mergeGlobalStateAndActionPayload(state, { payload });
  //   state.coownershipFoldersIdsByPlaceId[payload.placeId] = get(
  //     payload,
  //     'data.result',
  //   );
  // },

  PLACE_EQUIPMENTS_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.equipmentsIdsByPlaceId[payload.id] = get(payload, 'data.result');
  },

  PLACE_MAINTENANCE_CONTRACTS_CATEGORIES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.maintenanceContractsCategoriesIdsByPlaceId[payload.placeId] =
      payload.data.result;
  },

  PLACE_MAINTENANCE_CONTRACTS_CATEGORY_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.maintenanceContractsIdByMaintenanceContractsCategoryId[
      payload.contractId
    ] = payload.data.result;
  },

  TREASURY_ACCOUNT_PLACE_ENTRIES_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.accountPlaceEntriesPagination[action.payload.treasuryId] = merge(
      get(state, `accountPlaceEntriesPagination[${action.payload.treasuryId}]`),
      generatePagination(
        state.accountPlaceEntriesPagination,
        action.payload.treasuryId,
        action,
      ),
    );
  },

  TREASURY_INVOICES_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.invoicesPagination[action.payload.treasuryId] = merge(
      get(state, `invoicesPagination[${action.payload.treasuryId}]`),
      generatePagination(
        state.invoicesPagination,
        action.payload.treasuryId,
        action,
      ),
    );
  },

  PUBLIC_WORKS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.publicWorksPagination[action.payload.placeId] = merge(
      get(state, `publicWorksPagination[${action.payload.placeId}]`),
      generatePagination(
        state.publicWorksPagination,
        action.payload.placeId,
        action,
      ),
    );
  },

  TREASURY_COOWNER_AMOUNT_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.coownerAmountsPagination[action.payload.treasuryId] = merge(
      get(state, `coownerAmountsPagination[${action.payload.treasuryId}]`),
      generatePagination(
        state.coownerAmountsPagination,
        action.payload.treasuryId,
        action,
      ),
    );
  },

  PLACE_FISCAL_YEARS_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.fiscalYearsIdsByPlaceId[payload.placeId] = payload.data.result;
  },

  ACCOUNT_PLACE_ENTRIES_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.accountPlaceEntriesPagination,
      payload,
      status: 'PENDING',
    }),

  ACCOUNT_PLACE_ENTRIES_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.accountPlaceEntriesPagination,
      payload,
      status: 'ERROR',
    }),

  ACCOUNT_PLACE_ENTRIES_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.accountPlaceEntriesPagination,
      ...action,
      status: 'DONE',
    });
  },

  PLACE_NOTIFICATIONS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.notificationsPagination,
      payload,
      status: 'PENDING',
    }),

  PLACE_NOTIFICATIONS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.notificationsPagination,
      payload,
      status: 'ERROR',
    }),

  PLACE_NOTIFICATIONS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.notificationsPagination,
      ...action,
      status: 'DONE',
    });
  },

  LOGOUT: () => initialState,
});

export default places;
