import { PureComponent } from 'react';
import Downshift from 'downshift';
import styled, { css } from 'styled-components';
import { ButtonReset, ui } from 'ui';
import {
  PopoverItem,
  PopoverMenu,
  PopoverContainer,
  PopoverItemButton,
} from 'ui/popover';
import { find, get } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import { Label } from 'components/TextField';
import { MdExpandMore, MdAccessTime, MdSearch } from 'react-icons/md';
import { RowTitle, RowLabel } from 'ui/datatable';

const Wrapper = styled.div`
  ${({ size }) =>
    size === 'full' &&
    css`
      width: 100%;
    `};

  + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const Input = styled.input`
  border: 0;
  outline: none;
  padding: ${({ theme }) => theme.spacing(0.5)};
  margin: 0;
  background-color: transparent;
  width: 100%;
  display: block;

  &::placeholder {
    color: ${ui('textLight')};
  }

  ${props =>
    props.isFollowingIcon &&
    css`
      padding-left: ${({ theme }) => theme.spacing(2.25)};
    `};
`;

const Trigger = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.5)};
  outline: none;
  color: inherit;
  position: relative;
  overflow: hidden;
  border: 1px solid ${ui('separator')};
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  text-align: left;
  position: relative;
  white-space: nowrap;
  min-height: 40px;
  text-overflow: ellipsis;
  ${'' /* font-size: ${size(1)}; */} svg {
    position: absolute;
    top: 50%;
    right: ${({ theme }) => theme.spacing(0.5)};
    transform: translateY(-50%);
  }

  ${props =>
    props.hasIcon &&
    css`
      padding-left: ${({ theme }) => theme.spacing(2.25)};
    `};
`;

class Select extends PureComponent {
  state = {
    inputValue: '',
  };

  static defaultProps = {
    focusOnMount: false,
    options: [],
  };

  componentDidMount() {
    const { focusOnMount } = this.props;

    if (focusOnMount) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.setState({ isOpen: false });
    }
  }

  handleInputChange = async search => {
    this.setState({ inputValue: search || '' });
  };

  getItemsFromSearch = search => {
    const { options } = this.props;

    if (!search) {
      return options;
    }

    return options.filter(
      ({ label, sublabel = '' }) =>
        label.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        sublabel.toLowerCase().indexOf(search.toLowerCase()) > -1,
    );
  };

  handleSelectValue = ({ value, ...rest }) => {
    const { onChange, name } = this.props;

    this.setState({ inputValue: '' }, () => onChange({ name, value, ...rest }));
  };

  render() {
    const { inputValue, isOpen } = this.state;
    const {
      options,
      placeholder,
      label,
      isSearchable,
      icon,
      isDisabled,
      showsPopoverOnTop,
      shouldHideSearchInput,
      shouldHideLabel,
      isFull,
      above,
      renderOption,
      size,
      right,
      popoverSize,
      noWrap,
    } = this.props;
    const value = this.props.value || '';
    const valueLabel =
      get(
        find(options, opt => opt.value === value),
        'selectedLabel',
      ) ||
      get(
        find(options, opt => opt.value === value),
        'label',
      );

    return (
      <Wrapper size={size}>
        {!shouldHideLabel && Boolean(label) && <Label>{label}</Label>}

        <Trigger
          type="button"
          onClick={() => this.setState({ isOpen: !isDisabled && true })}
          hasIcon={Boolean(icon)}
        >
          {icon === 'time' && (
            <MdAccessTime style={{ right: 'auto', left: 12 }} />
          )}
          {valueLabel || placeholder || '\u00a0'}
          <MdExpandMore />
        </Trigger>

        <div
          style={{
            position: 'relative',
          }}
        >
          <Downshift
            {...{ inputValue, isOpen }}
            onChange={this.handleSelectValue}
            onInputValueChange={this.handleInputChange}
            onOuterClick={() =>
              this.setState({ inputValue: '', isOpen: false })
            }
            itemToString={i => i && i.displayName}
          >
            {({
              getInputProps,
              getItemProps,
              isOpen,
              inputValue,
              highlightedIndex,
            }) => (
              <div>
                {isOpen && (
                  <>
                    <PopoverContainer
                      isOfTypeSelect
                      isOnTop={showsPopoverOnTop}
                      isFull={isFull}
                      above={above}
                      right={right}
                      size={popoverSize}
                    >
                      {!showsPopoverOnTop && icon === 'time' && (
                        <MdAccessTime
                          style={{ position: 'absolute', top: 9, left: 12 }}
                        />
                      )}

                      {!showsPopoverOnTop &&
                        !shouldHideSearchInput &&
                        (isSearchable ? (
                          <label
                            style={{
                              padding: '5px 8px',
                              display: 'flex',
                              borderBottom: `2px solid ${ui('separator')}`,
                              alignItems: 'center',
                            }}
                          >
                            <MdSearch />

                            <Input
                              {...getInputProps({
                                placeholder,
                              })}
                              ref={c => (this.input = c)}
                              isFollowingIcon={Boolean(icon)}
                            />
                          </label>
                        ) : (
                          <Input
                            value={valueLabel}
                            readOnly
                            isFollowingIcon={Boolean(icon)}
                          />
                        ))}
                      <Scrollbars autoHeight autoHeightMax={200}>
                        <PopoverMenu>
                          {this.getItemsFromSearch(inputValue).map(
                            (item, index) => (
                              <PopoverItem key={index}>
                                <PopoverItemButton
                                  noWrap={noWrap}
                                  type="button"
                                  {...getItemProps({ item })}
                                  isHighlighted={highlightedIndex === index}
                                >
                                  {Boolean(renderOption) ? (
                                    renderOption(item)
                                  ) : (
                                    <>
                                      <RowTitle>{item.label}</RowTitle>
                                      {Boolean(item.sublabel) && (
                                        <RowLabel>{item.sublabel}</RowLabel>
                                      )}
                                    </>
                                  )}
                                </PopoverItemButton>
                              </PopoverItem>
                            ),
                          )}
                        </PopoverMenu>
                      </Scrollbars>
                    </PopoverContainer>
                  </>
                )}
              </div>
            )}
          </Downshift>
        </div>
      </Wrapper>
    );
  }
}

export default Select;
