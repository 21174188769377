import {
  capitalize,
  camelCase,
  pickBy,
  identity,
  keys,
  omit,
  trimStart,
} from 'lodash';
import { camelize, decamelize } from 'humps';
import queryString from 'query-string';

const crumbNames = {
  // Misc
  editer: 'Éditer',

  communaute: 'Communauté',
  lieu: 'Lieu',
  lieu_signalements: 'Signalements en cours',
  messages: 'Messages',
  documents: 'Documents',
  contacts: 'Contacts',
  equipments: 'Équipements',

  // Paramètres
  parametres: 'Paramètres',
  mesLieux: 'Mes lieux',
  monProfil: 'Mon profil',
  gestionDesComptes: 'Gestion des comptes',

  repondre: 'Répondre',

  contratsDEntretien: 'Contrats d’entretien',
  tresorerie: 'Trésorerie',
  soldesCoproprietaires: 'Soldes copropriétaires',
  tresorerieActuelle: 'Trésorerie actuelle',

  forum: 'Forum C.S',
  tableauDeBord: 'Tableau de bord',
  batch: 'Administration',
  batch_conversations: 'Nouveau message aux CS',
  batch_notifications: 'Nouvelle actualité immeuble',
  actualites: 'Actualités',
};

export const getPathSlug = url =>
  url
    .slice(1)
    .split('/')
    .map(camelCase)
    .join('_');

export const getCrumbName = (crumb, url, customTranslations = {}) => {
  const pathSlug = getPathSlug(url);
  const customTranslation = customTranslations[pathSlug];
  const pathTranslation = crumbNames[pathSlug];
  const crumbTranslation = crumbNames[camelCase(crumb)];

  // If full path has specific translation, use it.
  // If not, return the crumb translation.
  // If it does not exist, return the crumb slug.
  return customTranslation
    ? customTranslation
    : pathTranslation
    ? pathTranslation
    : crumbTranslation
    ? crumbTranslation
    : capitalize(crumb);
};

export const getURLWithParam = (url = '', search, param = {}) => {
  // pickBy removes null values from params object. It permits to
  // remove a key from URL by setting key: null.
  const params = pickBy(
    {
      ...queryString.parse(search),
      ...param,
    },
    identity,
  );

  const query = queryString.stringify(params);

  if (!Boolean(query)) {
    return url;
  }

  return `${url}?${query}`;
};

export const getApiKeyFromPaginationKey = key => {
  switch (key) {
    case 'unread':
      return 'with_unread_message';
    case 'sergicOffer':
    case 'categoryKind':
    case 'state':
    case 'place':
    case 'agency':
      return `by_${decamelize(key)}`;
    case 'sergicPartner':
      return 'filter[sergic_partner]';
    case 'search':
      return 'filter[search]';
    default:
      return decamelize(key);
  }
};

export const getApiValueFromPaginationValue = (key, value) => {
  switch (key) {
    case 'unread':
    case 'archived':
    case 'inPortfolio':
      return true;
    case 'sort':
      return decamelize(value);
    case 'sergicOffer':
      return value;
    default:
      return (value || '').toLowerCase();
  }
};

export const getPaginationKeyFromApiKey = key => {
  switch (key) {
    case 'with_unread_message':
      return 'unread';
    case 'archived':
      return 'archived';
    case 'filter[search]':
      return 'search';
    default:
      return camelize(trimStart(key, 'by_'));
  }
};

export const getPaginationValueFromApiValue = (key, value) => {
  switch (key) {
    case 'with_unread_message':
    case 'archived':
    case 'in_portfolio':
      return true;
    case 'filter[search]':
      return encodeURI(value);
    case 'by_sergic_offer':
      return value;
    default:
      return `${value.charAt(0) === '-' ? '-' : ''}${camelize(value)}`;
  }
};

export const getApiQueryFromPaginationQuery = input => {
  const paginationQuery = queryString.parse(input);
  let apiQuery = {};
  keys(paginationQuery).forEach(key => {
    apiQuery = {
      ...apiQuery,
      [getApiKeyFromPaginationKey(key)]: getApiValueFromPaginationValue(
        key,
        paginationQuery[key],
      ),
    };
  });

  return `?${queryString.stringify(apiQuery)}`;
};

export const getPaginationQueryFromApiQuery = input => {
  const apiQuery = omit(input, ['per_page', 'page']);

  let paginationQuery = {};
  keys(apiQuery).forEach(key => {
    paginationQuery = {
      ...paginationQuery,
      [getPaginationKeyFromApiKey(key)]: getPaginationValueFromApiValue(
        key,
        apiQuery[key],
      ),
    };
  });

  return `?${queryString.stringify(paginationQuery)}`;
};

export const getPageFromQuery = query => queryString.parse(query).page || 1;

export const getQueryWithoutPage = input => {
  const { page: omit, ...query } = queryString.parse(input);

  return `?${queryString.stringify(query)}`;
};
