import Icon from 'react-icon-base';

const IcChat = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M2.4 15.6a.4.4 0 0 1-.4.4c-1.103 0-2-.897-2-2V2C0 .897.897 0 2 0h16.8c1.103 0 2 .897 2 2v1.6a.4.4 0 0 1-.8 0V2c0-.662-.538-1.2-1.2-1.2H2C1.338.8.8 1.338.8 2v12c0 .662.538 1.2 1.2 1.2.22 0 .4.18.4.4zM7.6 12h4a.4.4 0 0 0 0-.8h-4a.4.4 0 0 0 0 .8zm10-.4c0 .22.18.4.4.4h1.6a.4.4 0 0 0 0-.8H18a.4.4 0 0 0-.4.4zm-.8 0a.4.4 0 0 0-.4-.4h-3.2a.4.4 0 0 0 0 .8h3.2a.4.4 0 0 0 .4-.4zm2-2.8h-7.2a.4.4 0 0 0 0 .8h7.2a.4.4 0 0 0 0-.8zm-11.2.8H10a.4.4 0 0 0 0-.8H7.6a.4.4 0 0 0 0 .8zm0 7.2H14a.4.4 0 0 0 0-.8H7.6a.4.4 0 0 0 0 .8zm8 0h4a.4.4 0 0 0 0-.8h-4a.4.4 0 0 0 0 .8zm-.8-2.4h4a.4.4 0 0 0 0-.8h-4a.4.4 0 0 0 0 .8zm-7.2 0h5.6a.4.4 0 0 0 0-.8H7.6a.4.4 0 0 0 0 .8zM22 4.8H5.2c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h10a.4.4 0 0 0 0-.8h-10c-.662 0-1.2-.538-1.2-1.2v-12c0-.662.538-1.2 1.2-1.2H22c.662 0 1.2.538 1.2 1.2v12c0 .662-.538 1.2-1.2 1.2h-1.6a.4.4 0 0 0-.4.4v2.234l-3.317-3.317a.4.4 0 1 0-.566.566l4 4a.4.4 0 0 0 .683-.283v-2.8H22c1.103 0 2-.897 2-2v-12c0-1.103-.897-2-2-2z" />
    </g>
  </Icon>
);

export default IcChat;
