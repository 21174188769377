import { createAction } from 'redux-actions';
import { getExpensesSummary } from '../api/v2/expenses';

const requestExpensesSummary = createAction('EXPENSES_SUMMARY_REQUEST');
const receiveExpensesSummary = createAction('EXPENSES_SUMMARY_SUCCESS');
const failExpensesSummary = createAction('EXPENSES_SUMMARY_ERROR');

export const fetchExpensesSummary = (placeId, fiscalYearId, keepPreviousData = false) => dispatch => {
   dispatch(requestExpensesSummary({ keepPreviousData }));

   return getExpensesSummary(placeId, fiscalYearId).then(
      response => dispatch(receiveExpensesSummary(response)),
      error => dispatch(failExpensesSummary(error.response))
   );
};
