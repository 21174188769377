import Icon from 'react-icon-base';

const IcBriefcase = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path
        d="M14.8 4a.4.4 0 0 1-.4-.4c0-.662-.538-1.2-1.2-1.2h-2.4c-.662 0-1.2.538-1.2 1.2a.4.4 0 0 1-.8 0c0-1.103.897-2 2-2h2.4c1.103 0 2 .897 2 2a.4.4 0 0 1-.4.4zm8.4 13.6V22c0 .662-.538 1.2-1.2 1.2H2c-.662 0-1.2-.538-1.2-1.2v-4.4a.4.4 0 0 0-.8 0V22c0 1.103.897 2 2 2h20c1.103 0 2-.897 2-2v-4.4a.4.4 0 0 0-.8 0zm-12.8.4v1.2a.8.8 0 0 0 .8.8h1.6a.8.8 0 0 0 .8-.8V18a.4.4 0 0 0-.8 0v1.2h-1.6V18a.4.4 0 0 0-.8 0zM24 14.8c0 1.103-.897 2-2 2h-8.8a.4.4 0 0 1-.4-.4v-3.6h-1.6v3.6a.4.4 0 0 1-.4.4H2c-1.103 0-2-.897-2-2v-8c0-1.103.897-2 2-2h20c1.103 0 2 .897 2 2v8zm-2-9.2h-2v9.2a.4.4 0 0 1-.8 0V5.6H4.8v9.2a.4.4 0 0 1-.8 0V5.6H2c-.662 0-1.2.538-1.2 1.2v8c0 .662.538 1.2 1.2 1.2h8.4v-3.2a.8.8 0 0 1 .8-.8h1.6a.8.8 0 0 1 .8.8V16H22c.662 0 1.2-.538 1.2-1.2v-8c0-.662-.538-1.2-1.2-1.2zM12.012 18h-.004c-.22 0-.398.18-.398.4a.402.402 0 0 0 .802 0 .4.4 0 0 0-.4-.4zM8 3.6C8 2.16 9.36.8 10.8.8h2.4C14.666.8 16 2.134 16 3.6a.4.4 0 0 0 .8 0c0-1.918-1.682-3.6-3.6-3.6h-2.4C8.916 0 7.2 1.716 7.2 3.6a.4.4 0 0 0 .8 0z"
        fillRule="evenodd"
      />
    </g>
  </Icon>
);

export default IcBriefcase;
