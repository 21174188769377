import { createAction } from 'redux-actions';
import { getPlace, getEquipment } from './places';
import { getAgencies } from './agencies';
import * as Api from 'api/reports';
import { get } from 'lodash';

const requestReportsCategories = createAction('REPORTS_CATEGORIES_REQUEST');
const receiveReportsCategories = createAction('REPORTS_CATEGORIES_SUCCESS');
const failReportsCategories = createAction('REPORTS_CATEGORIES_ERROR');

export const getReportsCategories = () => dispatch => {
  dispatch(requestReportsCategories());

  return Api.getReportsCategories().then(
    response => dispatch(receiveReportsCategories(response)),
    error => dispatch(failReportsCategories(error)),
  );
};

const receiveToggleReportUserWitness = createAction(
  'REPORT_USER_WITNESS_TOGGLE_SUCCESS',
);

export const toggleWitnessReport = (id, witness) => dispatch => {
  return Api.toggleWitnessReport(id, { witness }).then(
    response => dispatch(receiveToggleReportUserWitness(response)),
    error => console.error(error),
  );
};

const requestReport = createAction('REPORT_REQUEST');
const receiveReport = createAction('REPORT_SUCCESS');
const failReport = createAction('REPORT_ERROR');

export const getReport = (id, fromPortfolio = false) => async dispatch => {
  dispatch(requestReport(id));

  return Api.getReport(id).then(
    response => {
      const report = get(response, `entities.reports.${response.result}`);
      const equipmentId = get(report, 'equipmentId');
      const placeId = get(report, 'placeId');

      if (fromPortfolio && Boolean(report)) {
        // const reportContacts = [report.author, ...report.witnesses];
        // reportContacts.forEach(id => dispatch(getContactContracts(id)));
      }

      dispatch(receiveReport(response));
      dispatch(getEquipment(equipmentId, true));
      dispatch(getPlace(placeId));
    },
    error => dispatch(failReport(error)),
  );
};

const requestReportContracts = createAction('REPORT_CONTRACTS_REQUEST');
const receiveReportContracts = createAction('REPORT_CONTRACTS_SUCCESS');
const failReportContracts = createAction('REPORT_CONTRACTS_ERROR');

export const getReportContracts = (agencyId, reportId) => async dispatch => {
  dispatch(requestReportContracts(agencyId, reportId));

  return Api.getReportContracts(agencyId, reportId).then(
    data => dispatch(receiveReportContracts({ agencyId, data })),
    error => dispatch(failReportContracts(error)),
  );
};

const requestResolveReport = createAction('RESOLVE_REPORT_REQUEST');
const receiveResolveReport = createAction('RESOLVE_REPORT_SUCCESS');
const failResolveReport = createAction('RESOLVE_REPORT_ERROR');

export const resolveReport = id => dispatch => {
  dispatch(requestResolveReport());

  return Api.resolveReport(id).then(
    response => {
      dispatch(receiveResolveReport(response));
      dispatch(getAgencies());
    },
    error => dispatch(failResolveReport(error)),
  );
};

const requestPostReportEvent = createAction('NEW_REPORT_EVENT_REQUEST');
const receivePostReportEvent = createAction('NEW_REPORT_EVENT_SUCCESS');
const failPostReportEvent = createAction('NEW_REPORT_EVENT_ERROR');

export const postReportEvent = (reportId, payload) => dispatch => {
  dispatch(requestPostReportEvent());

  return Api.postReportEvent(reportId, payload).then(
    response => {
      dispatch(getReport(reportId));
      return dispatch(receivePostReportEvent(response));
    },
    error => dispatch(failPostReportEvent(error)),
  );
};

const requestAcknowledgeReport = createAction('ACKNOWLEDGE_REPORT_REQUEST');
const receiveAcknowledgeReport = createAction('ACKNOWLEDGE_REPORT_SUCCESS');
const failAcknowledgeReport = createAction('ACKNOWLEDGE_REPORT_ERROR');

export const acknowledgeReport = id => dispatch => {
  dispatch(requestAcknowledgeReport());

  return Api.acknowledgeReport(id).then(
    response => dispatch(receiveAcknowledgeReport(response)),
    error => dispatch(failAcknowledgeReport(error)),
  );
};

const requestCreateReport = createAction('NEW_REPORT_REQUEST');
const receiveCreateReport = createAction('NEW_REPORT_SUCCESS');
const failCreateReport = createAction('NEW_REPORT_ERROR');

export const createReport = (equipmentId, payload) => dispatch => {
  dispatch(requestCreateReport());

  return Api.createReport(equipmentId, payload).then(
    response => dispatch(receiveCreateReport(response)),
    error => dispatch(failCreateReport(error)),
  );
};
