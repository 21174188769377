const defs = {
  // Account Places
  accountCodeAccountPlaceEntry: 'accountCodeAccountPlaceEntries',
  accountPlaceEntry: 'accountPlaceEntries',
  coownerAmount: 'coownerAmounts',
  invoice: 'invoices',
  invoiceGroup: 'invoicesGroups',
  providerAccountPlaceEntry: 'providerAccountPlaceEntries',
  publicWork: 'publicWorks',

  // Agencies
  agency: 'agencies',

  // Alerts
  alert: 'alerts',
  connectedDeviceAlert: 'alerts',

  // Conversations
  conversation: 'conversations',
  message: 'messages',

  // Forum
  topic: 'topics',
  post: 'posts',
  topicCategory: 'topicsCategories',

  // Places
  place: 'places',
  equipment: 'equipments',
  budget: 'budgets',
  treasury: 'treasuries',
  coownershipDocument: 'coownershipDocuments',
  coownershipFolder: 'coownershipFolders',
  notification: 'notifications',
  maintenanceContract: 'maintenanceContracts',
  maintenanceContractsCategory: 'maintenanceContractsCategories',
  maintenanceContractDocument: 'maintenanceContractDocuments',
  fiscalYear: 'fiscalYears',

  // Reports
  category: 'category',
  report: 'reports',
  reportEvent: 'reportEvents',
  reportResolution: 'reportResolutions',

  // Users
  user: 'users',
  identity: 'identities',

  contract: 'contracts',
  contractFolder: 'contractFolders',
  contractDocument: 'contractDocuments',
};

export default defs;
