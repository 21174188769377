import { Component } from 'react';
import styled from 'styled-components';
import { Buttons, Card as RawCard, Container, Title } from '../ui';

import { withTranslation } from 'react-i18next';
import { Logo } from '../ui/auth';
import logo from '../images/logo-sergic.svg';
import { Button } from '@tymate/sergic';

const Card = styled(RawCard)`
   flex-direction: column;
`;

const Wrapper = styled.div`
   min-height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: ${({ theme }) => theme.background};
   flex-direction: column;
`;

const Content = styled.div`
   flex: 0 0 100%;
`;

const P = styled.p`
   color: ${({ theme }) => theme.text};
   line-height: 1.5;
`;

class ErrorBoundary extends Component {
   state = {};

   static getDerivedStateFromError(error) {
      return { hasError: true };
   }

   redirectToBaseUrl = () => {
      window.location.href = '/';
   };

   refreshPage = () => {
      window.location.reload(true);
   };

   render() {
      if (this.state.hasError) {
         return (
            <Wrapper>
               <Container tight>
                  <Card isPadded>
                     <Logo>
                        <img src={logo} alt='sergic USER' />
                     </Logo>
                     <Content>
                        <Title>Une erreur est survenue</Title>
                        <P>
                           Nous sommes désolés, un rapport d’erreur a été envoyé à notre équipe. Réessayez en cliquant
                           sur “Recharger la page”. Si le problème persiste, n'hésitez pas à nous contacter.
                        </P>
                     </Content>
                     <Buttons marged>
                        <Button variant='primary' onClick={this.refreshPage}>
                           Recharger la page
                        </Button>
                        <Button variant='simple' onClick={this.redirectToBaseUrl}>
                           Retour à la page d'accueil
                        </Button>
                     </Buttons>
                  </Card>
               </Container>
            </Wrapper>
         );
      }

      return this.props.children;
   }
}

export default withTranslation('mainError')(ErrorBoundary);
