import { Wrapper, Logo } from 'ui/auth';
import { Container } from 'ui';
import dev from 'images/sergic-logo-dev-white.svg';
import staging from 'images/sergic-logo-preprod-white.svg';
import prod from 'images/sergic-logo-white.svg';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

const getLogoUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return dev;
    case 'preprod':
      return staging;
    case 'production':
    default:
      return prod;
  }
};

const AuthLayout = ({ children }) => {
  return (
    <Wrapper>
      <Container tight center>
        <Logo>
          <img src={getLogoUrl()} alt="sergic USER" />
        </Logo>

        {children}
      </Container>
    </Wrapper>
  );
};

export default AuthLayout;
