import { destroy, get, post, put, rawGet } from './';
import { get as getV2, post as postV2 } from './v2/index';
import {
  budget,
  conversation,
  coownershipDocument,
  coownershipFolder,
  equipment,
  fiscalYear,
  maintenanceContract,
  maintenanceContractsCategory,
  notification,
  place,
  report,
  reportCategory,
  treasury,
  user,
} from './schema';

export const getPlaces = payload => get('/v1/users/me/places', [place]);
export const getPlace = id => get(`/v1/places/${id}`, place);
export const getEquipment = id => get(`/v1/equipments/${id}`, equipment);

export const getPlaceEquipments = id =>
  get(`/v1/places/${id}/equipments`, [equipment]);
export const getPlaceContacts = id => get(`/v1/places/${id}/contacts`, [user]);
export const getPlaceContactsPaginated = id =>
  get(`/v1/places/${id}/contacts?page=1`, [user]);
export const getPlaceContactsCSV = id =>
  rawGet(`/v1/identities?by_place=${id}&to_csv=true`);
export const getPlaceBuildings = ({ queryKey }) =>
  get(`/v1/places/${queryKey[1]}/buildings`, undefined, {}, false);

export const getPlaceConversations = id =>
  get(`/v1/places/${id}/conversations`, [conversation]);
export const getPlaceReports = id => get(`/v1/places/${id}/reports`, [report]);
export const getPlaceTreasuries = id =>
  get(`/v1/places/${id}/treasuries`, [treasury]);
export const getPlaceBudgets = (placeId, fiscalYearId) =>
  get(
    `/v1/places/${placeId}/budgets${
      fiscalYearId ? `?fiscal_year_id=${fiscalYearId}` : ''
    }`,
    [budget],
  );
export const getPlaceMaintenanceWorks = id =>
  get(`/v1/places/${id}/maintenance_works`);

export const getPlaceCoownershipFolders = ({ sliceId, params }) =>
  get(`/v2/places/${sliceId}/coownership_folders${params}`, [
    coownershipFolder,
  ]);

export const getCoownershipFolderDocuments = ({ sliceId, params }) =>
  get(`/v2/places/${sliceId}/coownership_documents${params}`, [
    coownershipDocument,
  ]);

export const createCoownershipFolder = ({ placeId, payload }) =>
  post(`/v1/places/${placeId}/coownership_folders`, payload, coownershipFolder);

export const updateCoownershipFolder = ({
  placeId,
  payload,
  coownershipFolderId,
}) =>
  put(
    `/v1/places/${placeId}/coownership_folders/${coownershipFolderId}`,
    payload,
    coownershipFolder,
  );

export const deleteCoownershipFolder = ({ placeId, coownershipFolderId }) =>
  destroy(
    `/v1/places/${placeId}/coownership_folders/${coownershipFolderId}`,
    coownershipFolder,
  );

export const postPlaceConversation = (id, payload) =>
  post(`/v1/places/${id}/conversations`, payload, conversation);

export const getPlaceNotifications = id =>
  get(`/v1/places/${id}/place_actualities`, [notification]);
export const postPlaceActuality = (id, payload) =>
  post(`/v1/places/${id}/place_actualities`, payload, notification);
export const getNotification = id =>
  get(`/v1/place_actualities/${id}`, notification);
export const putPlaceActuality = (notificationId, payload) =>
  put(`/v1/place_actualities/${notificationId}`, payload, notification);
export const deleteNotification = id => destroy(`/v1/place_actualities/${id}`);

export const getPlaceCollaborators = id =>
  get(`/v1/places/${id}/contacts?sergic_partner=true`, [user]);

export const getPlaceFiscalYears = id =>
  get(`/v2/places/${id}/fiscal_years`, [fiscalYear]);

export const getPlaceMaintenanceContractsCategories = id =>
  get(`/v1/places/${id}/maintenance_contract_categories`, [
    maintenanceContractsCategory,
  ]);

export const getPlaceMaintenanceContract = id =>
  get(`/v1/maintenance_contracts/${id}`, maintenanceContract);

export const getReportCategories = () =>
  get(`/v1/report_categories`, [reportCategory]);

export const getShareableRoles = () => get(`/v1/roles?filter=for_invitation`);
export const getPlaceInvitations = placeId =>
  get(`/v1/places/${placeId}/invitations`);

export const createTmpPlace = payload =>
  post(`/v1/temporary_places`, payload, place);
export const updateTmpPlace = (id, payload) =>
  put(`/v1/temporary_places/${id}`, payload, place);
export const generateTmpPlaceInvitationLink = id =>
  put(`/v1/temporary_places/${id}/invitation_link`, {}, place);
export const cleanTmpPlace = id =>
  post(`/v1/temporary_places/${id}/clean`, {}, place);
export const syncTmpPlace = id =>
  post(`/v1/temporary_places/${id}/synchronize`, {}, place);
export const getPlaceDistributionLists = id =>
  get(`/v1/places/${id}/distribution_lists`);
export const searchPlaceContacts = (id, query) =>
  get(`/v1/places/${id}/contacts?${query}`, [user]);

export const resyncPlace = id =>
  post(`/v1/places/require_sync/${id}`, {}, place);

export const getPlaceCoowners = placeId =>
  getV2(
    `/v1/places/${placeId}/coowners?coownership_board_members=true`,
    undefined,
    undefined,
    undefined,
  );

export const postExpensesManager = (placeId, payload) =>
  postV2(`/v2/places/${placeId}/expenses/expenses_manager`, payload);
