import { schema } from 'normalizr';

export const reportCategory = new schema.Entity('category');
export const budget = new schema.Entity('budgets');
export const treasury = new schema.Entity('treasuries');
export const contract = new schema.Entity('contracts');
export const coownershipDocument = new schema.Entity('coownershipDocuments');
export const coownershipFolder = new schema.Entity('coownershipFolders', {
  coownershipDocuments: [coownershipDocument],
});
export const contractDocument = new schema.Entity('contractDocuments');
export const contractFolder = new schema.Entity('contractFolders', {
  contractDocuments: [contractDocument],
});
export const user = new schema.Entity('users');
export const identity = new schema.Entity('identities');
export const reportEvents = new schema.Entity('reportEvents', {
  author: user,
});
export const reportResolution = new schema.Entity('reportResolutions');
export const report = new schema.Entity('reports', {
  author: user,
  category: reportCategory,
  reportEvents: [reportEvents],
  reportResolution,
  witnesses: [user],
});
user.define({
  lastFiveReports: [report],
});
export const equipment = new schema.Entity('equipments');
export const place = new schema.Entity('places');
export const accountPlace = new schema.Entity('accountPlaces');
export const accountPlaceCode = new schema.Entity('accountPlaceCodes');
export const accountCode = new schema.Entity('accountCodes');
export const agency = new schema.Entity('agencies', {
  places: [place],
});
export const conversation = new schema.Entity('conversations', {
  members: [user],
  agency,
});
export const message = new schema.Entity('messages', {
  author: user,
});
export const notification = new schema.Entity('notifications');
export const fiscalYear = new schema.Entity('fiscalYears');
export const invoice = new schema.Entity('invoices');
export const invoiceGroup = new schema.Entity('invoicesGroups', {
  invoices: [invoice],
});
export const accountPlaceEntry = new schema.Entity('accountPlaceEntries');
export const coownerAmount = new schema.Entity('coownerAmounts');
export const publicWork = new schema.Entity('publicWorks');
export const maintenanceContractsCategory = new schema.Entity(
  'maintenanceContractsCategories',
);
export const maintenanceContractDocument = new schema.Entity(
  'maintenanceContractDocuments',
);
export const maintenanceContract = new schema.Entity('maintenanceContracts', {
  maintenanceContractDocuments: [maintenanceContractDocument],
});

export const topic = new schema.Entity('topics', { author: user });
export const post = new schema.Entity('posts');
export const topicCategory = new schema.Entity('topicsCategories');
export const providerAccountPlaceEntry = new schema.Entity(
  'providerAccountPlaceEntries',
  { accountPlaceEntries: [accountPlaceEntry] },
);
export const accountCodeAccountPlaceEntry = new schema.Entity(
  'accountCodeAccountPlaceEntries',
  { accountPlaceEntries: [accountPlaceEntry] },
);
export const distributionList = new schema.Entity('distributionList');
export const connectedDeviceAlert = new schema.Entity('alerts');
