import { Component } from 'react';
import styled, { css } from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import { color, ui, TitleAndAction, Buttons, media } from 'ui';
import { Table, Thead, Tr, Th, Td, Tbody } from 'ui/table';
import { get, isEqual, orderBy, pickBy } from 'lodash';
import { MdUnfoldMore, MdKeyboardArrowDown } from 'react-icons/md';
import LocationAwareSearch from 'components/LocationAwareSearch';
import MDSpinner from 'react-md-spinner';
import queryString from 'query-string';
import { overrideSearchWith } from 'utils';

const ASC = 'asc';
const DESC = 'desc';

const TableWrapper = styled.div`
  max-width: calc(100vw - ${({ theme }) => theme.spacing(2)});
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ${media.tablet`
    overflow-x: visible;
    max-width: none;
  `};
`;

export const Title = styled.p`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
`;

export const Preview = styled.p`
  color: ${color('grey', 'blueish')};
  margin-bottom: 0;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

const getInitialState = ({ headings, activeProp }) => {
  let state = {};

  if (activeProp) {
    state = {
      ...state,
      activeProp,
    };
  }

  // eslint-disable-next-line
  for (const prop of headings) {
    state = {
      ...state,
      propByKey: {
        ...state.propByKey,
        [prop.slug]: DESC,
      },
    };
  }

  return state;
};

const Content = ({ render, value }) =>
  render ? render() : <span>{value}</span>;

const SwitchButton = styled.button`
  border: 0;
  color: ${props => (props.active ? ui('textLight') : ui('textLightish'))};
  outline: none;
  cursor: ${({ filterable, fixed }) =>
    filterable && !fixed ? 'pointer' : 'normal'};
  padding: 0;
  font-size: inherit;
  text-transform: inherit;
  display: flex;
  align-items: center;
  background-color: transparent;
  white-space: nowrap;
  width: 100%;
  text-align: inherit;
  text-decoration: none;

  ${({ textAlign }) =>
    textAlign === 'right' &&
    css`
      justify-content: flex-end;
    `};

  svg {
    margin-right: 0;
    transform: ${props => (props.desc ? 'none' : 'rotate(180deg)')};
    transition: transform 150ms ease;
  }
`;

class DataTable extends Component {
  static defaultProps = {
    headings: [],
    data: [],
    fixedLines: [],
    filterable: true,
    onOrderBy: () => null,
    sort: '',
    isSearchable: true,
  };

  constructor(props) {
    super(props);

    this.state = getInitialState(props);
  }

  componentDidMount() {
    Boolean(this.props.onReorder) && this.sendIdsToParent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.state, prevState)) {
      Boolean(this.props.onReorder) && this.sendIdsToParent();
    }
  }

  sendIdsToParent = () => {
    const { activeProp, propByKey } = this.state;
    const nested = item => item[activeProp].value;

    const orderedIds = (
      (activeProp &&
        Boolean(this.props.data.length) &&
        orderBy(this.props.data, nested, propByKey[activeProp])) ||
      this.props.data
    ).map(({ id }) => id);

    this.props.onReorder(orderedIds);
  };

  orderBy = (prop, fixed) => {
    const { filterable } = this.props;
    if (!filterable || fixed) return;

    this.props.onOrderBy(prop);
  };

  togglePropOrder = prop => {
    const { propByKey } = this.state;

    if (propByKey[prop] === ASC) {
      this.setState({
        propByKey: {
          ...propByKey,
          [prop]: DESC,
        },
        activeProp: null,
      });

      return;
    }

    this.setState({
      propByKey: {
        ...propByKey,
        [prop]: propByKey[prop] === DESC ? ASC : DESC,
      },
    });
  };

  render() {
    const {
      headings,
      style,
      fixedLines,
      filterable,
      modal,
      prev,
      fixedHeader,
      loading,
      emptyState,
      location,
      isSearchable,
      action,
      hasFooterActions,
      leftAction,
      dropZone,
    } = this.props;
    const { activeProp, propByKey } = this.state;
    const nested = item => item[activeProp].value;
    const sort = get(queryString.parse(location.search), 'sort');

    const data =
      (activeProp &&
        Boolean(this.props.data.length) &&
        orderBy(this.props.data, nested, propByKey[activeProp])) ||
      this.props.data;

    const shouldShowEmptyState =
      !Boolean(dropZone) &&
      Boolean(emptyState) &&
      data.length === 0 &&
      !loading;

    return (
      <>
        {(Boolean(action) || Boolean(isSearchable) || Boolean(leftAction)) && (
          <TitleAndAction>
            {(Boolean(action) ||
              Boolean(isSearchable) ||
              Boolean(leftAction)) && (
              <Buttons left>
                {Boolean(isSearchable) && <LocationAwareSearch />}
                {leftAction}
              </Buttons>
            )}
            {Boolean(action) && <div>{action}</div>}
          </TitleAndAction>
        )}

        <TableWrapper>
          {' '}
          <Table
            variant="bordered"
            style={style}
            fixedHeader={fixedHeader}
            hasFooterActions={hasFooterActions}
          >
            <Thead fixedHeader={fixedHeader}>
              <Tr>
                {headings.map(
                  ({
                    slug,
                    label,
                    fixed,
                    width,
                    textAlign,
                    hasNoLeftPadding,
                    renderLabel,
                  }) => (
                    <Th
                      key={slug}
                      style={{
                        width,
                        textAlign,
                      }}
                      hasNoLeftPadding={hasNoLeftPadding}
                    >
                      {(Boolean(label) || Boolean(renderLabel)) && (
                        <SwitchButton
                          as={!fixed ? Link : null}
                          to={{
                            pathname: location.pathname,
                            search: queryString.stringify(
                              pickBy({
                                ...queryString.parse(location.search),
                                sort:
                                  sort === `-${slug}`
                                    ? slug
                                    : sort === slug
                                    ? null
                                    : `-${slug}`,
                              }),
                            ),
                          }}
                          filterable={filterable}
                          fixed={fixed}
                          active={sort === slug || sort === `-${slug}`}
                          onClick={() => this.orderBy(slug, fixed)}
                          desc={sort === `-${slug}`}
                          textAlign={textAlign}
                        >
                          {Boolean(renderLabel) ? renderLabel() : label}
                          {fixed ? null : sort === slug ||
                            sort === `-${slug}` ? (
                            <MdKeyboardArrowDown />
                          ) : (
                            <MdUnfoldMore />
                          )}
                        </SwitchButton>
                      )}
                    </Th>
                  ),
                )}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((data, index) => (
                <Tr
                  key={index}
                  className={data.cls || ''}
                  to={
                    data.path && !data.isExternal
                      ? {
                          pathname: data.path,
                          search: data.searchOverride
                            ? overrideSearchWith({
                                location,
                                ...data.searchOverride,
                              })
                            : location.search,
                          state: { modal, prev },
                        }
                      : null
                  }
                  onClick={data.onClick}
                  as={data.path && data.isExternal ? 'a' : null}
                  href={data.path && data.isExternal ? data.path : null}
                  target={data.path && data.isExternal ? '_blank' : null}
                  rel={
                    data.path && data.isExternal ? 'noopener noreferrer' : null
                  }
                  isTransparent={data?.status?.value}
                >
                  {headings.map(
                    ({
                      slug,
                      className = '',
                      width,
                      nowrap,
                      textAlign,
                      hasNoHorizontalPadding,
                      hasNoLeftPadding,
                    }) => (
                      <Td
                        key={slug}
                        className={className}
                        nowrap={nowrap}
                        hasNoHorizontalPadding={hasNoHorizontalPadding}
                        hasNoLeftPadding={hasNoLeftPadding}
                        style={{
                          width,
                          textAlign: textAlign || 'left',
                        }}
                      >
                        <Content key={index} {...data[slug]} />
                      </Td>
                    ),
                  )}
                </Tr>
              ))}
              {fixedLines.map((data, index) => (
                <Tr key={index} className={data.cls || ''}>
                  {headings.map(({ slug }) => (
                    <Td key={slug}>
                      <Content key={index} {...data[slug]} />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          {dropZone}
          {shouldShowEmptyState && emptyState}
        </TableWrapper>

        {loading && (
          <MDSpinner
            style={{ marginRight: 8 }}
            singleColor="currentColor"
            size={16}
          />
        )}
      </>
    );
  }
}

export default withRouter(DataTable);
