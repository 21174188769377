export const API_BASE = process.env.REACT_APP_API_BASE;
export const MOCK_API_BASE = process.env.REACT_APP_MOCK_API_BASE;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const SCOPE = process.env.REACT_APP_SCOPE;
export const APP = process.env.REACT_APP_APP;
export const FF_ENABLE_BAP = process.env.REACT_APP_FF_ENABLE_BAP === 'true';
export const FF_ENABLE_BILLER = process.env.REACT_APP_FF_ENABLE_BILLER === 'true';
export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

export const ERROR_MESSAGES = {
   403: 'Vous n’êtes pas autorisé à faire cette action.',
   404: "La page n'existe pas ou a été supprimée.",
};
