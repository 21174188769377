import { Component } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { Scrollbars } from 'react-custom-scrollbars';
import { ButtonReset, size } from 'ui';

const ModalCloseTrigger = styled(ButtonReset)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(0.5)};
  right: ${({ theme }) => theme.spacing(0.5)};
  z-index: 2;
  font-size: ${size(3)};
  background-color: #fff;
  width: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(2)};
  line-height: ${({ theme }) => theme.spacing(2)};
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
`;

ReactModal.setAppElement('#root');

/**
 * Modal dialog ([react-modal](https://github.com/rackt/react-modal) wrapper).
 */
class Modal extends Component {
  componentDidMount() {
    if (this.props.isOpen) {
      document.documentElement.style.overflowY = 'hidden';
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      document.documentElement.style.overflowY = 'hidden';
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      document.documentElement.style.overflowY = 'auto';
    }
  }

  componentWillUnmount() {
    document.documentElement.style.overflowY = 'auto';
  }

  render() {
    const {
      children,
      isOpen,
      onRequestClose,
      contentLabel,
      tight,
      large,
    } = this.props;

    return (
      <ReactModal
        style={{
          content: {
            width: large ? '64em' : tight ? '30em' : '40em',
            maxWidth: 'calc(100% - 16px)',
          },
        }}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
      >
        <ModalCloseTrigger onClick={onRequestClose} children="&times;" />
        <Scrollbars autoHeight autoHeightMax="calc(100vh - 32px)">
          {children}
        </Scrollbars>
      </ReactModal>
    );
  }
}

export default Modal;
