import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { media } from 'ui';
import Avatar from 'components/Avatar';
import Dropdown from 'components/Dropdown';
import { MdArrowDropDown } from 'react-icons/md';
import {
  PopoverMenu,
  PopoverItem,
  PopoverItemButton,
  PopoverItemLink,
} from 'ui/popover';
import { getCurrentUser } from 'selectors';
import { logout } from 'actions/user';

export const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  color: ${({ theme }) => theme.primary};
  width: 100%;

  ${media.desktop`
    border-color: rgba(255,255,255,0.5);
    color: #fff;
  `};
`;

const AvatarAndName = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const UserPopover = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { firstName, lastName, avatarUrl } = currentUser;

  const onLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Dropdown
      wrapperStyle={{ flex: 1 }}
      isFull
      trigger={
        <Trigger>
          <AvatarAndName>
            <Avatar
              size="small"
              firstName={firstName}
              lastName={lastName}
              avatarUrl={avatarUrl}
              variant="sidebar"
            />
            {firstName} {lastName}
            <MdArrowDropDown style={{ marginLeft: 4 }} />
          </AvatarAndName>
        </Trigger>
      }
    >
      <PopoverMenu>
        <PopoverItem>
          <PopoverItemLink to="/parametres/mon-profil">
            Mon profil
          </PopoverItemLink>
        </PopoverItem>
        <PopoverItemButton onClick={onLogout}>Déconnexion</PopoverItemButton>
      </PopoverMenu>
    </Dropdown>
  );
};

export default UserPopover;
