import { createReducer } from 'redux-starter-kit';
import { get } from 'lodash';
import { merge } from 'utils';
import { generatePagination } from 'utils/pagination';
import {
  mergeGlobalStateAndActionPayload,
  addLocalPaginationToGlobalState,
} from 'utils/reducers';

const initialState = {
  reportById: {},
  categoryById: {},
  reportEventById: {},
  reportResolutionById: {},
  categoriesIds: [],
  reportsIdsByAgencyId: [],
  agenciesReportsPagination: {},
  reportsIdsByContactId: {},
  reportsPagination: {},
};

const places = createReducer(initialState, {
  REPORTS_CATEGORIES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.categoriesIds = payload.result;
  },

  REPORTS_REQUEST: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.reportsPagination,
      payload,
      status: 'PENDING',
    }),

  REPORTS_ERROR: (state, { payload }) =>
    addLocalPaginationToGlobalState({
      state: state.reportsPagination,
      payload,
      status: 'ERROR',
    }),

  REPORTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    addLocalPaginationToGlobalState({
      state: state.reportsPagination,
      ...action,
      status: 'DONE',
    });
  },

  AGENCY_REPORTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.agenciesReportsPagination[action.payload.agencyId] = merge(
      get(state, `agenciesReportsPagination.${action.payload.agencyId}`),
      generatePagination(
        state.agenciesReportsPagination,
        action.payload.agencyId,
        action,
      ),
    );
  },

  REPORT_USER_WITNESS_TOGGLE_SUCCESS: mergeGlobalStateAndActionPayload,
  RESOLVE_REPORT_SUCCESS: mergeGlobalStateAndActionPayload,
  ACKNOWLEDGE_REPORT_SUCCESS: mergeGlobalStateAndActionPayload,
  REPORT_SUCCESS: mergeGlobalStateAndActionPayload,
  CONTACT_SUCCESS: mergeGlobalStateAndActionPayload,

  PORTFOLIO_REPORTS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.agenciesReportsPagination.portfolio = merge(
      get(state, `agenciesReportsPagination.portfolio`),
      generatePagination(state.agenciesReportsPagination, 'portfolio', action),
    );
  },

  CONTACT_REPORTS_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.reportsIdsByContactId[payload.contactId] = payload.data.result;
  },
});

export default places;
