import { Component } from 'react';
import { connect } from 'react-redux';
import { decamelizeKeys } from 'humps';
import { Box } from 'ui';
import AuthLayout from 'components/AuthLayout';
import { CheckProfileForm } from 'components/AuthForms';
import { getCurrentUser } from 'selectors';
import { history } from 'store';
import { isEmpty, get } from 'lodash';
import { getMe, putMe } from 'actions/user';

class CheckProfile extends Component {
  componentDidMount() {
    const { user, currentUser } = this.props;

    this.fetchData();

    if (!user.isAuthenticated) {
      history.replace('/connexion');
    }

    if (get(currentUser, 'profileChecked')) {
      history.replace('/');
      return;
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser, hasSkippedCheck } = this.props;

    if (
      (!prevProps.hasSkippedCheck && hasSkippedCheck) ||
      get(currentUser, 'profileChecked')
    ) {
      history.replace('/');
      return;
    }
  }

  fetchData() {
    this.props.getMe();
  }

  handleSubmitForm = async values => {
    const { phoneNumber, salesOptin } = values;
    const { putMe } = this.props;

    const user = {
      phoneNumber,
      salesOptin,
      profileChecked: true,
    };

    await putMe(decamelizeKeys({ user }));
  };

  render() {
    const { currentUser } = this.props;

    return (
      <AuthLayout>
        <Box padded>
          {!isEmpty(currentUser) && (
            <CheckProfileForm
              initialValues={currentUser}
              onSubmit={this.handleSubmitForm}
            />
          )}
        </Box>
      </AuthLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  currentUser: getCurrentUser(state),
  hasSkippedCheck: state.ui.hasSkippedCheck,
});

const mapDispatchToProps = dispatch => ({
  getMe: () => dispatch(getMe()),
  putMe: payload => dispatch(putMe(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckProfile);
