import { createReducer } from 'redux-starter-kit';

const initialState = {
  byId: {},
  queue: [],
};

const snacks = createReducer(initialState, {
  SNACK_QUEUE: (state, { payload }) => {
    state.byId[payload.id] = payload;
    state.queue.push(payload.id);
  },

  SNACK_POP_IMPORTANT: (state, { payload }) => {
    state.byId[payload.id] = payload;
    state.queue.unshift(payload.id);
  },

  SNACK_DISMISS: (state, { payload }) => {
    state.queue = state.queue.filter(id => id !== payload);
  },

  SNACK_CLEAR_ALL: state => {
    state.queue = [];
  },
});

export default snacks;
