import background from 'images/background-login.jpg';
import { Buttons, LinkButton as RawLinkButton, size } from './';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  background-image: url(${background});
  background-size: cover;
  background-position: center center;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  display: flex;
  align-items: center;
`;

export const Logo = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing()};

  img {
    display: inline-block;
    max-width: 100%;
  }
`;

export const BoxTitle = styled.h1`
  font-size: ${size(2)};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-weight: 600;
`;

export const BoxSuffix = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  color: #fff;
  text-align: center;

  a {
    color: inherit;
  }
`;

export const BoxDescription = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ForgottenPasswordButtonContainer = styled(Buttons)`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const LinkButton = styled(RawLinkButton)`
  color: inherit;
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(0.5)};
  transition: background-color 150ms ease;
  border-radius: 8px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`.withComponent(Link);
