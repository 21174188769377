import { ConnectedPassword, ConnectedTextField } from './TextField';
import { isEmail, isRequired, isValidEmail } from 'utils/validation';
import { BoxDescription, BoxTitle, ForgottenPasswordButtonContainer, LinkButton } from 'ui/auth';
import { Button, Buttons } from 'ui';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_RECAPTCHA_KEY } from '../constants';

export const LoginForm = ({ onSubmit, type }) => (
   <form onSubmit={onSubmit} autoComplete='off'>
      <BoxTitle>Connexion par email</BoxTitle>

      <ConnectedTextField
         id='login-username'
         label='Email'
         name='username'
         validate={[isEmail, isRequired]}
         autoComplete='off'
      />

      <ConnectedPassword {...{ type }} label='Mot de passe' id='login-password' name='password' autoComplete='off' />

      <ForgottenPasswordButtonContainer right>
         <LinkButton to='/mot-de-passe-oublie'>Mot de passe oublié ?</LinkButton>
      </ForgottenPasswordButtonContainer>

      <Buttons marged>
         <Button cta primary block>
            Se connecter
         </Button>
      </Buttons>
   </form>
);

export const SignupForm = ({ onSubmit }) => (
   <form onSubmit={onSubmit}>
      <BoxTitle>Créer un compte</BoxTitle>

      <BoxDescription>
         Nous allons vous envoyer un email de confirmation à l'adresse indiquée pour activer votre compte.
      </BoxDescription>

      <ConnectedTextField id='signup-username' label='Email' name='username' validate={[isEmail, isRequired]} />

      <Buttons marged>
         <Button cta primary block>
            Valider
         </Button>
      </Buttons>
   </form>
);

export const LostPasswordForm = ({ onSubmit }) => {
   const [recaptchaToken, setRecaptchaToken] = useState();
   const [email, setEmail] = useState();
   return (
      <form onSubmit={e => onSubmit(e, recaptchaToken)}>
         <BoxTitle>Mot de passe oublié</BoxTitle>

         <BoxDescription>
            Indiquez l’email de votre compte. Nous vous enverrons un lien afin de réinitialiser votre mot de passe.
         </BoxDescription>

         <ConnectedTextField id='signup-username' label='Email' name='username' onChange={e => setEmail(e.target.value)} validate={[isEmail, isRequired]} />
         <ReCAPTCHA onChange={value => setRecaptchaToken(value)} sitekey={GOOGLE_RECAPTCHA_KEY} />

         <Buttons marged>
            <Button cta primary block disabled={!recaptchaToken || !isValidEmail(email)}>
               Envoyer
            </Button>
         </Buttons>
      </form>
   );
};

export const AcceptInvitationForm = ({ onSubmit }) => {
   const form = useSelector(state => state.form.auth);
   const [submitCount, setSubmitCount] = useState(0);
   return (
      <form
         onSubmit={e => {
            setSubmitCount(submitCount + 1);
            onSubmit(e);
         }}
      >
         <BoxTitle>Votre mot de passe</BoxTitle>

         <BoxDescription>Il doit contenir au moins 12 caractères</BoxDescription>

         <ConnectedPassword
            label='Mot de passe'
            id='accept-password'
            name='password'
            password={form?.values?.password}
            isDirty={submitCount > 0}
            validation
         />

         <Buttons marged style={{ marginTop: 16 }}>
            <Button cta primary block>
               Enregistrer
            </Button>
         </Buttons>
      </form>
   );
};

export const ResetPasswordForm = ({ onSubmit }) => {
   const form = useSelector(state => state.form.auth);
   const [submitCount, setSubmitCount] = useState(0);
   return (
      <form
         onSubmit={e => {
            setSubmitCount(submitCount + 1);
            onSubmit(e);
         }}
      >
         <BoxTitle>Réinitialisation du mot de passe</BoxTitle>

         <BoxDescription>Il doit contenir au moins 12 caractères</BoxDescription>

         <ConnectedPassword
            label='Nouveau mot de passe'
            id='reset-password'
            name='password'
            password={form?.values?.password}
            isDirty={submitCount > 0}
            validation
         />

         <Buttons marged style={{ marginTop: 16 }}>
            <Button cta primary block>
               Enregistrer
            </Button>
         </Buttons>
      </form>
   );
};

export const CheckProfileForm = reduxForm({
   form: 'checkProfile',
})(({ handleSubmit, ...props }) => (
   <form onSubmit={handleSubmit}>
      <BoxTitle>Vérification de votre profil</BoxTitle>

      <BoxDescription>
         Pour profiter d’une meilleure expérience communautaire, nous vous invitons à vérifier que vos données sont
         correctes.
      </BoxDescription>

      <ConnectedTextField id='signup-phone' label='Numéro de téléphone' name='phoneNumber' validate={[isRequired]} />

      <Buttons>
         <Button big primary>
            Envoyer
         </Button>
      </Buttons>
   </form>
));
