import { createReducer } from 'redux-starter-kit';
import { camelizeKeys } from 'humps';

const initialState = {
  privacySettings: {},
};

const receiveAuthData = (state, { payload }) => ({
  ...state,
  isAuthenticated: true,
  isFetching: false,
  ...camelizeKeys(payload),
});

const failAuth = () => initialState;

const receivePrivacySettings = (state, { payload }) => {
  state.privacySettings = payload;
};

const user = createReducer(initialState, {
  LOGIN_REQUEST: (state, { payload }) => {
    state.isFetching = true;
    state.isAuthenticated = false;
  },

  LOGIN_SUCCESS: receiveAuthData,
  REFRESH_TOKEN_SUCCESS: receiveAuthData,

  ME_SUCCESS: (state, { payload }) => {
    state.userId = payload.result;
  },

  USER_IDENTITIES_SUCCESS: (state, { payload }) => {
    const identityId = payload.result?.[0];
    state.identityId = identityId;
    state.identityUuid = payload.entities?.identities?.[identityId]?.uuid;
  },

  PRIVACY_SETTINGS_SUCCESS: receivePrivacySettings,
  UPDATE_PRIVACY_SETTINGS_SUCCESS: receivePrivacySettings,

  REFRESH_TOKEN_ERROR: failAuth,
  LOGIN_ERROR: failAuth,
  LOGOUT: failAuth,
});

export default user;
