import { get, put } from './index';
import { constructURI } from '../../utils';
import snakeCase from 'lodash/snakeCase';

export const getExpensesSummary = (placeId, fiscalYearId) =>
   get(`/v2/places/${placeId}/expenses/summary?fiscal_year_id=${fiscalYearId}`);
export const getAccountPlaces = (placeId, publicWorkId, fiscalYearId, stateFilter, search) =>
   get(
      constructURI(
         `/v2/places/${placeId}/expenses/account_places`,
         {
            publicWorkId,
            fiscalYearId,
            stateFilter,
            search,
         },
         snakeCase
      )
   );
export const getRepartitionKeys = (
   placeId,
   publicWorkId,
   fiscalYearId,
   accountPlaceId,
   page,
   perPage,
   stateFilter,
   search
) =>
   get(
      constructURI(
         `/v2/places/${placeId}/expenses/repartition_keys`,
         {
            publicWorkId,
            fiscalYearId,
            accountPlaceId,
            page,
            perPage,
            stateFilter,
            search,
         },
         snakeCase
      )
   );
export const getBuildings = (
   placeId,
   publicWorkId,
   fiscalYearId,
   accountPlaceId,
   repartitionKeyId,
   page,
   perPage,
   stateFilter,
   search
) =>
   get(
      constructURI(
         `/v2/places/${placeId}/expenses/buildings`,
         {
            publicWorkId,
            fiscalYearId,
            accountPlaceId,
            repartitionKeyId,
            page,
            perPage,
            stateFilter,
            search,
         },
         snakeCase
      )
   );
export const getAccountCodes = (
   placeId,
   publicWorkId,
   fiscalYearId,
   accountPlaceId,
   repartitionKeyId,
   buildingId,
   page,
   perPage,
   stateFilter,
   search
) =>
   get(
      constructURI(
         `/v2/places/${placeId}/expenses/account_codes`,
         {
            publicWorkId,
            fiscalYearId,
            accountPlaceId,
            repartitionKeyId,
            buildingId,
            page,
            perPage,
            stateFilter,
            search,
         },
         snakeCase
      )
   );
export const getProviders = (
   placeId,
   publicWorkId,
   fiscalYearId,
   accountPlaceId,
   repartitionKeyId,
   buildingId,
   accountCodeId,
   page,
   perPage,
   stateFilter,
   search
) =>
   get(
      constructURI(
         `/v2/places/${placeId}/expenses/providers`,
         {
            publicWorkId,
            fiscalYearId,
            accountPlaceId,
            repartitionKeyId,
            buildingId,
            accountCodeId,
            page,
            perPage,
            stateFilter,
            search,
         },
         snakeCase
      )
   );
export const getEntries = (
   placeId,
   publicWorkId,
   fiscalYearId,
   accountPlaceId,
   repartitionKeyId,
   buildingId,
   accountCodeId,
   providerId,
   page,
   perPage,
   stateFilter,
   search
) =>
   get(
      constructURI(
         `/v2/places/${placeId}/expenses`,
         {
            publicWorkId,
            fiscalYearId,
            accountPlaceId,
            repartitionKeyId,
            buildingId,
            accountCodeId,
            providerId,
            page,
            perPage,
            stateFilter,
            search,
         },
         snakeCase
      )
   );
export const getEntry = (placeId, fiscalYearId, stateFilter, search, entryId) =>
   get(
      constructURI(
         `/v2/places/${placeId}/expenses/${entryId}`,
         {
            fiscalYearId,
            stateFilter,
            search,
         },
         snakeCase
      )
   );
export const approveAllEntries = (placeId, providerId, payload) =>
   put(`/v2/places/${placeId}/account_place_entries/providers/${providerId}/approve_all_entries`, payload);

export const markAsRead = (placeId, entryId, payload) =>
   put(`/v2/places/${placeId}/account_place_entries/${entryId}/mark_as_read`, payload);
export const updateEntry = (placeId, entryId, payload) =>
   put(`/v2/places/${placeId}/account_place_entries/${entryId}`, payload);
export const getFiscalYears = placeId => get(`/v2/places/${placeId}/expenses/fiscal_years`);
