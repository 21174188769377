import { get, post } from './api';
import { reportCategory, report, reportEvents, contract } from './schema';

export const getReportsCategories = () =>
  get(`/v1/report_categories`, [reportCategory]);
export const getReport = id => get(`/v1/reports/${id}`, report);
export const getReportContracts = (agencyId, reportId) =>
  get(`/v1/agencies/${agencyId}/contracts?by_report=${reportId}`, [contract]);
// export const getReportWitness = id => get(`/v1/reports/${id}/witness`);
// export const getReportEvents = id => get(`/v1/reports/${id}/events`);
// export const getReportComments = id => get(`/v1/reports/${id}/comments`);

export const postReportWitness = (id, payload) =>
  post(`/v1/reports/${id}/witness`, payload, report);
export const resolveReport = (id, payload) =>
  post(`/v1/reports/${id}/resolve`, payload, report);
export const acknowledgeReport = (id, payload) =>
  post(`/v1/reports/${id}/acknowledge`, payload, report);
export const toggleWitnessReport = (id, payload) =>
  post(`/v1/reports/${id}/witness`, payload, report);

export const postReportEvent = (id, payload) =>
  post(`/v1/reports/${id}/events`, payload, reportEvents);

export const createReport = (equipmentId, payload) =>
  post(`/v1/equipments/${equipmentId}/reports`, payload, report);
