import styled from 'styled-components';
import Dotdotdot from 'react-dotdotdot';

export const ListItemTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: ${({ theme }) => theme.size()};

  ${({ theme, isUnread }) =>
    !Boolean(isUnread) &&
    `
    font-weight: normal;
    color: ${theme.textLight};
  `}
`;

export const ListItemMeta = styled.p`
  margin: ${({ theme }) => theme.spacing(0.5)} 0 0;
  color: ${({ theme }) => theme.textLight};
  white-space: initial;
  word-break: break-word;
`;

export const ListItemDescription = styled(Dotdotdot)`
  margin: ${({ theme }) => theme.spacing(0.5)} 0 0;
  color: ${({ theme }) => theme.textLight};
  white-space: pre-wrap;
`;
