import { createReducer } from 'redux-starter-kit';
import { generatePagination } from 'utils/pagination';
import { get } from 'lodash';
import { merge } from 'utils';
import { mergeGlobalStateAndActionPayload } from 'utils/reducers';

const initialState = {
  accountPlaceEntryById: {},
  accountPlaceEntriesPagination: {},
  invoiceById: {},
  invoiceGroupById: {},
  invoicesPagination: {},
  coownerAmountById: {},
  coownerAmountsPagination: {},
  publicWorkById: {},
  publicWorksPagination: {},
  accountPlaceEntriesIdsByBudgetId: {},
  providerAccountPlaceEntriesIdsByBudgetId: {},
  accountCodeAccountPlaceEntriesIdsByBudgetId: {},
  providerAccountPlaceEntryById: {},
  accountCodeAccountPlaceEntryById: {},
};

const accounts = createReducer(initialState, {
  TREASURY_ACCOUNT_PLACE_ENTRIES_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);

    state.accountPlaceEntriesPagination[action.payload.treasuryId] = merge(
      get(state, `accountPlaceEntriesPagination[${action.payload.treasuryId}]`),
      generatePagination(
        state.accountPlaceEntriesPagination,
        action.payload.treasuryId,
        action,
      ),
    );
  },

  TREASURY_INVOICES_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);

    state.invoicesPagination[action.payload.treasuryId] = merge(
      get(state, `invoicesPagination[${action.payload.treasuryId}]`),
      generatePagination(
        state.invoicesPagination,
        action.payload.treasuryId,
        action,
      ),
    );
  },

  PUBLIC_WORKS_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.publicWorksPagination[action.payload.placeId] = merge(
      get(state, `publicWorksPagination[${action.payload.placeId}]`),
      generatePagination(
        state.publicWorksPagination,
        action.payload.placeId,
        action,
      ),
    );
  },

  TREASURY_COOWNER_AMOUNT_SUCCESS: (state, action) => {
    mergeGlobalStateAndActionPayload(state, action);
    state.coownerAmountsPagination[action.payload.treasuryId] = merge(
      get(state, `coownerAmountsPagination[${action.payload.treasuryId}]`),
      generatePagination(
        state.coownerAmountsPagination,
        action.payload.treasuryId,
        action,
      ),
    );
  },

  BUDGET_ACCOUNT_PLACE_ENTRIES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.accountPlaceEntriesIdsByBudgetId[payload.budgetId] =
      payload.data.result;
  },

  BUDGET_PROVIDERS_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.providerAccountPlaceEntriesIdsByBudgetId[payload.budgetId] =
      payload.data.result;
  },

  BUDGET_ACCOUNT_CODES_SUCCESS: (state, { payload }) => {
    mergeGlobalStateAndActionPayload(state, { payload });
    state.accountCodeAccountPlaceEntriesIdsByBudgetId[payload.budgetId] =
      payload.data.result;
  },

  LOGOUT: () => initialState,
});

export default accounts;
