import { Component, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { MdBugReport } from 'react-icons/md';
import CheckProfile from 'containers/CheckProfile';
import { history } from 'store';
import { fetchInitialData } from 'actions';
import { logout } from 'actions/user';
import { getPlace } from 'actions/places';
import { getCurrentUser } from 'selectors';
import { getSelectedAgency, getSelectedPlace } from 'selectors/agencies';
import { ButtonReset, color, size } from 'ui';
import { get, isEmpty } from 'lodash';
import MainSidebar from 'components/MainSidebar';
import { AppContext } from 'containers/AppProvider';
import { motion } from 'framer-motion';
import Avatar from 'components/Avatar';
import PlaceSidebar from 'components/PlaceSidebar';
import { FF_ENABLE_BAP, FF_ENABLE_BILLER } from './constants';

const MobileNavTrigger = styled(ButtonReset)`
   position: fixed;
   left: 0;
   top: 0;
   z-index: 4;
   height: 60px;
   display: flex;
   align-items: center;
   width: 60px;
   justify-content: center;
   color: #fff;
   font-size: 24px;
`;

const Conversations = lazy(() => import('containers/Conversations'));
const Reports = lazy(() => import('containers/Reports'));
const Forum = lazy(() => import('containers/Forum'));
const Settings = lazy(() => import('containers/Settings'));
const Contacts = lazy(() => import('containers/Contacts'));
const Places = lazy(() => import('containers/Places'));
const Alerts = lazy(() => import('containers/Alerts'));
const Dashboard = lazy(() => import('containers/Places/Dashboard'));
const Billers = lazy(() => import('containers/Billers'));
const Invoices = lazy(() => import('containers/Invoices'));
const Admin = lazy(() => import('containers/Admin'));
const Batch = lazy(() => import('containers/Batch'));
const Actualities = lazy(() => import('containers/Actualities'));

const Wrapper = styled.div`
   background-color: #000;
`;

const BugButton = styled(ButtonReset)`
   font-size: ${size(3)};
   color: #fff;
   background-color: ${color('red')};
   padding: ${({ theme }) => theme.spacing(0.5)};
   display: flex;
   position: fixed;
   bottom: ${({ theme }) => theme.spacing(0.5)};
   right: ${({ theme }) => theme.spacing(0.5)};
   z-index: 1000;
   cursor: pointer;

   &:hover {
      background-color: ${color('red', 'medium')};
   }
`;

const MobilePlaceTrigger = styled(ButtonReset)`
   position: fixed;
   right: 0;
   top: 0;
   height: 60px;
   width: 60px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: #fff;
   font-size: 24px;
   padding: ${({ theme }) => theme.spacing()};
`;

const Path = props => <motion.path fill='transparent' strokeWidth='3' strokeLinecap='round' {...props} />;

class App extends Component {
   async componentDidMount() {
      const { user, fetchInitialData, ui, getPlace } = this.props;

      if (user && user.isAuthenticated && window.Raven) {
         window.Raven.setUserContext({
            id: user.userId,
         });
      }

      if (!user.isAuthenticated) {
         history.replace('/connexion');
         return;
      }

      if (Boolean(ui.selectedPlaceId)) {
         getPlace(ui.selectedPlaceId);
      }

      await fetchInitialData();
      this.setUserContext();
   }

   setUserContext = () => {
      const { currentUser } = this.props;

      if (Boolean(currentUser) && window.Raven) {
         window.Raven.setUserContext({
            id: currentUser.id,
            email: currentUser.email,
            name: `${currentUser.firstName} ${currentUser.lastName}`,
         });
      }
   };

   componentDidUpdate(prevProps) {
      const { user, ui, getPlace } = this.props;

      if (!user.isAuthenticated) {
         history.replace('/connexion');
      }

      if (Boolean(ui.selectedPlaceId) && ui.selectedPlaceId !== prevProps.ui.selectedPlaceId) {
         getPlace(ui.selectedPlaceId);
      }
   }

   render() {
      const { currentUser, match, ui, selectedPlace, selectedAgency } = this.props;

      if (isEmpty(currentUser)) {
         return <div />;
      }

      if (!isEmpty(currentUser) && !currentUser.profileChecked) {
         return <CheckProfile />;
      }

      return (
         <Wrapper>
            <AppContext.Consumer>
               {({ isMobile, toggleNavIsShown, mobileNavIsShown, togglePlaceIsShown, mobilePlaceIsShown }) =>
                  isMobile && (
                     <>
                        <MobileNavTrigger onClick={toggleNavIsShown}>
                           <motion.svg
                              width='23'
                              height='23'
                              viewBox='0 0 23 23'
                              animate={mobileNavIsShown ? 'open' : 'closed'}
                           >
                              <Path
                                 d='M 2 2.5 L 20 2.5'
                                 stroke='#ffffff'
                                 variants={{
                                    closed: { stroke: '#ffffff', d: 'M 2 2.5 L 20 2.5' },
                                    open: { stroke: '#000000', d: 'M 3 16.5 L 17 2.5' },
                                 }}
                              />
                              <Path
                                 d='M 2 9.423 L 20 9.423'
                                 stroke='#ffffff'
                                 variants={{
                                    closed: { stroke: '#ffffff', opacity: 1 },
                                    open: { stroke: '#000000', opacity: 0 },
                                 }}
                                 opacity={0}
                                 transition={{ duration: 0.1 }}
                              />
                              <Path
                                 d='M 2 16.346 L 20 16.346'
                                 stroke='#ffffff'
                                 variants={{
                                    closed: {
                                       stroke: '#ffffff',
                                       d: 'M 2 16.346 L 20 16.346',
                                    },
                                    open: { stroke: '#000000', d: 'M 3 2.5 L 17 16.346' },
                                 }}
                              />
                           </motion.svg>
                        </MobileNavTrigger>
                        <MobilePlaceTrigger style={{ zIndex: mobileNavIsShown ? 3 : 6 }} onClick={togglePlaceIsShown}>
                           {mobilePlaceIsShown !== <Avatar size='small' avatarUrl={get(selectedPlace, 'pictureUrl')} />}
                           <motion.svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              animate={mobilePlaceIsShown ? 'open' : 'closed'}
                           >
                              <Path
                                 d='M7,8 L12,2 L17,8'
                                 stroke='#ffffff'
                                 variants={{
                                    closed: { stroke: '#ffffff', d: 'M7,8 L12,2 L17,8' },
                                    open: {
                                       stroke: '#000000',
                                       d: 'M5.25,4.75 L12,12 L 18.75,4.75',
                                    },
                                 }}
                              />
                              <Path
                                 d='M7,16 L12,22 L17,16'
                                 stroke='#ffffff'
                                 variants={{
                                    closed: { stroke: '#ffffff', d: 'M7,16 L12,22 L17,16' },
                                    open: {
                                       stroke: '#000000',
                                       d: 'M5.25,19.25 L12,12 L18.75,19.25',
                                    },
                                 }}
                              />
                           </motion.svg>

                           {/* <MdUnfoldMore /> */}
                        </MobilePlaceTrigger>
                        <PlaceSidebar place={selectedPlace} agency={selectedAgency} />
                     </>
                  )
               }
            </AppContext.Consumer>

            {/* <Header onLogout={this.props.logout} {...{ currentUser }} /> */}

            <Switch>
               <Route path='/' exact render={() => null} />
               <Route component={MainSidebar} />
            </Switch>

            <Suspense fallback={<div />}>
               <Switch>
                  <Route path='/messages' component={Conversations} />
                  <Route path='/signalements' component={Reports} />
                  <Route path='/parametres' component={Settings} />
                  <Route path='/contacts' component={Contacts} />
                  <Route path='/forum' component={Forum} />
                  <Route path='/alertes' component={Alerts} />
                  <Route path='/tableau-de-bord' component={Dashboard} />
                  <Route path='/administration' component={Admin} />
                  <Route path='/batch' component={Batch} />
                  <Route path='/lieux/:id?' component={Places} />
                  <Route path='/actualites' component={Actualities} />
                  {FF_ENABLE_BILLER && <Route path='/facturier' component={Billers} />}
                  {FF_ENABLE_BAP && <Route path='/factures' component={Invoices} />}

                  <Redirect to={Boolean(ui.selectedPlaceId) ? '/tableau-de-bord' : 'signalements'} />
               </Switch>
            </Suspense>

            <BugButton
               onClick={() =>
                  window.Raven.showReportDialog({
                     event: window.Raven.captureMessage(match.url),
                  })
               }
            >
               <MdBugReport />
            </BugButton>
         </Wrapper>
      );
   }
}

const mapStateToProps = state => ({
   user: state.user,
   currentUser: getCurrentUser(state),
   ui: state.ui,
   selectedPlace: getSelectedPlace(state),
   selectedAgency: getSelectedAgency(state),
});

const mapDispatchToProps = dispatch => ({
   fetchInitialData: () => dispatch(fetchInitialData()),
   logout: () => dispatch(logout()),
   getPlace: id => dispatch(getPlace(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
