import { ModalBody, ModalContent, ModalHeader, ModalTitle } from 'ui/modal';
import { Button, Buttons, LoadingButton, LoadingLayout } from 'ui';
import Modal from 'components/Modal';
import { Stack } from '@tymate/sergic';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { MdCheckCircle, MdCheckCircleOutline, MdPerson } from 'react-icons/md';
import RadioFormik from './RadioFormik';
import { get, kebabCase } from 'lodash';
import { useQuery } from 'react-query';
import { getPlaceCoowners, postExpensesManager } from '../api/places';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Spinner } from '@tymate/margaret';
import { fetchExpensesSummary } from 'client/src/actions/expenses';

const Person = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: ${({ theme }) => theme.spacing(0.5)};

   label {
      padding-right: 0;
   }

   span {
      margin-right: 0;
   }

   > :nth-child(3) {
      color: ${({ theme }) => theme.primary};
   }

   > svg,
   input:not(:checked) + * svg {
      color: ${({ theme }) => theme.textLight};
   }
`;

const PersonName = styled.span`
   display: block;
   flex: 1;
   font-weight: bold;
`;

const ExpensesRightsModal = ({ afterSubmit, onCancelClick, isOpen }) => {
   const dispatch = useDispatch();

   const { selectedPlaceId, selectedFiscalYearId } = useSelector(state => ({
      selectedPlaceId: state.ui.selectedPlaceId,
      selectedFiscalYearId: get(state.ui.selectedFiscalYears, state.ui.selectedPlaceId),
   }));

   const { data, refetch, isFetching } = useQuery(['getPlaceCoowners', selectedPlaceId], () => getPlaceCoowners(selectedPlaceId), { enabled: false });

   useEffect(() => {
      if (selectedPlaceId && isOpen) {
         refetch();
      }
   }, [selectedPlaceId, isOpen]);

   const onSubmit = async values => {
      try {
         await postExpensesManager(selectedPlaceId, values);
         dispatch(fetchExpensesSummary(selectedPlaceId, selectedFiscalYearId));
         toast.success('Modification effectuée aves succès', { duration: 3000 });
         if (afterSubmit) {
            afterSubmit();
         }
      } catch (err) {
         toast.error('Une erreur est survenue, veuillez réessayer', {
            duration: 3000,
         });
      }
   };

   return (
      <Modal isOpen={isOpen} onRequestClose={onCancelClick} isNotScrollable>
         <ModalHeader padded>
            <ModalTitle>Préférences de gestion des dépenses</ModalTitle>
         </ModalHeader>
         <ModalBody>
            <ModalContent>
               <Stack direction='column' gutterSize={2} alignX='stretch'>
                  <Stack>Affectez un membre du CS pour gérer les dépenses de la copropriété</Stack>
                  <Formik
                     enableReinitialize
                     onSubmit={onSubmit}
                     initialValues={{
                        userId: data?.data?.find(m => m.expensesManager)?.id,
                     }}
                  >
                     {({ isSubmitting }) => (
                        <Form>
                           {isFetching && (
                              <LoadingLayout>
                                 <Spinner />
                              </LoadingLayout>
                           )}
                           {!isFetching && (
                              <>
                                 <Person>
                                    <span>Membre du CS :</span>
                                    <span>Droits de gestion</span>
                                 </Person>
                                 {data?.data?.map(m => (
                                    <Person key={kebabCase(m.displayName)}>
                                       <MdPerson />
                                       <PersonName>{m.displayName}</PersonName>
                                       <span>{m.displayPlaceRole}</span>
                                       <RadioFormik
                                          name='userId'
                                          value={m.id}
                                          checked={m.expensesManager}
                                          iconChecked={<MdCheckCircle />}
                                          iconUnchecked={<MdCheckCircleOutline />}
                                          disabled={isSubmitting}
                                       />
                                    </Person>
                                 ))}
                              </>
                           )}
                           <Buttons marged>
                              <Button type='button' onClick={onCancelClick} disabled={isSubmitting}>
                                 Annuler
                              </Button>

                              <LoadingButton isLoading={isSubmitting} disabled={isSubmitting} primary type='submit'>
                                 Enregistrer
                              </LoadingButton>
                           </Buttons>
                        </Form>
                     )}
                  </Formik>
               </Stack>
            </ModalContent>
         </ModalBody>
      </Modal>
   );
};

export default ExpensesRightsModal;
